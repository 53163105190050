import { useQuery } from "react-query";
import { GET_PROJECT } from "services/api/projects";
import { useParams } from "react-router-dom";

const useInitialState = () => {
  const { project } = useParams();
  const { data } = useQuery(["getProject", project!], GET_PROJECT, {
    staleTime: Infinity,
    enabled: Boolean(project),
  });
  const initialProjectState = data?.data;
  const INITAL_VALUES_CREATE = {
    logo: "",
    projName: "",
    status: "upcoming",
    swapRate: "",
    cap: "",
    capFormatted: "",
    symbol: "",
    // description: "",
    timeToOpen: "",
    timeToClose: "",
    network: "",
    surpportedTokens: "",
    DesriptionAndSocials: {
      description: "",
      telegram: "",
      medium: "",
      twitter: "",
      facebook: "",
      discord: "",
      website: "",
    },
  };

  const INITAL_VALUES_UPDATE = {
    logo: initialProjectState?.icon || "",
    projName: initialProjectState?.name || "",
    status: initialProjectState?.status || "",
    swapRate: initialProjectState?.swap_rate || "",
    cap: initialProjectState?.pool_size || "",
    capFormatted: "",
    symbol: initialProjectState?.token_code || "",
    timeToOpen: initialProjectState?.open_time
      ? `${new Date(initialProjectState?.open_time)}`
      : "",
    timeToClose: initialProjectState?.close_time
      ? `${new Date(initialProjectState?.close_time)}`
      : "",
    network: initialProjectState?.contribution_network || "",
    surpportedTokens: "",
    DesriptionAndSocials: {
      description: initialProjectState?.description || "",
      telegram: initialProjectState?.telegram || "",
      medium: initialProjectState?.medium || "",
      twitter: initialProjectState?.twitter || "",
      facebook: initialProjectState?.facebook || "",
      discord: initialProjectState?.discord || "",
      website: initialProjectState?.website || "",
    },
  };

  return {
    INITAL_VALUES_CREATE,
    INITAL_VALUES_UPDATE,
    initialProjectState,
    project,
  };
};

export default useInitialState;
