import { useState, useEffect } from "react";
import type { Dispatch, SetStateAction } from "react";
import {
  useContract,
  useSigner,
  useContractEvent,
  chain,
  useConnect,
  useSwitchNetwork,
} from "wagmi";
import CONTRACT_ABI from "contract/abi.json";
import { BNB_CHAIN, BNB_CHAIN_TEST } from "CHAINS";
import { InjectedConnector } from "wagmi/connectors/injected";
import { useAppContext } from "contexts/AppContext";
import { getTimeStamp } from "utils/getTimeStamp";
import { LoaderButton } from "components/loader-utils/LoaderButton";
import { useMutation } from "react-query";
import CloseIcon from "assets/rocket-img/icons/close_icon.png";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as yup from "yup";
import DatePickerField from "../../DatePicker";
import {
  CREATE_DISTRIBUTION_SCHEDULE,
  UPDATE_DISTRIBUTION_SCHEDULE_ID,
} from "services/api/projects";
import { IProject } from "@/interfaces/projects";
import { IMetaMaskErr, IRouteError } from "@/interfaces/general";
import { useSnackbar } from "notistack";
import { getChainId } from 'utils/getChainId';

interface CreateDistributionScheduleProps {
  refetchSchedules: any;
  details: IProject;
  setIsNewSchedule: Dispatch<SetStateAction<boolean>>;
  setShowSuccessModal: Dispatch<SetStateAction<boolean>>;
}

interface CreateScheduleForm {
  startDate: string;
  closeDate: string;
  claimPercent: string | number;
}

const initialValues: CreateScheduleForm = {
  startDate: "",
  closeDate: "",
  claimPercent: "",
};
const validationSchema = yup.object({
  startDate: yup.string().required("Please select a date"),
  closeDate: yup.string().required("Please select a date"),
  claimPercent: yup.number().required("Please enter a value"),
});

const CreateDistributionSchedule = ({
  details,
  refetchSchedules,
  setIsNewSchedule,
  setShowSuccessModal,
}: CreateDistributionScheduleProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { appState } = useAppContext();
  const { walletConnected } = appState;

  const { switchNetwork } = useSwitchNetwork();

  const { connect } = useConnect({
    chainId: getChainId(details?.contribution_network),
    connector: new InjectedConnector(),
    onSuccess: (data) => {
      if (data.chain.id !== getChainId(details?.contribution_network)) {
        switchNetwork?.(getChainId(details?.contribution_network));
      }
    },
  });

  useEffect(() => {
    if (!walletConnected) {
      connect();
    }
  }, []);
  const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const [distributionId, setDistributionId] = useState<string>();
  const [contractLoading, setContractLoading] = useState(false);
  const { data: signer } = useSigner();
  const contract = useContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    signerOrProvider: signer,
  });

  const [intervalTime, setIntervalTime] = useState(30);

  const { mutateAsync, isLoading: isLoadingAddSchedule } = useMutation(
    "createDistributionSchedule",
    CREATE_DISTRIBUTION_SCHEDULE,
    {
      onError: (error) => {
        const errorMessage = (error as IRouteError)?.response?.data.message;
        enqueueSnackbar({
          variant: "error",
          message: errorMessage,
          autoHideDuration: 5000,
        });
      },
    }
  );
  const { mutateAsync: updateScheduleId, isLoading: isLoadingScheduleId } =
    useMutation("updateScheduleID", UPDATE_DISTRIBUTION_SCHEDULE_ID, {
      onError: (error) => {
        const errorMessage = (error as IRouteError)?.response?.data.message;
        enqueueSnackbar({
          variant: "error",
          message: errorMessage,
          autoHideDuration: 5000,
        });
      },
    });

  useContractEvent({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    eventName: "CreatedDistributionSchedule",
    listener(...args: any) {
      console.log("event args: ", args);
      setDistributionId(args[0]);
    },
    once: true,
  });

  const createScheduleWeb3 = async (values: CreateScheduleForm) => {
    try {
      setContractLoading(true);
      const transaction: any = await contract?.createDistributionSchedule(
        details?.pool_id,
        Math.ceil(getTimeStamp(`${values.startDate}`) / 1000),
        Math.ceil(getTimeStamp(`${values.closeDate}`) / 1000),
        Number(values.claimPercent)
      );
      //   wait for transactionResponse
      const transactionReceipt = await transaction.wait(2);
      console.log("reciept: ", transactionReceipt);

      setContractLoading(false);
      return transactionReceipt;
    } catch (error: any) {
      setContractLoading(false);
      console.log(error);
      if ((error as IMetaMaskErr)?.code) {
        enqueueSnackbar({
          variant: "error",
          message: (error as IMetaMaskErr).error.data.message,
          autoHideDuration: 5000,
        });
      }
    }
  };
  const handleSubmit = async (values: CreateScheduleForm) => {
    try {
      const formatted_close = new Date(values.closeDate);
      const formatted_start = new Date(values.startDate);
      const res = await mutateAsync({
        scheduleDetails: {
          projectId: details?.id,
          startDate: formatted_start.toISOString(),
          closeDate: formatted_close.toISOString(),
          claimPercentage: `${values.claimPercent}`,
        },
      });

      await updateScheduleId({
        distributionId: res.data?.id,
        blockchainDistributionId: distributionId!,
      });
      refetchSchedules();
      setShowSuccessModal(true);
      setIsNewSchedule(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="bg-black/50 w-full min-w-[100vw] py-[5rem] h-full min-h-[100vh] overflow-y-auto fixed top-0 left-0 flex z-[999]">
        <div
          className={`w-[90%] md:w-[70%] lg:w-[60%] h-fit  relative z-[150] mx-auto bg-white rounded-xl`}
        >
          <div className="px-8 py-6">
            <div className="flex w-full">
              <button
                type="button"
                onClick={() => setIsNewSchedule(false)}
                className="w-[30px] h-[30px] ml-auto opacity-[0.5]"
              >
                <img src={CloseIcon} alt="close" />
              </button>
            </div>
            <div className="w-full">
              <h2 className="text-xl font-semibold mb-4">
                Add New Distribution Schedule
              </h2>
              <div className="border-t border-[#EFF2F5] border-solid w-full"></div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  createScheduleWeb3(values);
                }}
              >
                {(props: FormikProps<CreateScheduleForm>) => {
                  const { values } = props;
                  useEffect(() => {
                    if (distributionId && !contractLoading) {
                      handleSubmit(values);
                    }
                  }, [distributionId, contractLoading]);
                  return (
                    <Form>
                      <div className="flex items-center w-full mb-10">
                        <label
                          htmlFor="startDate"
                          className="text-lg font-semibold w-[141px]"
                        >
                          Starts
                        </label>
                        <div className="mx-auto w-[445px] relative">
                          <div className="bg-[#F5F8FA] w-full">
                            <DatePickerField
                              name="startDate"
                              customTimeInterval={intervalTime}
                              toggleInterval={() => {
                                setIntervalTime((prev) => prev + 30);
                              }}
                            />
                            <span className="content-['']  border-r-[2px] border-b-[2px] border-[#7E8299] border-solid rotate-45 absolute top-[45%] translate-y-[-45%] right-[6px] inline-block p-[3px] pointer-events-none"></span>
                          </div>
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="startDate" />
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center w-full mb-10">
                        <label
                          htmlFor="closeDate"
                          className="text-lg font-semibold w-[141px]"
                        >
                          Closes
                        </label>
                        <div className="mx-auto w-[445px] relative">
                          <div className="bg-[#F5F8FA] w-full">
                            <DatePickerField
                              name="closeDate"
                              customTimeInterval={intervalTime}
                              toggleInterval={() => {
                                setIntervalTime((prev) => prev + 15);
                              }}
                            />
                            <span className="content-['']  border-r-[2px] border-b-[2px] border-[#7E8299] border-solid rotate-45 absolute top-[45%] translate-y-[-45%] right-[6px] inline-block p-[3px] pointer-events-none"></span>
                          </div>
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="closeDate" />
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center w-full my-10">
                        <label
                          htmlFor="claimPercent"
                          className="text-lg font-semibold w-[141px]"
                        >
                          Claim Percent
                        </label>
                        <div className="mx-auto w-[445px]">
                          <Field
                            name="claimPercent"
                            type="number"
                            className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                          />
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="claimPercent" />
                          </div>
                        </div>
                      </div>

                      <div className="w-[90%] mx-auto pt-10 pb-[5rem] flex">
                        <LoaderButton
                          loading={
                            contractLoading ||
                            isLoadingAddSchedule ||
                            isLoadingScheduleId
                          }
                          type="submit"
                          className="ml-auto bg-[#00A3FF] text-white rounded py-2 pl-2 px-4 hover:scale-95 hover:opacity-[0.9]"
                        >
                          Create Schedule
                        </LoaderButton>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateDistributionSchedule;
