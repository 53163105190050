import { Column } from "react-table";
import { MemberInterface } from "@/interfaces/member";
import { getReadableTimeDiff } from "utils/date.func";
import { getTimeStamp } from "utils/getTimeStamp";
import { formatToCurrencyString } from "utils/formatToCurrencyString";
import { ReactComponent as Ellipsis } from "assets/rocket-img/icons/ellipsis-solid.svg";

export const tableColumns: readonly Column<MemberInterface>[] = [
  {
    Header: "MEMBERS",
    accessor: (Row: MemberInterface) => {
      const { first_name, last_name, email, phoneNumber, id } = Row;
      const members = {
        first_name,
        last_name,
        email,
        phoneNumber,
        id,
      };
      return members;
    },
    id: "members",
    Cell: (Row: any) => {
      const { value } = Row;
      return (
        <div>
          <div className="font-semibold">
            {value.first_name} {value.last_name}
            <p className="text-xs text-[#B5B5C3] font-medium">{value.email}</p>
            <p className="text-xs text-[#B5B5C3] font-medium">
              {value.phoneNumber}
            </p>
          </div>
        </div>
      );
    },
  },
  {
    Header: "MEMBERSHIP TIER",
    accessor: (Row: MemberInterface) => {
      const { subscriptions } = Row;
      return subscriptions;
    },
    id: "tier",
    Cell: (Row: any) => {
      let plan, active, expiresAt;
      if (Row.value) {
        plan = Row?.value?.[0].plan;
        active = Row?.value?.[0].active;
        expiresAt = Row?.value?.[0].expiresAt;
      }
      return (
        <div>
          <div className="font-light">
            {plan?.name}
            <p className="text-xs text-[#B5B5C3] font-medium">{active}</p>
            <p className="text-xs text-[#B5B5C3] font-medium">
              {expiresAt && `Expires ${getReadableTimeDiff(expiresAt * 1000)}`}
            </p>
          </div>
        </div>
      );
    },
  },
  {
    Header: "JOIN DATE",
    accessor: (Row: MemberInterface) => {
      const { create_date } = Row;
      return create_date;
    },
    id: "dateJoined",
    Cell: (Row: any) => {
      const { value } = Row;
      return (
        <div>
          <div className="text-sm">
            {value && `Created ${getReadableTimeDiff(getTimeStamp(value))}`}
          </div>
        </div>
      );
    },
  },
  {
    Header: "LIFETIME CONTRIBUTIONS",
    accessor: (Row: MemberInterface) => {
      const { contributedAmount } = Row;
      return contributedAmount;
    },
    id: "contributions",
    Cell: (Row: any) => {
      const { value } = Row;
      return (
        <div>
          <div className="font-semibold">
            {value && formatToCurrencyString(value)}
          </div>
        </div>
      );
    },
  },
  {
    Header: "STATUS",
    accessor: (Row: MemberInterface) => {
      const { status } = Row;
      return status;
    },
    id: "status",
    Cell: (Row: any) => {
      const { value } = Row;
      return (
        <div className="flex items-center justify-between">
          <div
            className={`p-2 text-xs uppercase font-semibold w-fit rounded-md
                    ${
                      value === "registered"
                        ? "bg-[#50CD89]/10 text-[#50CD89]"
                        : "bg-[#F1416C]/10 text-[#F1416C]"
                    }
                    `}
          >
            {value ? value : "Not registered"}
          </div>
          <span className="flex rotate-90 w-[10px] min-w-[10px] h-[15px]">
            <Ellipsis className="fill-[#7E8299]" />
          </span>
        </div>
      );
    },
  },
  // {
  //   Header: "Action",
  //   accessor: (Row: MemberInterface) => {
  //     const { id } = Row;
  //     return id;
  //   },
  //   id: getColumnID("row" as unknown as MemberInterface),
  //   Cell: (Row: any) => {
  //     const { value } = Row;
  //     return (
  //       <div>
  //         <Link to={`${value}`}>
  //           <span
  //             className={` font-semibold rounded-md  text-[#7E8299] text-sm`}
  //             style={{ width: "100px" }}
  //           >
  //             View
  //           </span>
  //         </Link>
  //       </div>
  //     );
  //   },
  // },
];

function getColumnID(row: any): string {
  return row;
}
