import { IProject } from "@/interfaces/projects";

export const getProjectContributionAmount = (project: IProject) => {
  if (project.contributions && project.contributions.length) {
    const amount = project.contributions.reduce((prev, current) => {
      return current.amount + prev;
    }, 0);
    return amount;
  }

  return 0;
};
