import React, { useMemo, useEffect } from "react";
import type { Dispatch, SetStateAction } from "react";
import { useTable, usePagination, TableOptions, PluginHook } from "react-table";
import type { UsePaginationOptions, Column } from "react-table";
import PaginationButtons from "./utils/PaginationButtons";
import { useNavigate } from "react-router-dom";

type TableType<T extends {}> = {
  tableData: T[];
  tableColumns: readonly Column<any>[];
  isNavigation: boolean;
  active: string;
  hideHeader?: boolean;
  searchErrorMessage?: string;
  dependacies?: any[];
};

type PaginationTable<T extends {}> = TableType<T> & {
  hasPagination: true;
  queryPageIndex: number;
  queryPageSize: number;
  setPageIndex: Dispatch<SetStateAction<number>>;
  totalItems: number;
  isSuccess: boolean;
};

type NoPaginationTable<T extends {}> = TableType<T> & {
  hasPagination: false;
  queryPageIndex?: never;
  queryPageSize?: never;
  setPageIndex?: never;
  totalItems?: never;
  isSuccess?: never;
};

export type TableProps<T extends {}> =
  | PaginationTable<T>
  | NoPaginationTable<T>;

const Table = <T extends {}>({
  tableData,
  tableColumns,
  hasPagination,
  isNavigation,
  active,
  searchErrorMessage,
  setPageIndex,
  queryPageIndex,
  queryPageSize,
  totalItems,
  isSuccess,
  hideHeader,
  dependacies = [],
}: TableProps<T>) => {
  const navigate = useNavigate();
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [...dependacies]);
  const initialState: any = {
    pageSize: queryPageSize,
    pageIndex: queryPageIndex,
  };

  const paginationOptions: UsePaginationOptions<T> = {
    manualPagination: true,
    pageCount:
      isSuccess && totalItems
        ? Math.ceil(totalItems / initialState.pageSize!)
        : 0,
  };
  const tableOptions: TableOptions<any> = {
    columns,
    data,
    initialState,
    ...paginationOptions,
  };
  const plugins: PluginHook<T>[] = [usePagination];

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    rows,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    prepareRow,
  }: any = useTable({ ...tableOptions }, ...plugins);

  const { pageCount } = paginationOptions;

  useEffect(() => {
    if (typeof setPageIndex !== "undefined") {
      setPageIndex(state.pageIndex);
    }
  }, [state.pageIndex]);

  return (
    <>
      <div className="w-full overflow-x-auto">
        <table className="border-collapse my-6  w-full" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any) => {
              const { key, ...restHeaderGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={key}
                  className="text-left text-[#B5B5C3] text-xs lg:text-sm border-b border-[#E5EAEE] border-solid uppercase font-semibold "
                  {...restHeaderGroupProps}
                >
                  {headerGroup.headers.map((column: any) => {
                    const { key, ...restColumn } = column.getHeaderProps();
                    return (
                      <th key={key} className="py-2 px-4" {...restColumn}>
                        {column.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {(hasPagination ? page : rows).map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  onClick={() => {
                    if (isNavigation) {
                      navigate(`/${active}/${row?.original?.id}`);
                    }
                  }}
                  className={`border-b border-[#E5EAEE] border-solid ${
                    isNavigation ? "lg:cursor-pointer" : ""
                  }`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell: any) => {
                    const { key, ...restCellProps } = cell.getCellProps();
                    return (
                      <td
                        key={key}
                        className="py-3 px-4 leading-6 font-semibold"
                        {...restCellProps}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {hasPagination ? (
          <PaginationButtons
            state={state}
            pageCount={pageCount!}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Table;
