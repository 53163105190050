import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import AdminViewHome from "./pages/home/views/adminView";
import UserViewHome from "./pages/home/views/userView";
import AdminViewProjects from "./pages/projects/views/adminView";
import UserViewProjects from "./pages/projects/views/userView";
import AdminViewProjectDetails from "./pages/projects/project_details/views/adminView";
import UserViewProjectDetails from "./pages/projects/project_details/views/userView";
import CreateProject from "./pages/projects/create_new";
import Contributions from "./pages/contributions";
import SignIn from "./pages/authentication/signIn";
import PrivateRoute from "./components/PrivateRoute";
import { useAppContext } from "./contexts/AppContext";
import Members from "./pages/members";
import { setToken } from "./services/api/base";
import { useHydrateState } from "./hooks/useHydrateState";
import { useAccount } from "wagmi";
import User from "./pages/user";
import MemberDetails from "./pages/members/member";
import Claims from "./pages/claims";
import { useCookies } from "react-cookie";

function App() {
  const cookieUtils = useCookies(["token"]);
  const { appState, updateState } = useAppContext();
  const { isAdmin } = appState;

  useHydrateState();

  useEffect(() => {
    setToken(cookieUtils[0]?.token);
  }, [cookieUtils[0]?.token]);

  const { isConnected, isDisconnected, address } = useAccount();

  useEffect(() => {
    if (isConnected) {
      if (!updateState) return;
      updateState({
        walletAddress: address,
        walletConnected: typeof address === "string",
      });
    }
    if (isDisconnected) {
      if (!updateState) return;
      updateState({ walletAddress: undefined, walletConnected: false });
    }
  }, [isConnected, isDisconnected]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            {/* home */}
            <Route path="/" element={<Outlet />}>
              {isAdmin ? (
                <Route index element={<AdminViewHome />} />
              ) : (
                <Route index element={<UserViewHome />} />
              )}
            </Route>
            {/* home */}

            {/* projects */}
            <Route path="projects" element={<Outlet />}>
              {isAdmin ? (
                <Route index element={<AdminViewProjects />} />
              ) : (
                <Route index element={<UserViewProjects />} />
              )}
              <Route path="create" element={<CreateProject />} />
              <Route path=":project" element={<Outlet />}>
                {isAdmin ? (
                  <>
                    <Route index element={<AdminViewProjectDetails />} />
                    <Route path="update" element={<CreateProject />} />
                  </>
                ) : (
                  <Route index element={<UserViewProjectDetails />} />
                )}
              </Route>
            </Route>
            {/* projects */}
            {!isAdmin && <Route path="/claims" element={<Claims />} />}
            <Route path="contributions" element={<Contributions />} />

            <Route path="members" element={<Outlet />}>
              <Route index element={<Members />} />
              <Route path=":member" element={<MemberDetails />} />
            </Route>

            <Route path="/user" element={<User />} />
          </Route>

          <Route path="/signIn" element={<SignIn />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
