import { formatDate } from "utils/formatDate";
import { formatToCurrencyString } from "utils/formatToCurrencyString";
import { IProject } from "interfaces/projects";
import { useNavigate } from "react-router-dom";
import { getProjectContributionAmount } from "./getContributionAmount";

interface ICardProps {
  project: IProject;
}

const ProjectCard = ({ project }: ICardProps) => {
  const navigate = useNavigate();
  const contributionAmount = project?.id
    ? getProjectContributionAmount(project)
    : 0;
  const contributionPercentage = parseFloat(
    ((contributionAmount / parseFloat(`${project?.pool_size}`)) * 100).toFixed(
      2
    )
  );
  return (
    <>
      <button
        onClick={() => {
          project.status === "Live" ? navigate(`${project.id}`) : "";
        }}
        className="bg-white text-left rounded-lg mb-6 w-full mx-auto sm:mx-0 pt-4 relative"
      >
        <div className="flex justify-between my-6 mx-6">
          <div className="w-[30px] h-[30px] p-2 rounded-lg bg-[#F5F8FA]">
            <img
              className="w-full h-full object-contain"
              src={project.icon}
              alt="project logo"
            />
          </div>
          <div>
            <p
              className={`flex justify-center font-bold rounded p-2 max-w-[100px] ml-auto ${
                project.status === "Upcoming"
                  ? "bg-[#F5F8FA] text-[#A1A5B7]"
                  : project.status === "Live"
                  ? "bg-[#F1FAFF] text-[#00A3FF]"
                  : "bg-[#E8FFF3] text-[#50CD89]"
              }`}
            >
              {project.status}
            </p>
            {project.status === "Live" ? (
              <p className="text-dark font-bold mt-2">
                {contributionPercentage}
                {"%"} filled
              </p>
            ) : project.status === "Completed" ? (
              <p className="text-[#5E6278] font-bold">100%</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="p-4">
          <h3 className="text-dark font-boldest">{project.name}</h3>
          <p className="text-muted">{project.description}</p>
          <div className="flex items-center my-6 mx-3">
            <div className="shrink-0 border-2 border-[#E4E6EF] border-dashed rounded p-2 text-sm">
              <p className="my-0 text-[#5E6278] font-bold">
                {formatDate(project?.open_time)}
              </p>
              <p className="text-muted">Pool Opens</p>
            </div>
            <div className="shrink-0 border-2 border-[#E4E6EF] border-dashed rounded p-2 text-sm ml-4">
              <p className="my-0 text-[#5E6278] font-bold">
                {formatToCurrencyString(parseFloat(project.pool_size))} USDT
              </p>
              <p className="text-muted">Pool Size</p>
            </div>
          </div>
        </div>
        <div className="progress h-[3px] absolute bottom-0 w-full">
          <div
            className={`progress-bar ${
              project.status === "Upcoming"
                ? "bg-[#E4E6EF]"
                : project.status === "Live"
                ? "bg-[#00A3FF]"
                : "bg-[#50CD89]"
            }`}
            role="progressbar"
            style={{
              width: `${
                project.status === "Live" ? contributionPercentage : 100
              }%`,
            }}
            aria-valuenow={
              project.status === "Live" ? contributionPercentage : 100
            }
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div>
      </button>
    </>
  );
};

export default ProjectCard;
