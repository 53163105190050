import { Contribution, IProject } from "@/interfaces/projects";
import Table from "components/Table";
import { formatDate } from "utils/formatDate";
import type { Column } from "react-table";
import { formatToCurrencyString } from "utils/formatToCurrencyString";

interface ContributionsProps {
  projectDetails: IProject | undefined;
}
const Contributions = ({ projectDetails }: ContributionsProps) => {
  const getContributions = (): ContributionTable[] => {
    const contributions = projectDetails?.contributions;
    if (contributions) {
      return contributions.map((contribution) => ({
        amount: contribution.amount,
        tokenSymbol: projectDetails.token_code,
        swapRate: projectDetails.swap_rate,
        status: "Contributed",
        date: contribution.create_date,
      }));
    }
    return [];
  };
  return (
    <Table
      tableColumns={tableColumns}
      tableData={getContributions()}
      hasPagination={false}
      active="contributions-user"
      isNavigation={false}
    />
  );
};

export default Contributions;

export interface ContributionTable {
  isSelected?: boolean;
  amount: number;
  tokenSymbol: string;
  swapRate: number | string;
  status: string;
  date?: string;
}
const getStyles = (value: string) => {
  if (value === "released") {
    return {
      backgroundColor: "#DFF1EB",
      color: "#2BA579",
    };
  } else if (value === "upcoming") {
    return {
      backgroundColor: "#F5F8FA",
      color: "#A1A5B7",
    };
  } else if (value === "closed") {
    return {
      backgroundColor: "#FFE2E5",
      color: "#F64E60",
    };
  } else {
    return {
      backgroundColor: "#F1FAFF",
      color: "#00A3FF",
    };
  }
};
export const tableColumns: readonly Column<ContributionTable>[] = [
  {
    Header: "Contribution",
    accessor: "amount",
    Cell: (Row) => {
      const { value } = Row;
      return <span>${formatToCurrencyString(value)}</span>;
    },
  },
  {
    Header: "Reward Token",
    accessor: "tokenSymbol",
    Cell: (Row) => {
      const { swapRate, amount, tokenSymbol } = Row.row.original;
      const tokenAmount = parseFloat(`${swapRate}`) * amount;
      return (
        <span>
          {formatToCurrencyString(tokenAmount)}
        </span>
      );
    },
  },

  {
    Header: "Status",
    accessor: "status",
    Cell: (Row) => {
      const { value } = Row;
      return (
        <div
          className="py-2 px-4 capitalize rounded-lg leading-5 w-fit text-xs"
          style={getStyles(value)}
        >
          {value}
        </div>
      );
    },
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: (Row) => {
      const { value } = Row;

      return <span>{formatDate(value)}</span>;
    },
  },
];
