import useDisplayPaginationButtons from "../hooks/useDisplayPaginationButtons";
import { ReactComponent as Chevron } from "assets/rocket-img/icons/chevron-left-solid.svg";
import { ReactComponent as Ellipsis } from "assets/rocket-img/icons/ellipsis-solid.svg";

interface PaginationButtonsProps {
  state: any;
  pageOptions: number[];
  pageCount: number;
  gotoPage: any;
  previousPage: any;
  canPreviousPage: boolean;
  nextPage: any;
  canNextPage: boolean;
}

const PaginationButtons = ({
  state,
  pageOptions,
  pageCount,
  gotoPage,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
}: PaginationButtonsProps) => {
  const { displayPaginationButtons, paginateButtons } =
    useDisplayPaginationButtons(state, pageOptions, pageCount, gotoPage);
  return (
    <>
      <div className="flex items-center justify-center w-full text-sm">
        <button
          className="hidden sm:flex mr-4 sm:mr-auto lg:mr-0  ml-4  xs:ml-8 lg:ml-auto px-4 py-2 rounded-xl text-sm text-[#344054] disabled:opacity-50"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <div className="mr-2 w-[15px] min-w-[15px] h-[15px]">
            <Chevron className="fill-[#7E8299]" />
          </div>
        </button>
        {!paginateButtons.includes(1) ? (
          <div className="flex items-center ml-auto lg:ml-0">
            <button
              className="text-[#667085] px-3 py-1 rounded-sm mx-2"
              style={
                state.pageIndex === 0
                  ? {
                      backgroundColor: "#00A3FF",
                      color: "#fff",
                      borderColor: "#00A3FF",
                    }
                  : {}
              }
              onClick={() => {
                gotoPage(0);
              }}
            >
              {1}
            </button>

            {state.pageIndex >= 3 ? (
              <div className="w-[10px] h-[10px] flex justify-center  mx-1">
                <Ellipsis className="fill-[#7E8299]" />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {displayPaginationButtons()}

        {state.pageIndex <= pageOptions.length - 4 && pageCount > 4 ? (
          <>
            <div className="w-[10px] h-[10px]  mx-1">
              <Ellipsis className="fill-[#7E8299]" />
            </div>
          </>
        ) : (
          ""
        )}
        <div className="mr-auto lg:mr-0">
          <button
            className="text-[#667085] py-1 px-3 mx-2"
            style={
              pageCount === state.pageIndex + 1
                ? {
                    backgroundColor: "#00A3FF",
                    color: "#fff",
                    borderColor: "#00A3FF",
                    borderRadius: "0.3rem",
                  }
                : {}
            }
            onClick={() => {
              gotoPage(pageCount - 1);
            }}
          >
            {pageCount}
          </button>
        </div>
        <button
          className="ml-auto lg:ml-0  hidden sm:flex mr-4 xs:mr-8 px-4 py-2 rounded-xl text-sm text-[#344054] disabled:opacity-50"
          onClick={nextPage}
          disabled={!canNextPage}
        >
          <div className="ml-2 w-[15px] min-w-[15px] h-[15px]">
            <Chevron className="rotate-180 fill-[#7E8299]" />
          </div>
        </button>
      </div>
      <div className="w-full my-3 flex sm:hidden">
        <button
          className="flex mr-auto lg:mr-0  ml-3 xs:ml-8 px-4 py-2 rounded-xl text-sm text-[#344054] disabled:opacity-50"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <div className="mr-2 w-[15px] min-w-[15px] h-[15px]">
            <Chevron />
          </div>
        </button>
        <button
          className="ml-auto lg:ml-0 flex mr-3 xs:mr-8 px-4 py-2 rounded-xl text-sm text-[#344054] disabled:opacity-50"
          onClick={nextPage}
          disabled={!canNextPage}
        >
          <div className="ml-2 w-[15px] min-w-[15px] h-[15px]">
            <Chevron className="rotate-180 fill-[#7E8299]" />
          </div>
        </button>
      </div>
    </>
  );
};

export default PaginationButtons;
