import { PaginatedResponse, rocketAPI } from "./base";
import { MemberInterface } from "@/interfaces/member";

const routes = {
  getAllMembers: "/customer/get-all-customers",
  getMember: "/customer",
};

export const GET_ALL_MEMBERS = async (query: any) => {
  const { queryKey } = query;
  return await rocketAPI.get<{ users: MemberInterface[] }>(
    routes.getAllMembers,
    {
      params: {
        currentPage: queryKey[1]?.page,
      },
    }
  );
};

export const GET_MEMBER = (query: any) => {
  const { queryKey } = query;
  return rocketAPI.get<MemberInterface>(`${routes.getMember}/${queryKey[1]}`);
};
