import {
  Contribution,
  DistributionSchedule,
  ICreateProject,
  IProject,
  SubscriptionDataResponse,
} from "interfaces/projects";
import { PaginatedResponse, Query, rocketAPI } from "./base";

const projectRoutes = {
  project: "/project",
  contributionSchedules: "/project/get-contribution-schedule/",
  addContributionSchedule: "/project/add-contribution-schedule",
  subscription: "/subscription",
  contributionStatus: `/project/pause-contribution/`,
  poolId: `/project/update-pool-id`,
  scheduleId: `/project/update-contribution-schedule-id`,
  distributionSchedules: `distribution`,
  distributionSceduleId: `distribution/update-id`,
};

export const CREATE_PROJECT = (data: { project: ICreateProject }) => {
  const { project } = data;
  return rocketAPI.post(projectRoutes.project, project);
};
export const UPDATE_PROJECT = (
  data:
    | {
        projectId: string;
        projectDetails: ICreateProject;
      }
    | {
        projectId: string;
        projectDetails: {
          swap_rate: number;
          status: string;
        };
      }
) => {
  const { projectId, projectDetails } = data;
  return rocketAPI.patch(
    `${projectRoutes.project}/${projectId}`,
    projectDetails
  );
};

export const GET_PROJECTS = (query: any) => {
  const { queryKey } = query;
  return rocketAPI.get<PaginatedResponse<IProject>>(projectRoutes.project, {
    params: {
      currentPage: queryKey[1]?.page,
    },
  });
};

export const GET_PROJECT = (query: Query<string>) => {
  const { queryKey } = query;
  console.log(query);

  return rocketAPI.get<IProject>(`${projectRoutes.project}/${queryKey[1]}`);
};

export const GET_SCHEDULES = (query: any) => {
  const { queryKey } = query;
  return rocketAPI.get(
    `${projectRoutes.contributionSchedules}${queryKey[1]?.id}`,
    {
      params: {
        currentPage: queryKey[1]?.page,
      },
    }
  );
};

export const ADD_NEW_SCHEDULE = (data: any) => {
  const { schedule, id } = data;

  return rocketAPI.post(
    `${projectRoutes.addContributionSchedule}/${id}`,
    schedule
  );
};

export const UPDATE_SCHEDULE_ID = (data: {
  id: string;
  scheduleId: string;
}) => {
  const { scheduleId, id } = data;
  return rocketAPI.post(`${projectRoutes.scheduleId}/${id}`, {
    scheduleId: scheduleId,
  });
};

export const GET_SUBSCRIPTION = () => {
  return rocketAPI.get<SubscriptionDataResponse[]>(projectRoutes.subscription);
};

export const UPDATE_POOL_ID = (data: { projectId: string; poolId: string }) => {
  const { projectId, poolId } = data;
  return rocketAPI.post(`${projectRoutes.poolId}/${projectId}`, { poolId });
};

export const DELETE_PROJECT = (data: { projectId: string }) => {
  const { projectId } = data;
  return rocketAPI.delete(`${projectRoutes.project}/${projectId}`);
};

export const GET_CONTRIBUTIONS = (query: any) => {
  const { queryKey } = query;
  return rocketAPI.get<PaginatedResponse<Contribution>>(
    `/contribution?project_id=${queryKey[1]?.projectId}`,
    {
      params: {
        currentPage: queryKey[1]?.page,
      },
    }
  );
};

export const SET_CONTRIBUTION_STATUS = (data: {
  projectId: string;
  body: {
    status: boolean;
  };
}) => {
  const { projectId, body } = data;
  return rocketAPI.post(
    `${projectRoutes.contributionStatus}${projectId}`,
    body
  );
};

export const GET_DISTRIBUTION_SCHEDULES = (query: any) => {
  const { queryKey } = query;
  return rocketAPI.get<PaginatedResponse<DistributionSchedule>>(
    `${projectRoutes.distributionSchedules}/${queryKey[1]?.projectId}`,
    {
      params: {
        currentPage: queryKey[1]?.page,
      },
    }
  );
};

export const CREATE_DISTRIBUTION_SCHEDULE = (data: {
  scheduleDetails: {
    projectId: string;
    startDate: string;
    closeDate: string;
    claimPercentage: string;
  };
}) => {
  const { scheduleDetails } = data;
  return rocketAPI.post(
    `${projectRoutes.distributionSchedules}`,
    scheduleDetails
  );
};

export const UPDATE_DISTRIBUTION_SCHEDULE = (data: {
  scheduleDetails: Partial<DistributionSchedule>;
}) => {
  const { scheduleDetails } = data;
  return rocketAPI.post<DistributionSchedule>(
    `${projectRoutes.distributionSchedules}`,
    scheduleDetails
  );
};

export const UPDATE_DISTRIBUTION_SCHEDULE_ID = (data: {
  blockchainDistributionId: string;
  distributionId: string;
}) => {
  const { distributionId, blockchainDistributionId } = data;
  return rocketAPI.post(
    `${projectRoutes.distributionSceduleId}/${distributionId}`,
    {
      distributionId: blockchainDistributionId,
    }
  );
};

export const CLAIM = (body: {
  data: {
    projectId: string;
    distributionId: string;
    contributionId: string;
  };
  method: "GET" | "POST";
}) => {
  const { method, data } = body;
  if (method === "GET") {
    return rocketAPI.get(`/claim`);
  }
  return rocketAPI.post(`/claim`, data);
};
