import { useState, useEffect } from "react";
import type { Dispatch, SetStateAction, ChangeEvent } from "react";
import {
  useContract,
  useSigner,
  useContractEvent,
  chain,
  useConnect,
  useSwitchNetwork,
} from "wagmi";
import CONTRACT_ABI from "contract/abi.json";
import { BNB_CHAIN, BNB_CHAIN_TEST } from "CHAINS";
import { InjectedConnector } from "wagmi/connectors/injected";
import { useAppContext } from "contexts/AppContext";
import { getTimeStamp } from "utils/getTimeStamp";
import { LoaderButton } from "components/loader-utils/LoaderButton";
import { useMutation, useQuery } from "react-query";
import SuccessModal from "../../../SuccessModal";
import CloseIcon from "assets/rocket-img/icons/close_icon.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import type { FormikProps, FieldProps } from "formik";
import * as yup from "yup";
import DatePickerField from "../../DatePicker";
import {
  ADD_NEW_SCHEDULE,
  GET_SUBSCRIPTION,
  UPDATE_SCHEDULE_ID,
} from "services/api/projects";
import { IMetaMaskErr, IRouteError } from "interfaces/general";
import { IProject } from "interfaces/projects";
import { convertToFloat } from "utils/convertToFloat";
import { useSnackbar } from "notistack";

interface ICreateSchedules {
  refetchSchedules: any;
  details: IProject;
  setIsNewSchedule: Dispatch<SetStateAction<boolean>>;
}
interface ContributionScheduleForm {
  name: string;
  min_contribution: string;
  min_contributionFormatted: string;
  max_contribution: string;
  max_contributionFormatted: string;
  contribution_fee: number;
  open_datetime: string;
  close_datetime: string;
  subscription: string;
}

const CreateScheduleModal = ({
  refetchSchedules,
  details,
  setIsNewSchedule,
}: ICreateSchedules) => {
  const { enqueueSnackbar } = useSnackbar();
  const { appState } = useAppContext();
  const { walletConnected } = appState;
  const getChainId = (network: string) => {
    if (network === "Polygon") {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        return chain.polygonMumbai.id;
      else return chain.polygon.id;
    } else if (network?.includes("Binance")) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        return BNB_CHAIN_TEST.id;
      else return BNB_CHAIN.id;
    } else {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        return chain.goerli.id;
      else return chain.mainnet.id;
    }
  };
  const { switchNetwork } = useSwitchNetwork();

  const { connect } = useConnect({
    chainId: getChainId(details?.contribution_network),
    connector: new InjectedConnector(),
    onSuccess: (data) => {
      if (data.chain.id !== getChainId(details?.contribution_network)) {
        switchNetwork?.(getChainId(details?.contribution_network));
      }
    },
  });

  useEffect(() => {
    if (!walletConnected) {
      connect();
    }
  }, []);
  const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const [scheduleId, setScheduleId] = useState<string>();
  const [contractLoading, setContractLoading] = useState(false);
  const { data: signer } = useSigner();
  const contract = useContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    signerOrProvider: signer,
  });

  const [intervalTime, setIntervalTime] = useState(30);
  const initialValues: ContributionScheduleForm = {
    name: "",
    min_contribution: "",
    min_contributionFormatted: "",
    max_contribution: "",
    max_contributionFormatted: "",
    contribution_fee: 0,
    open_datetime: "",
    close_datetime: "",
    subscription: "",
  };
  const validationSchema = yup.object({
    name: yup.string().required("Please enter a name"),
    min_contributionFormatted: yup.string().required("Please enter a value"),
    max_contributionFormatted: yup.string().required("Please enter a value"),
    contribution_fee: yup
      .number()
      .max(100, "Value cannot be more than 100")
      .required("Please enter a value"),
    open_datetime: yup.string().required("Please enter a value"),
    close_datetime: yup.string().required("Please enter a value"),
    subscription: yup.string().required("Please select a subscription"),
  });
  const {
    mutateAsync,
    isSuccess: scheduleCreated,
    isLoading: isLoadingAddSchedule,
  } = useMutation("createSchedule", ADD_NEW_SCHEDULE, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });
  const { mutateAsync: updateScheduleId, isLoading: isLoadingScheduleId } =
    useMutation("updateScheduleID", UPDATE_SCHEDULE_ID, {
      onError: (error) => {
        const errorMessage = (error as IRouteError)?.response.data.message;
        enqueueSnackbar({
          variant: "error",
          message: errorMessage,
          autoHideDuration: 5000,
        });
      },
    });
  const { data: subscriptions, isSuccess: subscriptionIsSuccess } = useQuery(
    "getSubs",
    GET_SUBSCRIPTION,
    {
      staleTime: Infinity,
    }
  );
  const displaySubscriptions = () => {
    const options = subscriptions?.data;
    if (subscriptionIsSuccess) {
      return (
        <>
          <option value=""></option>
          {options?.map((subscription) => (
            <option
              key={subscription?.id}
              value={`${subscription?.name},${subscription?.nft?.nftTierId}`}
            >
              {subscription?.name}
            </option>
          ))}
        </>
      );
    } else return [];
  };

  useContractEvent({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    eventName: "CreatedContributionSchedule",
    listener(...args: any) {
      console.log("event args: ", args);
      setScheduleId(args[1]);
    },
    once: true,
  });

  const createScheduleWeb3 = async (values: ContributionScheduleForm) => {
    try {
      setContractLoading(true);
      const tierId = values.subscription.split(",")[1];
      const min_contributionFloat = convertToFloat(values.min_contribution);
      const max_contributionFloat = convertToFloat(values.max_contribution);
      console.log(
        "params: ",
        details?.pool_id,
        Number(tierId),
        min_contributionFloat,
        max_contributionFloat,
        Number(values.contribution_fee),
        getTimeStamp(`${values.open_datetime}`),
        getTimeStamp(`${values.close_datetime}`)
      );

      const transaction = await contract?.createContributionSchedule(
        details?.pool_id,
        Number(tierId),
        min_contributionFloat,
        max_contributionFloat,
        Number(values.contribution_fee),
        Math.ceil(getTimeStamp(`${values.open_datetime}`) / 1000),
        Math.ceil(getTimeStamp(`${values.close_datetime}`) / 1000)
      );
      //   wait for transactionResponse
      const transactionReceipt = await transaction.wait(2);
      console.log("reciept: ", transactionReceipt);

      setContractLoading(false);
      return transactionReceipt;
    } catch (error) {
      setContractLoading(false);
      console.log(error);

      if ((error as IMetaMaskErr)?.code) {
        enqueueSnackbar({
          variant: "error",
          message: (error as IMetaMaskErr).error.data.message,
          autoHideDuration: 5000,
        });
      }
    }
  };
  const handleSubmit = async (values: ContributionScheduleForm) => {
    try {
      if (scheduleId) {
        const subscriptionName = values.subscription.split(",")[0];
        const formatted_close = new Date(values.close_datetime);
        const formatted_open = new Date(values.open_datetime);
        const res = await mutateAsync({
          schedule: {
            close_datetime: formatted_close.toISOString(),
            contribution_fee: values.contribution_fee,
            max_contribution: parseFloat(values.max_contribution),
            min_contribution: parseFloat(values.min_contribution),
            name: values.name,
            open_datetime: formatted_open.toISOString(),
            subscription: subscriptions?.data?.find(
              (item) => item?.name === subscriptionName
            )?.id,
          },
          id: details?.id,
        });

        await updateScheduleId({
          id: res?.data?.id,
          scheduleId: scheduleId,
        });
        refetchSchedules();
        // setIsNewSchedule(false);
      }
    } catch (error) {
      return;
    }
  };
  return (
    <>
      <div
        className={`bg-white md:bg-black/50 w-full min-w-[100vw] pt-8 pb-[5rem] md:py-[5rem] h-full min-h-[100vh] overflow-y-auto fixed top-0 left-0 flex  ${
          scheduleCreated ? "z-[800]" : "z-[999]"
        }`}
      >
        <div
          className={`w-full md:w-[70%] lg:w-[60%] h-fit  relative z-[150] mx-auto bg-white rounded-xl`}
        >
          <div className="px-8 py-6">
            <div className="flex w-full">
              <button
                type="button"
                onClick={() => setIsNewSchedule(false)}
                className="w-[30px] h-[30px] ml-auto opacity-[0.5]"
              >
                <img src={CloseIcon} alt="close" />
              </button>
            </div>
            <div className="w-full">
              <h2 className="text-xl font-semibold mb-4">Add New Schedule</h2>
              <div className="border-t border-[#EFF2F5] border-solid w-full"></div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  createScheduleWeb3(values);
                }}
              >
                {(props: FormikProps<ContributionScheduleForm>) => {
                  const { values } = props;
                  useEffect(() => {
                    if (scheduleId) {
                      handleSubmit(values);
                    }
                  }, [scheduleId]);
                  return (
                    <Form>
                      <div className="flex flex-col sm:flex-row sm:items-center w-full my-10">
                        <label
                          htmlFor="name"
                          className="text-lg font-semibold w-[141px] mb-2 sm:mb-0"
                        >
                          Name
                        </label>
                        <div className="sm:mx-auto w-full sm:w-[445px]">
                          <Field
                            name="name"
                            type="text"
                            className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                          />
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="name" />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row sm:items-center w-full my-10">
                        <label
                          htmlFor="min_contributionFormatted"
                          className="text-lg font-semibold w-[141px] mb-2 sm:mb-0"
                        >
                          Min Contribution
                        </label>
                        <div className="sm:mx-auto w-full sm:w-[445px]">
                          <Field name="min_contributionFormatted">
                            {({ form, field }: FieldProps<number>) => {
                              const [fieldType, setFieldType] =
                                useState("number");
                              const handleChange = (
                                e: ChangeEvent<HTMLInputElement>
                              ) => {
                                form.setFieldValue(
                                  "min_contribution",
                                  e.target.value
                                );
                                form.setFieldValue(
                                  "min_contributionFormatted",
                                  e.target.value
                                );
                              };
                              const handleFocus = (
                                e: ChangeEvent<HTMLInputElement>
                              ) => {
                                if (values.min_contribution) {
                                  setFieldType("number");
                                  //  remove formatting on focus
                                  form.setFieldValue(
                                    "min_contributionFormatted",
                                    values.min_contribution
                                  );
                                }
                              };
                              const handleBlur = (
                                e: ChangeEvent<HTMLInputElement>
                              ) => {
                                if (e.target.value === "") return;
                                setFieldType("text");
                                const value = String(
                                  values.min_contribution
                                ).replace(/,/g, "");
                                // format value on blur
                                form.setFieldValue(
                                  "min_contributionFormatted",
                                  `${parseFloat(value).toLocaleString("en-US", {
                                    style: "decimal",
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  })}`
                                );
                              };
                              return (
                                <input
                                  type={fieldType}
                                  name="min_contributionFormatted"
                                  value={field.value}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                                />
                              );
                            }}
                          </Field>
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="min_contribution" />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row sm:items-center w-full my-10">
                        <label
                          htmlFor="max_contribution"
                          className="text-lg font-semibold w-[141px] mb-2 sm:mb-0"
                        >
                          Max Contribution
                        </label>
                        <div className="sm:mx-auto w-full sm:w-[445px]">
                          <Field name="max_contributionFormatted">
                            {({ form, field }: FieldProps<number>) => {
                              const [fieldType, setFieldType] =
                                useState("number");
                              const handleChange = (
                                e: ChangeEvent<HTMLInputElement>
                              ) => {
                                form.setFieldValue(
                                  "max_contribution",
                                  e.target.value
                                );
                                form.setFieldValue(
                                  "max_contributionFormatted",
                                  e.target.value
                                );
                              };
                              const handleFocus = (
                                e: ChangeEvent<HTMLInputElement>
                              ) => {
                                if (values.max_contribution) {
                                  setFieldType("number");
                                  //  remove formatting on focus
                                  form.setFieldValue(
                                    "max_contributionFormatted",
                                    values.max_contribution
                                  );
                                }
                              };
                              const handleBlur = (
                                e: ChangeEvent<HTMLInputElement>
                              ) => {
                                if (e.target.value === "") return;
                                setFieldType("text");
                                const value = String(
                                  values.max_contribution
                                ).replace(/,/g, "");
                                // format value on blur
                                form.setFieldValue(
                                  "max_contributionFormatted",
                                  `${parseFloat(value).toLocaleString("en-US", {
                                    style: "decimal",
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  })}`
                                );
                              };
                              return (
                                <input
                                  type={fieldType}
                                  name="max_contributionFormatted"
                                  value={field.value}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                                />
                              );
                            }}
                          </Field>
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="max_contribution" />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row sm:items-center w-full my-10">
                        <label
                          htmlFor="contribution_fee"
                          className="text-lg font-semibold w-[141px] mb-2 sm:mb-0"
                        >
                          Contribution Fee (%)
                        </label>
                        <div className="sm:mx-auto w-full sm:w-[445px]">
                          <Field
                            name="contribution_fee"
                            type="number"
                            className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                          />
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="contribution_fee" />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row sm:items-center w-full mb-10">
                        <label
                          htmlFor="open_datetime"
                          className="text-lg font-semibold w-[141px] mb-2 sm:mb-0"
                        >
                          Opens
                        </label>
                        <div className="sm:mx-auto w-full sm:w-[445px] relative">
                          <div className="bg-[#F5F8FA] w-full">
                            <DatePickerField
                              name="open_datetime"
                              customTimeInterval={intervalTime}
                              toggleInterval={() => {
                                setIntervalTime((prev) => prev + 30);
                              }}
                            />
                            <span className="content-['']  border-r-[2px] border-b-[2px] border-[#7E8299] border-solid rotate-45 absolute top-[45%] translate-y-[-45%] right-[6px] inline-block p-[3px] pointer-events-none"></span>
                          </div>
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="open_datetime" />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row sm:items-center w-full mb-10">
                        <label
                          htmlFor="close_datetime"
                          className="text-lg font-semibold w-[141px] mb-2 sm:mb-0"
                        >
                          Closes
                        </label>
                        <div className="sm:mx-auto w-full sm:w-[445px] relative">
                          <div className="bg-[#F5F8FA] w-full">
                            <DatePickerField
                              name="close_datetime"
                              customTimeInterval={intervalTime}
                              toggleInterval={() => {
                                setIntervalTime((prev) => prev + 15);
                              }}
                            />
                            <span className="content-['']  border-r-[2px] border-b-[2px] border-[#7E8299] border-solid rotate-45 absolute top-[45%] translate-y-[-45%] right-[6px] inline-block p-[3px] pointer-events-none"></span>
                          </div>
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="close_datetime" />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row sm:items-center w-full mb-10">
                        <label
                          htmlFor="subscription"
                          className="text-lg font-semibold w-[141px] mb-2 sm:mb-0"
                        >
                          Subscription
                        </label>
                        <div className="sm:mx-auto w-full sm:w-[445px]  relative">
                          <Field
                            name="subscription"
                            as="select"
                            className="appearance-none cursor-pointer bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                          >
                            {displaySubscriptions()}
                          </Field>
                          <span className="content-['']  border-r-[2px] border-b-[2px] border-[#7E8299] border-solid rotate-45 absolute top-[45%] translate-y-[-45%] right-[6px] inline-block p-[3px] pointer-events-none"></span>
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="subscription" />
                          </div>
                        </div>
                      </div>
                      <div className="w-full sm:w-[90%] sm:mx-auto pt-10 pb-[5rem] flex">
                        <LoaderButton
                          loading={
                            contractLoading ||
                            isLoadingAddSchedule ||
                            isLoadingScheduleId
                          }
                          type="submit"
                          className="w-full sm:w-auto sm:ml-auto bg-[#00A3FF] text-white rounded py-2 pl-2 px-4 hover:scale-95 hover:opacity-[0.9]"
                        >
                          Create Schedule
                        </LoaderButton>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {scheduleCreated ? (
        <SuccessModal
          message="Schedule has been created"
          toggleClose={() => setIsNewSchedule(false)}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CreateScheduleModal;
