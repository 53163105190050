import { Contribution } from "@/interfaces/projects";
import { PaginatedResponse, Query, rocketAPI } from "./base";

const contributionRoutes = {
  getAll: `contribution/user/all`,
  create: "contribution",
  update_id: "contribution/update-contribution-tnx-hash",
};

export const GET_USER_CONTRIBUTIONS = () => {
  return rocketAPI.get<PaginatedResponse<Contribution>>(
    contributionRoutes.getAll
  );
};

export const GET_USER_CLAIMS = () => {};
export const GET_CLAIMABLE_CONTRIBUTIONS = (
  query: Query<{ contributionId: string; projectId: string }>
) => {
  const { queryKey } = query;
  if (typeof queryKey[1] !== "string") {
    return rocketAPI.get(
      `/contribution/${queryKey[1].contributionId}/claim-distribution/${queryKey[1].projectId}`
    );
  }
};
export const CREATE_CONTRIBUTION = (data: {
  project_id: string;
  amount: number;
  token: string;
  swap_rate: string;
  contribution_fee: number;
  contribution_network: string;
  contribution_schedule_id: string;
}) => {
  return rocketAPI.post<{
    amount: number;
    claimedBatchId: number;
    contribution_fee: number;
    contribution_network: string;
    contribution_schedule_id: string;
    customer_id: string;
    id: string;
    project_id: string;
    swap_rate: string;
    token: string;
  }>(contributionRoutes.create, data);
};

export const UPDATE_CONTRIBUTION_ID = (data: {
  id: string;
  contributionDetails: {
    tx_hash: string;
    blockchainContributionId: number;
  };
}) => {
  const { id, contributionDetails } = data;
  return rocketAPI.patch(
    `${contributionRoutes.update_id}/${id}`,
    contributionDetails
  );
};
