import { Helmet } from "react-helmet";
import Header from "components/header";
import Table from "components/Table";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import {
  GET_PROJECTS,
  DELETE_PROJECT,
  SET_CONTRIBUTION_STATUS,
} from "services/api/projects";
import { Column } from "react-table";
import EditIcon from "assets/rocket-img/icons/edit_icon.png";
import DeleteIcon from "assets/rocket-img/icons/delete_icon.png";
import PauseIcon from "assets/rocket-img/icons/select_icon.png";
import { ReactComponent as PlayIcon } from "assets/rocket-img/icons/play_icon.svg";
import { IProject } from "interfaces/projects";
import { formatToCurrencyString } from "utils/formatToCurrencyString";
import Spinner from "components/loader-utils/Spinner";
import { useState } from "react";
import { getProjectContributionAmount } from "components/projects/getContributionAmount";

const AdminView = () => {
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const {
    data: projects,
    refetch: refetchProjects,
    isLoading,
    isSuccess,
  } = useQuery(
    [
      "projects",
      {
        page: page + 1,
      },
    ],
    GET_PROJECTS,
    {
      staleTime: Infinity,
    }
  );

  const displayProjects = () => {
    if (projects?.data?.items && Array.isArray(projects?.data?.items)) {
      const allProjects = projects?.data.items?.map((project) => {
        return {
          id: project?.id,
          contributors:
            project?.contributions && project?.contributions.length
              ? project?.contributions.length
              : 0,
          poolSize: project?.pool_size,
          status: project?.status,
          pause_contribution: project?.pause_contribution,
          others: project,
        };
      });
      return [...allProjects];
    }
    return [];
  };

  const { mutate: setContributionStatus } = useMutation(
    "pauseContribution",
    SET_CONTRIBUTION_STATUS,
    {
      onSuccess: () => {
        refetchProjects();
      },
    }
  );
  const { mutate: deleteProject } = useMutation("delete", DELETE_PROJECT, {
    onSuccess: () => {
      refetchProjects();
    },
  });
  const getStyles = (value: string) => {
    if (value === "released") {
      return {
        backgroundColor: "#DFF1EB",
        color: "#2BA579",
      };
    } else if (value === "upcoming") {
      return {
        backgroundColor: "#F5F8FA",
        color: "#A1A5B7",
      };
    } else if (value === "closed") {
      return {
        backgroundColor: "#FFE2E5",
        color: "#F64E60",
      };
    } else {
      return {
        backgroundColor: "#F1FAFF",
        color: "#00A3FF",
      };
    }
  };
  // table column
  const tableColumns: readonly Column<IProjectsTableData>[] = [
    {
      Header: "Id",
      accessor: "id",
      Cell: (Row) => {
        const { value, row } = Row;
        return (
          <Link
            to={{
              pathname: `/projects/${value}`,
            }}
          >
            <div className="font-semibold flex items-center">
              <div className="w-[50px] h-[50px] mr-2 p-3 bg-[#F0F0FA] rounded-lg">
                <img
                  src={row.original?.others.icon}
                  className="w-full h-full"
                  alt="icon"
                />
              </div>
              <div>
                {row.original?.others.name}
                <p className="text-xs text-[#B5B5C3] font-medium">
                  {row.original?.others.contribution_network}
                </p>
              </div>
            </div>
          </Link>
        );
      },
    },
    {
      Header: "Contributors",
      accessor: "contributors",
      Cell: (Row) => {
        const { value } = Row;
        return <span>{value}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (Row) => {
        const { value } = Row;

        return (
          <div
            className="py-2 px-4 capitalize rounded-lg leading-5 w-fit text-xs"
            style={getStyles(value)}
          >
            {value}
          </div>
        );
      },
    },
    {
      Header: "Pool Size",
      accessor: "poolSize",
      id: "pool_size",
      Cell: (Row) => {
        const { value, row } = Row;
        const { status, others } = row.original;
        const contributionAmount = getProjectContributionAmount(others);
        const contributionPercentage = parseFloat(
          ((contributionAmount / parseFloat(`${value}`)) * 100).toFixed(2)
        );

        return (
          <div>
            <div className="flex text-xs text-[#B5B5C3] mb-2">
              <p>
                ${formatToCurrencyString(contributionAmount)} -{" "}
                {contributionPercentage}%
              </p>
              <p className="ml-auto">
                {"$"}
                {`${formatToCurrencyString(Number(value))}`}
              </p>
            </div>
            <div className={`progress h-[6px] w-[${contributionAmount}] `}>
              <div
                className="progress-bar"
                role="progressbar"
                style={getStyles(status)}
                aria-valuenow={contributionPercentage}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
          </div>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "pause_contribution",
      Cell: (Row) => {
        const { others, pause_contribution } = Row.row.original;

        return (
          <div className="flex items-center">
            <button
              onClick={() => {
                setContributionStatus({
                  projectId: others?.id,
                  body: { status: !pause_contribution },
                });
              }}
              className="w-[20px] h-[20px]"
            >
              {pause_contribution ? (
                <div className="w-[17px] h-[16px] fill-[#DADADA]">
                  <PlayIcon />
                </div>
              ) : (
                <img src={PauseIcon} alt="edit" />
              )}
            </button>
            <Link
              to={`/projects/${others.id}/update`}
              className="w-[20px] h-[20px] flex items-center justify-center mx-2"
            >
              <img src={EditIcon} className="w-auto" alt="edit" />
            </Link>
            <button
              onClick={() => deleteProject({ projectId: others.id })}
              className="w-[20px] h-[20px]"
            >
              <img src={DeleteIcon} alt="edit" />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full ml-auto">
      <>
        <Helmet>
          <title>Rocket- Admin {"(Projects)"}</title>
        </Helmet>
        <div className="flex flex-col px-10">
          <Header title="Projects" subTitle="Subtitle" />
          <div className="py-8">
            <div className="flex flex-col relative">
              <div className="flex items-center">
                <Link
                  to="create"
                  className="bg-[#1DC894] text-white py-3 px-6 ml-auto mr-4 rounded font-semibold hover:scale-95 hover:opacity-[0.9]"
                >
                  Add project
                </Link>
              </div>

              <div className="w-full">
                {isLoading ? (
                  <div className=" w-full h-full overflow-y-auto py-[7rem] flex z-[100]">
                    <div className="m-auto">
                      <Spinner />
                    </div>
                  </div>
                ) : (
                  <Table
                    active="projects"
                    isNavigation={false}
                    tableColumns={tableColumns}
                    tableData={displayProjects()}
                    hasPagination={true}
                    setPageIndex={setPage}
                    queryPageIndex={page}
                    queryPageSize={pageSize}
                    isSuccess={isSuccess}
                    totalItems={
                      projects?.data.meta.totalItems
                        ? projects?.data.meta.totalItems
                        : 0
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

interface IProjectsTableData {
  id: string | number;
  contributors: number | string;
  status: string;
  poolSize: string | number;
  pause_contribution: boolean;
  others: IProject;
}

export default AdminView;
