import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import Layout from "./Layout";

const ProtectedRoutes = () => {
  const getAccessToken = () => {
    const [cookie] = useCookies(["token"]);
    if (!cookie?.token) return false;
    const isAccessToken = cookie?.token;
    return isAccessToken !== null;
  };

  return getAccessToken() ? (
    <Layout>
      <Outlet />{" "}
    </Layout>
  ) : (
    <Navigate to="/signIn" />
  );
};

export default ProtectedRoutes;
