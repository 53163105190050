import { Helmet } from "react-helmet";
import Header from "../../../components/header";
import ProjectCard from "../../../components/projects/projectCard";
import { GET_PROJECTS } from "../../../services/api/projects";
import { useQuery } from "react-query";
import Spinner from "components/loader-utils/Spinner";

const UserView = () => {
  const { data: projects, isLoading } = useQuery("getProjects", GET_PROJECTS, {
    staleTime: Infinity,
  });
  const getCompletedProjects = () => {
    return projects?.data.items
      ?.filter((project) => project?.status === "released")
      ?.map((project) => (
        <ProjectCard
          key={project?.id}
          project={{ ...project, status: "Completed" }}
        />
      ));
  };
  const getLiveProjects = () => {
    return projects?.data.items
      ?.filter((project) => project?.status === "live")
      ?.map((project) => (
        <ProjectCard
          key={project?.id}
          project={{ ...project, status: "Live" }}
        />
      ));
  };
  const getUpcomingProjects = () => {
    return projects?.data.items
      ?.filter((project) => project?.status === "upcoming")
      ?.map((project) => (
        <ProjectCard
          key={project?.name}
          project={{ ...project, status: "Upcoming" }}
        />
      ));
  };
  return (
    <div className="w-full ml-auto">
      <>
        <Helmet>
          <title>Rocket- Projects</title>
        </Helmet>
        <div className="flex flex-col px-10">
          <Header title="Projects" subTitle="Subtitle" />

          {isLoading ? (
            <div className=" w-full h-full overflow-y-auto py-[7rem] flex z-[100]">
              <div className="m-auto">
                <Spinner />
              </div>
            </div>
          ) : (
            <div className="py-8">
              <div className="pl-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-[1.5rem]">
                  {getLiveProjects()}
                </div>
                <div className="grid  grid-cols-1 sm:grid-cols-2 xl:grid-cols-3  gap-[1.5rem]">
                  {getUpcomingProjects()}
                </div>
                <div className="grid  grid-cols-1 sm:grid-cols-2 xl:grid-cols-3  gap-[1.5rem]">
                  {getCompletedProjects()}
                </div>{" "}
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default UserView;
