import SideMenu from "components/SideMenu";
import MobileHeader from "components/MobileHeader";
import Header from "components/header";
import Table from "components/Table";
import { tableColumns } from "./columns";
import { useQuery } from "react-query";
import { GET_ALL_MEMBERS } from "./../../services/api/members";
import { Helmet } from "react-helmet";
import { useState } from "react";
import Spinner from "components/loader-utils/Spinner";

const Members = () => {
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const { isLoading, data, isSuccess } = useQuery(
    ["members", { page: page + 1, pageSize }],
    GET_ALL_MEMBERS,
    {
      staleTime: Infinity,
    }
  );

  const displayMembers = () => {
    if (data?.data.users && Array.isArray(data?.data.users)) {
      return data?.data.users.map((member) => ({
        first_name: member?.first_name,
        last_name: member?.last_name,
        email: member?.email,
        phoneNumber: member?.phoneNumber,
        id: member?.id,
      }));
    }
    return [];
  };

  return (
    <div className="w-full ml-auto">
      <>
        <Helmet>
          <title>Rocket- Admin {"(Members List)"}</title>
        </Helmet>
        <div className="flex flex-col px-10">
          <Header title="Members" subTitle="List of members from Memberful" />
          {isLoading ? (
            <div className=" w-full h-full overflow-y-auto min-h-[100vh] items-center justify-center flex z-[100]">
              <div className="m-auto">
                <Spinner />
              </div>
            </div>
          ) : (
            <div className="py-8">
              <div className="flex flex-col relative">
                <div className="flex items-center">
                  <div className="w-full pt-10">
                    <Table
                      active="members"
                      isNavigation={true}
                      tableColumns={tableColumns}
                      tableData={displayMembers()}
                      hasPagination={true}
                      setPageIndex={setPage}
                      queryPageIndex={page}
                      queryPageSize={pageSize}
                      isSuccess={isSuccess}
                      totalItems={pageSize}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default Members;
