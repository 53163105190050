import { Helmet } from "react-helmet";
import Header from "components/header";
import ProjectsImage from "assets/rocket-img/img/building6.png";
import ContributionsImage from "assets/rocket-img/img/building7.png";
import ClaimImage from "assets/rocket-img/img/building8.png";
import { ReactComponent as BarChatIllustration } from "assets/rocket-img/img/barchat_illustration.svg";
import Pattern from "assets/rocket-img/img/pattern.png";
import ProjectCard from "components/projects/projectCard";
import { GET_PROJECTS } from "services/api/projects";
import { useQuery } from "react-query";
import Spinner from "components/loader-utils/Spinner";
import classNames from "classnames";

const UserView = () => {
  const { data, isLoading } = useQuery("getProjects", GET_PROJECTS);
  const getUpcomingProjects = () => {
    const projects = data?.data?.items;
    if (projects && Array.isArray(projects) && projects.length) {
      const upcoming = projects.filter(
        (project) => project?.status === "upcoming"
      );
      if (upcoming.length) {
        return upcoming?.map((project) => (
          <ProjectCard
            key={project?.name}
            project={{ ...project, status: "Upcoming" }}
          />
        ));
      }
      return "No Upcoming Projects";
    }
    return "";
  };
  return (
    <>
      <Helmet>
        <title>Rocket- Dashboard</title>
      </Helmet>

      <div className="w-full px-10 ">
        <Header title="Dashboard" subTitle="Subtitle" />
      </div>
      <div className="flex flex-col w-full px-10">
        <div>
          <div className="flex flex-col md:flex-row items-center pt-10 mb-10">
            <div className="flex flex-col w-full md:w-[65%] lg:w-[50%]   pr-4">
              <div className="grid  grid-cols-1 md:grid-cols-3 gap-[2rem] lg:flex lg:items-center">
                <div
                  className="rounded h-[100px] w-full md:w-[130px] relative"
                  style={{
                    backgroundImage: `url(${ProjectsImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="absolute w-full">
                    <h3 className=" text-dark ml-3 mt-3">Projects</h3>
                  </div>
                </div>
                <div
                  className="rounded h-[100px] w-full md:w-[130px] relative"
                  style={{
                    backgroundImage: `url(${ContributionsImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="absolute w-full md:w-full h-full">
                    <h3 className=" text-dark mt-3">
                      <span className="block">My</span>Contributions
                    </h3>
                  </div>
                </div>
                <div
                  className="rounded h-[100px] w-full md:w-[130px] relative"
                  style={{
                    backgroundImage: `url(${ClaimImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="absolute w-full h-full">
                    <h3 className=" text-dark ml-3 mt-3">Claim</h3>
                  </div>
                </div>
              </div>
              <div className="flex items-center mt-8">
                <div className="mr-4 rounded h-[100px] w-[130px] bg-[#3699FF] relative">
                  <div className="absolute w-full h-full ml-3 mt-3">
                    <p className=" text-white my-0">Total Contributed</p>
                    <p className=" text-white my-0">8,000 USDT</p>
                    <div className="w-[130px] h-[57px]">
                      <BarChatIllustration />
                    </div>
                  </div>
                </div>
                <div className="ml-4 rounded h-[100px] w-[130px] bg-[#3699FF] relative">
                  <div className="absolute w-full h-full ml-3 mt-3">
                    <p className=" text-white my-0">Total Contributed</p>
                    <p className=" text-white my-0">8,000 USDT</p>
                    <div className="w-[130px] h-[57px]">
                      <BarChatIllustration />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:ml-auto mr-auto md:mr-0 mt-10 md:mt-0 w-[70%] md:w-[35%] lg:w-[40%]  h-[230px] md:pl-4">
              <div
                className="rounded w-full h-full relative bg-contain"
                style={{
                  backgroundColor: "#1B283F",
                  backgroundImage: `url(${Pattern})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right bottom",
                }}
              >
                <div className="w-[150px] pt-4 pl-3">
                  <h3 className="text-white">Notifications</h3>
                  <p className="text-white text-sm">
                    Notification Area to provide announcements [FUTURE FEATURE]
                  </p>
                  <p style={{ color: "#EF6327" }}>Read More</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full pt-[4rem]">
            {isLoading ? (
              <div className=" w-full h-full min-h-[100vh] items-center justify-center flex z-[100]">
                <div className="m-auto">
                  <Spinner />
                </div>
              </div>
            ) : (
              <>
                <h3 className="text-dark font-bold text-base">
                  Upcoming Projects
                </h3>
                <div
                  className={classNames(
                    "mt-6 grid grid-cols-1 gap-[1.5rem] text-center",
                    {
                      "sm:grid-cols-2 md:grid-cols-3 text-left":
                        typeof getUpcomingProjects() !== "string",
                    }
                  )}
                >
                  {getUpcomingProjects()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserView;
