import MobileHeader from "components/MobileHeader";
import SideMenu from "components/SideMenu";
import { Helmet } from "react-helmet";
import Header from "components/header";
import { Panel, TabList } from "components/Tabs";

const User = (props: any) => {
  console.log(props);
  return (
    <>
      <div className="w-full ml-auto">
        <>
          <Helmet>
            <title>Rocket- User</title>
          </Helmet>
          <div className="flex flex-col px-10">
            <Header title="Overview" subTitle="Subtitle" name={""} />

            <div className="mt-10  pt-6 px-6 pb-10 rounded-xl w-full bg-white">
              <div className="flex flex-col sm:flex-row">
                <div className="w-[200px] h-[200px] shrink-0 rounded-xl mx-auto sm:mx-0 mb-4 sm:mb-0">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/media/avatars/300-1.jpg"
                    }
                    alt="avatar"
                    className="w-full rounded-xl"
                  />
                </div>

                <div className="sm:ml-8 w-full">
                  <div className="flex flex-wrap items-end justify-between">
                    <div>
                      <h2 className="font-semibold text-xl text-[#181C32]">
                        Max Smith
                      </h2>
                      <div className="flex text-[13px] text-[#B5B5C3] mt-2">
                        <div className="mr-4">Developer</div>
                        <div className="mr-4">SF, Bay Area</div>
                        <div>max@wt.com</div>
                      </div>
                    </div>
                    <div className="my-4 text-xs">
                      <button className="bg-[#F9F9F9] text-[#7E8299] rounded py-2 px-3 shadow-sm mr-4">
                        Follow
                      </button>
                      <button className="bg-[#009EF7] text-white rounded py-2 px-3 shadow-sm mr-4">
                        Hire me
                      </button>
                      <button className="bg-[#F9F9F9] text-[#7E8299] rounded py-2 px-3 shadow-sm">
                        ...
                        <span className="before:content-[\f5d4]"></span>
                      </button>
                    </div>
                  </div>

                  <div className="mt-4 flex flex-wrap justify-between">
                    <div className="flex flex-wrap">
                      <div className="w-[125px] p-3 border-2 border-dotted rounded mr-4">
                        <h2 className="font-semibold text-lg text-[#181C32]">
                          $4500
                        </h2>
                        <p className="text-[#B5B5C3] text-[13px] mt-2">
                          Earning
                        </p>
                      </div>

                      <div className="w-[125px] p-3 border-2 border-dotted rounded mr-4">
                        <h2 className="font-semibold text-lg text-[#181C32]">
                          50
                        </h2>
                        <p className="text-[#B5B5C3] text-[13px] mt-2">
                          Projects
                        </p>
                      </div>

                      <div className="w-[125px] p-3 border-2 border-dotted rounded mt-4 md:mt-0">
                        <h2 className="font-semibold text-lg text-[#181C32]">
                          60%
                        </h2>
                        <p className="text-[#B5B5C3] text-[13px] mt-2">
                          Success Rate
                        </p>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="flex justify-between text-[13px]">
                        <h4 className="text-[#B5B5C3]">Profile Completion</h4>
                        <h4 className="text-[#181C32] font-semibold">50%</h4>
                      </div>
                      <div className="progress w-[300px] h-[6px]">
                        <div
                          className="progress-bar bg-[#50CD89]"
                          role="progressbar"
                          style={{ width: "50%" }}
                          aria-valuenow={0}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col">
              <div className="border-t border-[#EFF2F5] border-solid flex w-[90%] mx-auto"></div>
              <TabList selectedBg="#fff" selectedColor="#00A3FF">
                <Panel title="Overview">
                  <div>r</div>
                </Panel>
                <Panel title="Projects">
                  <div>r</div>
                </Panel>
                <Panel title="Campaigns">
                  <div>r</div>
                </Panel>
                <Panel title="Documents">
                  <div>r</div>
                </Panel>
                <Panel title="Followers">
                  <div>r</div>
                </Panel>
                <Panel title="Activity">
                  <div>r</div>
                </Panel>
              </TabList>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default User;
