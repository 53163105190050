import { useState, useEffect } from "react";
import type { ChangeEvent } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Panel, TabList } from "components/Tabs";
import Header from "components/header";
import { useAppContext } from "contexts/AppContext";
import ProjectStats from "components/projects/ProjectStats";
import { useQuery, useMutation } from "react-query";
import { GET_PROJECT } from "services/api/projects";
import {
  CREATE_CONTRIBUTION,
  UPDATE_CONTRIBUTION_ID,
  GET_CLAIMABLE_CONTRIBUTIONS,
} from "services/api/contribution";
import {
  useContractEvent,
  useContract,
  useSigner,
  useAccount,
  useConnect,
  useSwitchNetwork,
} from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { getChainId } from "utils/getChainId";
import CONTRACT_ABI from "contract/abi.json";
import IERC20_ABI from "contract/ierc20.json";
import { formatToCurrencyString } from "utils/formatToCurrencyString";
import { formatDate } from "utils/formatDate";
import { IMetaMaskErr } from "interfaces/general";
import { IContributionSchedule } from "interfaces/projects";
import { BigNumber } from "ethers";
import type { Contract } from "ethers";
import Spinner from "components/loader-utils/Spinner";
import * as yup from "yup";
import { ValidationError } from "yup";
import { convertToFloat } from "utils/convertToFloat";
import SuccessModal from "components/SuccessModal";
import Claims from "components/projects/user/claims";
import { useSnackbar } from "notistack";
import Contributions from "components/projects/user/contributions";

interface IContributionParams {
  poolId: string;
  _scheduleId: string;
  amount: number;
  _token: string;
}

const UserView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { project } = useParams();
  const [searchParams] = useSearchParams();
  const isClaimsTab = searchParams.get("tab") === "claims";
  const { appState } = useAppContext();
  const { walletConnected, memberFulUser } = appState;
  const {
    data,
    isLoading,
    refetch: refetchProject,
  } = useQuery(["getProject", project!], GET_PROJECT, {
    retry: 30000000,
    staleTime: Infinity,
    enabled: Boolean(project),
  });
  const { mutateAsync: createContribution } = useMutation(
    "createContribution",
    CREATE_CONTRIBUTION
  );
  const { mutate: updateContributionID } = useMutation(
    "updateContributionId",
    UPDATE_CONTRIBUTION_ID
  );

  const projectDetails = data?.data;

  const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const USDC_CONTRACT_ADDRESS = process.env.REACT_APP_USDC_ADDRESS;

  const { switchNetwork } = useSwitchNetwork();
  const { connect } = useConnect({
    chainId: getChainId(projectDetails?.contribution_network!),
    connector: new InjectedConnector(),
    onSuccess: (data) => {
      if (data.chain.id !== getChainId(projectDetails?.contribution_network!)) {
        switchNetwork?.(getChainId(projectDetails?.contribution_network!));
      }
    },
    onError: (error)=> {
      // On error we want to add the network base on the project Details 
      // So we have to get all the support blockchain for rocket
      // and then select this project blockchain from it.
      if(error.message.includes("Chain")){
        console.log(error)
      }
    }
  });
  useEffect(() => {
    if (!walletConnected && projectDetails?.contribution_network) {
      connect();
    }
  }, [projectDetails?.contribution_network]);

  const { data: signer } = useSigner();
  const { address } = useAccount();

  const IERC20Contract = useContract({
    address: USDC_CONTRACT_ADDRESS,
    abi: IERC20_ABI,
    signerOrProvider: signer,
  });
  const rocketContract = useContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    signerOrProvider: signer,
  });

  useContractEvent({
    address: USDC_CONTRACT_ADDRESS,
    abi: IERC20_ABI,
    eventName: "Approval",
    listener(...args: any) {
      console.log("event args: ", args);
    },
    once: true,
  });

  const [selectedSchedule, setSelectedSchedule] =
    useState<IContributionSchedule | null>(null);

  useEffect(() => {
    if (
      projectDetails?.id &&
      projectDetails?.contribution_schedules &&
      projectDetails?.contribution_schedules.length > 0
    ) {
      const activeSubscription = projectDetails.contribution_schedules.find(
        (schedule) => {
          const today = new Date();
          const closeDate = new Date(schedule.close_datetime);
          return (
            schedule.subscription?.name ===
              memberFulUser?.customer?.subscription?.name && today < closeDate
          );
        }
      );
      if (activeSubscription) {
        setSelectedSchedule(activeSubscription);
      }
    }
  }, [projectDetails?.id, projectDetails?.contribution_schedules?.length]);

  const [isAuthorised, setIsAuthorised] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);

  const [fieldType, setFieldType] = useState("number");
  const [contributionAmount, setContributionAmount] = useState("");
  const [contributionAmountFormatted, setContributionAmountFormatted] =
    useState("");
  const minContribution = Number(selectedSchedule?.min_contribution) || 0;
  const maxContribution = Number(selectedSchedule?.max_contribution) || 0;
  const validationSchema = yup.object().shape({
    contributionAmount: yup
      .number()
      .min(minContribution, `Minimum contribution is ${minContribution}`)
      .max(maxContribution, `Maximum contribution is ${maxContribution}`)
      .required(),
  });
  const [fieldError, setFieldError] = useState<ValidationError | null>(null);
  const [contributionLoading, setContributionLoading] = useState(false);
  const [contributionSuccess, setContributionSuccess] = useState(false);

  useEffect(() => {
    const checkIfAuthorized = async (contract: Contract | null) => {
      if (address && CONTRACT_ADDRESS && contract && walletConnected) {
        try {
          setApprovalLoading(true);
          const res: BigNumber = await contract?.allowance(
            address,
            CONTRACT_ADDRESS
          );

          if (parseFloat(res.toString())) {
            setIsAuthorised(true);
          }
          setApprovalLoading(false);
        } catch (error) {
          setApprovalLoading(false);
          if ((error as IMetaMaskErr)?.code) {
            enqueueSnackbar({
              variant: "error",
              message: (error as IMetaMaskErr).error.data.message,
              autoHideDuration: 5000,
            });
          }
        }
      }
    };

    checkIfAuthorized(IERC20Contract);
  }, [address, CONTRACT_ADDRESS, IERC20Contract, walletConnected]);

  const approveSmartContract = async () => {
    try {
      const isValid = await validationSchema.validate({
        contributionAmount: Number(contributionAmount),
      });
      if (isValid) {
        setFieldError(null);
        setApprovalLoading(true);
        const approvalAmountFloat = convertToFloat(contributionAmount);
        const approve = await IERC20Contract?.approve(
          CONTRACT_ADDRESS,
          approvalAmountFloat
        );
        await approve.wait(2);
        setIsAuthorised(true);
        setApprovalLoading(false);
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        setFieldError(error);
      } else {
        setApprovalLoading(false);
        if ((error as IMetaMaskErr)?.code) {
          enqueueSnackbar({
            variant: "error",
            message: (error as IMetaMaskErr).error.data.message,
            autoHideDuration: 5000,
          });
        }
      }
    }
  };

  const handleContribute = async () => {
    try {
      const isValid = await validationSchema.validate({
        contributionAmount: Number(contributionAmount),
      });
      if (
        isValid &&
        isAuthorised &&
        contributionAmount &&
        selectedSchedule?.contribution_schedule_id &&
        projectDetails &&
        USDC_CONTRACT_ADDRESS
      ) {
        setFieldError(null);
        setContributionLoading(true);

        const contributionAmountFloat = convertToFloat(contributionAmount);
        const { data } = await createContribution({
          project_id: projectDetails?.id,
          amount: contributionAmountFloat,
          token: USDC_CONTRACT_ADDRESS,
          swap_rate: projectDetails?.swap_rate,
          contribution_fee: selectedSchedule?.contribution_fee,
          contribution_network: projectDetails?.contribution_network,
          contribution_schedule_id: selectedSchedule?.id,
        });

        const transaction = await rocketContract?.contribute(
          projectDetails?.pool_id,
          selectedSchedule?.contribution_schedule_id,
          contributionAmountFloat,
          USDC_CONTRACT_ADDRESS
        );
        const res = await transaction.wait(2);
        const contributionIDBigNumber: BigNumber =
          res.events[0].args?.contributionId;
        const contributionID = contributionIDBigNumber.toNumber();
        const transactionHash = res.transactionHash;
        if (contributionID && transactionHash) {
          updateContributionID(
            {
              id: data.id,
              contributionDetails: {
                tx_hash: transactionHash,
                blockchainContributionId: contributionID,
              },
            },
            {
              onSuccess: () => {
                setContributionSuccess(true);
              },
            }
          );
        }
        setContributionLoading(false);
        refetchProject();
      }
      if (!selectedSchedule) {
        enqueueSnackbar({
          variant: "error",
          message: "No Active Schedule",
          autoHideDuration: 5000,
        });
      }
    } catch (error) {
      setContributionLoading(false);
      console.log(error);
      if (error instanceof ValidationError) {
        setFieldError(error);
      }
      if ((error as IMetaMaskErr)?.code) {
        enqueueSnackbar({
          variant: "error",
          message: (error as IMetaMaskErr).error.data.message,
          autoHideDuration: 5000,
        });
      }
    }
  };

  return (
    <>
      <div className="w-full ml-auto">
        <div className="flex flex-col font-roboto px-10">
          <Header
            title="Project"
            subTitle="Subtitle"
            name={projectDetails?.name}
          />
          <>
            {isLoading ? (
              <div className=" w-full h-full overflow-y-auto py-[7rem] flex z-[100]">
                <div className="m-auto">
                  <Spinner />
                </div>
              </div>
            ) : (
              <>
                <ProjectStats projectDetails={projectDetails || null}>
                  <>
                    <div className="flex ml-auto w-fit">
                      <div className="border border-gray-300 border-dashed rounded min-w-[125px] mr-4 px-4 py-2">
                        <div className="flex flex-col">
                          <p className="text-[#5E6278] text-base font-bold">
                            {formatDate(projectDetails?.open_time || "0")}
                          </p>

                          <p className="text-sm text-gray-400 mt-1">
                            Pool Opens
                          </p>
                        </div>
                      </div>
                      <div className="border border-gray-300 border-dashed rounded min-w-[125px] px-4 py-2">
                        <div className="flex flex-col">
                          <p className="text-[#5E6278] text-base font-bold">
                            $
                            {formatToCurrencyString(
                              parseFloat(projectDetails?.pool_size || "0")
                            )}
                          </p>

                          <p className="text-sm text-gray-400 mt-1">Pool</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full my-4">
                      <div className="w-[40%]">
                        <input
                          type={fieldType}
                          name="amount"
                          placeholder=" Amount"
                          value={contributionAmountFormatted}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setContributionAmount(e.target.value);
                            setContributionAmountFormatted(e.target.value);
                          }}
                          onFocus={() => {
                            if (contributionAmount) {
                              setFieldType("number");
                              setContributionAmountFormatted(
                                contributionAmount
                              );
                            }
                          }}
                          onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value === "") return;
                            setFieldType("text");
                            const value = String(contributionAmount).replace(
                              /,/g,
                              ""
                            );
                            setContributionAmountFormatted(
                              `${parseFloat(value).toLocaleString("en-US", {
                                style: "decimal",
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}`
                            );
                          }}
                          className="bg-[#F5F8FA] py-2 pl-2 w-full rounded-sm text-[#5E6278] text-sm"
                        />
                        {fieldError?.message ? (
                          <div className="text-red-500 text-sm">
                            {fieldError.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="flex mr-auto ml-6">
                        <button
                          disabled={!contributionAmount}
                          onClick={approveSmartContract}
                          className={`bg-[#F5F8FA] py-2 px-4 mr-4 rounded-sm text-sm font-semibold ${
                            isAuthorised ? "hidden" : ""
                          }`}
                        >
                          Authorise
                        </button>
                        <button
                          disabled={!isAuthorised}
                          onClick={handleContribute}
                          className={`text-white py-2 px-6 rounded-sm bg-[#00A3FF] font-semibold text-sm ${
                            !isAuthorised ? "hidden" : ""
                          }`}
                        >
                          Contribute
                        </button>
                      </div>
                    </div>
                    <button
                      className={`bg-[#F9F9F9] text-[#7E8299] shadow-sm rounded-sm px-3 py-2  mb-4 text-[13px] ${
                        !isAuthorised ? "hidden" : ""
                      }`}
                      onClick={() => setIsAuthorised(false)}
                    >
                      Update Allowance Amount
                    </button>
                  </>
                </ProjectStats>
                <div className="w-full pb-[5rem]">
                  <TabList
                    selectedPanel={isClaimsTab ? 1 : 0}
                    selectedBg="#fff"
                    selectedColor="#00A3FF"
                  >
                    <Panel title="Details">
                      <div className="w-full">
                        <p>{projectDetails?.description || ""}</p>
                      </div>
                    </Panel>
                    <Panel title="Contributions">
                      <Contributions projectDetails={projectDetails} />
                    </Panel>
                    <Panel title="Claims">
                      <Claims
                        projectId={project}
                        projectPoolId={`${projectDetails?.pool_id}`}
                        contributions={projectDetails?.contributions?.filter(
                          (contribution) =>
                            contribution?.customer_id ===
                            memberFulUser?.customer?.id
                        )}
                      />
                    </Panel>
                  </TabList>
                </div>
                {contributionLoading || approvalLoading ? (
                  <div className=" w-full h-full fixed top-0 left-0 bg-black/30 overflow-y-auto py-[7rem] flex z-[100]">
                    <div className="m-auto">
                      <Spinner />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        </div>
      </div>
      {contributionSuccess ? (
        <SuccessModal
          message="Contribution Successful"
          toggleClose={() => {
            refetchProject();
            setContributionAmount("");
            setContributionAmountFormatted("");
            setContributionSuccess(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default UserView;
