import { ReactNode, useEffect } from "react";
import MobileHeader from "components/MobileHeader";
import SideMenu from "components/SideMenu";
import { useLocation } from "react-router-dom";
import { useAppContext } from "contexts/AppContext";
import { AxiosError } from "axios";
import { rocketAPI } from "services/api/base";
import { useCookies } from "react-cookie";

const Layout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const cookieUtils = useCookies(["token"]);
  const { appState, updateState } = useAppContext();
  const { isMobile, redirect_to } = appState;
  const toggleIsMobile = () => {
    if (!updateState) return;
    updateState({
      isMobile: !isMobile,
    });
  };
  const active = location.pathname.includes("/projects")
    ? "projects"
    : location.pathname === "/"
    ? "dashboard"
    : location.pathname.includes("/members")
    ? "members"
    : location.pathname === "/contributions"
    ? "contributions"
    : location.pathname.includes("/claims")
    ? "claims"
    : "user";

  // interceptor
  rocketAPI.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error: AxiosError) {
      if (error.response?.status === 401) {
        // log user out on 401
        cookieUtils[2]("token");
        localStorage.clear();
        const origin = window.location.origin;
        const redirect_to = location.pathname;
        if (updateState) {
          updateState({ redirect_to: redirect_to });
        }
        window.location.assign(`${origin}/signIn?redirect_to=${redirect_to}`);
      }
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    //  if redirect link exist clear it
    if (redirect_to && updateState) {
      updateState({ redirect_to: null });
    }
  }, [redirect_to]);

  return (
    <>
      <div className="w-full flex items-stretch bg-[#D5D9E2]/10 min-h-[100vh]">
        <SideMenu activeLink={active} />
        <div className="w-full lg:w-[80%] ml-auto">
          <div className="relative self-stretch lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] mx-auto">
            <MobileHeader />

            {children}
          </div>
        </div>
      </div>
      {isMobile ? (
        <div
          aria-hidden={true}
          onClick={toggleIsMobile}
          className="drawer-overlay"
          style={{ zIndex: "109" }}
        ></div>
      ) : (
        ""
      )}
    </>
  );
};

export default Layout;
