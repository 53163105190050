import { useState } from "react";
import { ReactComponent as SearchIcon } from "assets/rocket-img/icons/search_icon_black.svg";
import { ReactComponent as Grid1 } from "assets/rocket-img/icons/grid_1.svg";
import { ReactComponent as Grid2 } from "assets/rocket-img/icons/grid_2.svg";
import { ReactComponent as ChevronDown } from "assets/rocket-img/icons/chevron_down.svg";
import {
  GET_DISTRIBUTION_SCHEDULES,
  UPDATE_DISTRIBUTION_SCHEDULE,
} from "services/api/projects";
import { useMutation, useQuery } from "react-query";
import Table from "../../../Table";
import { Column } from "react-table";
import CreateDistributionSchedule from "./create";
import SuccessModal from "../../../SuccessModal";
import Spinner from "components/loader-utils/Spinner";
import { LoaderButton } from "components/loader-utils/LoaderButton";
import { DistributionSchedule, IProject } from "interfaces/projects";
import { formatDate } from "utils/formatDate";

const DistributionSchedules = ({
  details,
  refetch,
}: {
  details: IProject;
  refetch: any;
}) => {
  const [isNewSchedule, setIsNewSchedule] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [page, setPage] = useState(0);
  const pageSize = 10;

  const updateSchedule = useMutation(
    "updateSchedule",
    UPDATE_DISTRIBUTION_SCHEDULE,
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const tableColumns: readonly Column<DistributionScheduleTable>[] = [
    {
      Header: "batch id",
      accessor: "batchId",
      Cell: (Row) => {
        const { value } = Row;
        return <span className="text-[#3F4254]">Period {value}</span>;
      },
    },
    {
      Header: "claim Percentage (%)",
      accessor: "claimPercentage",
      Cell: (Row) => {
        const { value } = Row;
        return <span className="text-[#3F4254]">{value}</span>;
      },
    },
    {
      Header: "start date",
      accessor: "startDate",
      Cell: (Row) => {
        const { value } = Row;
        return <span className="text-[#3F4254]">{value}</span>;
      },
    },
    {
      Header: "close date",
      accessor: "closeDate",
      Cell: (Row) => {
        const { value } = Row;
        return <span className="text-[#3F4254]">{value}</span>;
      },
    },
    {
      Header: "status",
      accessor: "status",
      Cell: (Row) => {
        const { value } = Row;
        return <span className="text-[#3F4254]">{value}</span>;
      },
    },
    {
      Header: "action",
      accessor: "others",
      Cell: (Row) => {
        const { value } = Row;
        return (
          <LoaderButton
            loading={updateSchedule.isLoading}
            onClick={() => {
              updateSchedule.mutate({
                scheduleDetails: {
                  ...value,
                  status: value.status === "active" ? "inactive" : "active",
                },
              });
            }}
            className="p-3 text-white bg-[#DADADA] hover:bg-[#1BC5BD] rounded-lg font-medium whitespace-nowrap btn-style"
          >
            Update Status
          </LoaderButton>
        );
      },
    },
  ];

  const distributionSchedules = details.distributions;
  const displayDistributionSchedules = () => {
    if (distributionSchedules) {
      return distributionSchedules.map((schedule) => ({
        batchId: schedule?.batchId,
        claimPercentage: schedule?.claimPercentage,
        startDate: formatDate(schedule?.startDate),
        closeDate: formatDate(schedule?.closeDate),
        status: schedule.status,
        id: schedule.id,
        others: schedule,
      }));
    }
    return [];
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <button
          onClick={() => setIsNewSchedule(true)}
          className="bg-[#1DC894] text-white py-3 px-6 ml-auto mr-4 rounded font-semibold hover:scale-95 hover:opacity-[0.9]"
        >
          Add New
        </button>
        <div className="flex items-center py-2 mt-8 border-b border-[#eee] border-solid shadow-sm">
          <h2 className="text-xl text-dark font-semibold">
            Distribution Schedules ({distributionSchedules?.length || 0})
          </h2>
          <div className="relative ml-4">
            <input
              type="search"
              className="bg-white outline-0 py-2 pl-8"
              placeholder="Search"
              name="search_distribution_schedule"
            />
            <span className="w-[15px] h-[15px] absolute top-[50%] translate-y-[-50%] left-[5px]">
              <SearchIcon />
            </span>
          </div>
          <div className="ml-auto flex items-center">
            <div className="w-[15px] h-[15px] mr-4">
              <Grid1 />
            </div>
            <div className="w-[15px] h-[15px] mr-4">
              <Grid2 />
            </div>
            <div className="mr-4">
              Filters
              <span className="ml-4">2</span>
            </div>
            <button className="flex items-center">
              Export
              <span className="w-[10x] ml-6">
                <ChevronDown />
              </span>
            </button>
          </div>
        </div>
        {/* {scheduleIsLoading ? (
          <div className=" w-full h-full overflow-y-auto py-[7rem] flex z-[100]">
            <div className="m-auto">
              <Spinner />
            </div>
          </div>
        ) : ( */}
        <Table
          isNavigation={false}
          active="distribution schedules"
          tableColumns={tableColumns}
          tableData={displayDistributionSchedules()}
          hasPagination={true}
          setPageIndex={setPage}
          queryPageIndex={page}
          queryPageSize={pageSize}
          isSuccess={true}
          totalItems={10}
        />
        {/* )} */}
      </div>
      {isNewSchedule ? (
        <CreateDistributionSchedule
          refetchSchedules={refetch}
          setIsNewSchedule={setIsNewSchedule}
          setShowSuccessModal={setShowSuccessModal}
          details={details}
        />
      ) : (
        ""
      )}
      {showSuccessModal ? (
        <SuccessModal
          message="Schedule has been created"
          toggleClose={() => setShowSuccessModal(false)}
        />
      ) : (
        ""
      )}
    </>
  );
};

interface DistributionScheduleTable {
  batchId: number;
  startDate: string;
  closeDate: string;
  claimPercentage: string;
  status: string;
  id: string;
  others: DistributionSchedule;
}

export default DistributionSchedules;
