import CloseIcon from "../assets/rocket-img/icons/close_icon.png";

interface IProps {
  message: any;
  toggleClose: any;
  SuccessIcon: any;
}

const ModalBox = ({ message, toggleClose, SuccessIcon }: IProps) => {
  return (
    <div className="bg-black/50 w-full min-w-[100vw] py-[5rem] h-full min-h-[100vh] overflow-y-auto fixed top-0 left-0 flex z-[990]">
      <div
        className={`w-[90%] lg:w-[40%] max-w-[400px] h-fit  relative z-[150] mx-auto bg-white rounded-xl`}
      >
        <div className="px-8 py-6">
          <div className="flex w-full">
            <button
              type="button"
              onClick={toggleClose}
              className="w-[30px] h-[30px] ml-auto opacity-[0.5]"
            >
              <img src={CloseIcon} alt="close" />
            </button>
          </div>
          <div className="rounded-full bg-[#4CAF50]/40 w-[50px] h-[50px] p-3 flex mx-auto mb-10 mt-4">
            <img src={SuccessIcon} className="w-full h-full" alt="success" />
          </div>
          <p className="text-center">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default ModalBox;
