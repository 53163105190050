import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { IAppState, useAppContext } from "contexts/AppContext";
import { useMutation, useQuery } from "react-query";
import { ADMIN_SIGN_IN } from "services/api/userActions";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import * as yup from "yup";
import { GET_MEMBERFUL, VERIFY_TOKEN, GET_USER } from "services/api/memberful";
import SignInBG from "assets/rocket-img/img/signIn.png";
import { ReactComponent as LogoWhite } from "assets/rocket-img/img/logo_white.svg";
import MemberFulLogo from "assets/rocket-img/img/memberful.png";
import { LoaderButton } from "components/loader-utils/LoaderButton";
import { ReactComponent as EyeIcon } from "assets/rocket-img/icons/eye_icon.svg";
import { ReactComponent as EyeSlashIcon } from "assets/rocket-img/icons/eye-slash-solid.svg";
import { IRouteError, IUser } from "interfaces/general";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";

const INITIAL_VALUES = {
  email: "",
  password: "",
};
const SIGNIN_SCHEMA = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter your email address"),
  password: yup.string().min(8).required("Please enter your password"),
});

const SignIn = () => {
  const { enqueueSnackbar } = useSnackbar();
  const cookieUtils = useCookies(["token"]);
  const [showPassword, setShowPassword] = useState(false);
  const [adminLoading, setAdminLoading] = useState(false);
  const [memberfulLoading, setMemberfulLoading] = useState(false);
  const { updateState } = useAppContext();
  const appStateLocal = localStorage.getItem("rocketSTATE");
  const appState = appStateLocal
    ? (JSON.parse(appStateLocal) as IAppState)
    : null;
  const redirect_to = appState?.redirect_to;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // QUERIES
  const memberful = useQuery("memberful", GET_MEMBERFUL, {
    enabled: false,
  });
  const adminLogin = useMutation("adminLogin", ADMIN_SIGN_IN, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response?.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });

  const user = useMutation("getUser", GET_USER, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response?.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });

  // Verify Code and state
  const verify = useMutation("verifyToken", VERIFY_TOKEN, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response?.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });

  // QUERIES
  const handleMemberfulAuth = async () => {
    const res = await memberful.refetch();
    return window.open(res.data?.data.link, "_self");
  };

  useEffect(() => {
    (async () => {
      try {
        if (
          searchParams.get("code") !== null &&
          searchParams.get("state") !== null
        ) {
          setMemberfulLoading(true);
          const res = await verify.mutateAsync({
            code: searchParams.get("code"),
            state: searchParams.get("state"),
          });
          if (res?.data?.access_token && updateState) {
            const userDetails = await user.mutateAsync({
              token: res?.data?.access_token,
            });
            setMemberfulLoading(false);
            cookieUtils[1]("token", res?.data?.access_token, {
              path: "/",
              maxAge: 3600,
              secure:
                process.env.NODE_ENV && process.env.NODE_ENV === "production"
                  ? true
                  : false,
              sameSite: "strict",
            });
            updateState({
              memberFulUser: userDetails?.data,
              isAdmin: false,
            });
            if (redirect_to) {
              navigate(redirect_to);
            } else {
              navigate("/");
            }
          }
        }
      } catch (error) {
        setMemberfulLoading(false);
      }
    })();
  }, []);
  useEffect(() => {
    // show the error notification when user has been logged out
    if (
      redirect_to &&
      !searchParams.get("code") &&
      !searchParams.get("state")
    ) {
      enqueueSnackbar({
        variant: "error",
        message: "Session has expired",
        autoHideDuration: 5000,
      });
    }
  }, []);
  const userSignIn = async (values: { email: string; password: string }) => {
    const { email, password } = values;
    try {
      setAdminLoading(true);
      const res = await adminLogin.mutateAsync({ email, password });
      const data: IUser = res?.data;
      const token = data?.token;
      if (token && updateState) {
        const userDetails = await user.mutateAsync({ token });
        setAdminLoading(false);
        cookieUtils[1]("token", token, {
          path: "/",
          maxAge: 86400,
          secure:
            process.env.NODE_ENV && process.env.NODE_ENV === "production"
              ? true
              : false,
          sameSite: "strict",
        });
        updateState({
          user: userDetails?.data,
          role: userDetails?.data?.role,
          isAdmin: true,
        });
        if (redirect_to) {
          navigate(redirect_to);
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      setAdminLoading(false);
      return;
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row w-full min-h-[100vh] items-stretch">
        <div className="w-full md:w-[35%] h-[200px] md:h-auto relative">
          <div className="w-full h-full  relative">
            <div className="bg-[#000]/50 absolute top-0 w-full h-full"></div>
            <img
              src={SignInBG}
              className="w-full h-full  object-cover md:object-none 2xl:object-cover object-top md:object-center"
              alt="rocket"
            />
          </div>
          <div className="absolute top-[30%] translate-y-[-30%] left-[50%] translate-x-[-50%] w-[200px] h-[100px]">
            <LogoWhite />
          </div>
        </div>
        <div className="w-full md:w-[75%] self-stretch bg-[#F3F5F9] flex flex-col  pt-[7rem] pb-[4rem]">
          <div className="mx-auto 3xl:my-auto px-8 md:px-0 max-w-[400px] 3xl:max-w-none">
            <h1 className="text-dark text-4xl font-semibold mb-3">
              Hello there! Welcome Back
            </h1>
            <p className="mb-10">
              Use the options below to login to your accounts
            </p>

            <LoaderButton
              loading={memberful.isLoading || verify.isLoading}
              type="button"
              className="flex items-center rounded-md py-3 px-4 w-full bg-[#ef5028] text-white mb-6 hover:scale-95 hover:opacity-[0.9]"
              onClick={handleMemberfulAuth}
            >
              <img
                src={MemberFulLogo}
                alt="memberful"
                style={{ width: "10%", marginLeft: "16px" }}
              />
              <big className="mx-auto">Login With Memberful</big>
            </LoaderButton>

            <h1 className="text-dark mb-10 text-3xl font-semibold">Sign In</h1>
            <div>
              <Formik
                initialValues={INITIAL_VALUES}
                validationSchema={SIGNIN_SCHEMA}
                onSubmit={userSignIn}
              >
                {(props: FormikProps<any>) => (
                  <Form>
                    <div className="w-full mb-8">
                      <label htmlFor="email" className="mb-2 font-semibold">
                        Your Email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        className="bg-white outline-white w-full py-4 pl-4 "
                      />
                      <div className="text-red-500 mt-2">
                        <ErrorMessage name="email" />
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="flex justify-between mb-2">
                        <label htmlFor="password" className="font-semibold">
                          Your Password
                        </label>
                        <a href="." className="text-[#3699FF]">
                          Forgot password {"?"}
                        </a>
                      </div>
                      <div className="relative w-full">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          className="bg-white outline-white w-full py-4 pl-4 "
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="w-[20px] h-[20px] absolute right-0 top-[50%] translate-y-[-50%] opacity-70"
                        >
                          {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                        </button>
                      </div>
                      <div className="text-red-500 mt-2">
                        {/* { 
                     getErrorMessage(props.setFieldError)
                      } */}
                        <ErrorMessage name="password" />
                      </div>
                    </div>
                    <LoaderButton
                      loading={adminLogin.isLoading || adminLoading}
                      type="submit"
                      className="bg-[#3699FF] text-white rounded py-4 px-2 w-[135px] mt-10 font-semibold"
                    >
                      Sign In
                    </LoaderButton>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
