import Dashboard from "./MenuItems/Dashboard";
import Projects from "./MenuItems/Projects";
import Contribution from "./MenuItems/Contributions";
import Members from "./MenuItems/Members";
import Claim from "./MenuItems/Claim";
import { useAppContext } from "../../contexts/AppContext";
import { ReactComponent as Logo } from "../../assets/rocket-img/img/logo.svg";
import { ReactComponent as LogOutIcon } from "../../assets/rocket-img/icons/log-out-icon.svg";
import { useNavigate } from "react-router-dom";
import { LOGOUT_MEMBERFUL } from "../../services/api/memberful";
import { useMutation } from "react-query";
import UserProfile from "./MenuItems/UserProfile";
import { useCookies } from "react-cookie";

const SideMenu = ({
  activeLink,
}: {
  activeLink:
    | "members"
    | "projects"
    | "dashboard"
    | "contributions"
    | "claims"
    | "user";
}) => {
  const navigate = useNavigate();
  const cookieUtils = useCookies(["token"]);
  const { appState } = useAppContext();
  const { isMobile } = appState;
  const isAdmin = appState.isAdmin;
  const nickName = appState?.user?.nickname;
  const name = appState?.memberFulUser?.memberFulData.fullName;
  const role = appState?.role?.name;
  const { mutate: logoutMemberful } = useMutation(
    "logoutMemberful",
    LOGOUT_MEMBERFUL,
    {
      onSuccess: () => {
        localStorage.clear();
        navigate("/signIn");
      },
    }
  );

  const handleLogout = () => {
    cookieUtils[2]("token");
    localStorage.clear();
    navigate("/signIn");
  };
  return (
    <>
      <div
        className={`fixed  w-[80%] sm:w-[40%] lg:w-[20%] z-[200] h-[100vh] overflow-y-auto lg:overflow-hidden flex flex-col items-center bg-[#EFE9E1] min-h-[100vh] pt-[5rem] ${
          isMobile ? "translate-x-0" : "translate-x-[-100vw]"
        } transition-[transform] duration-500 lg:translate-x-0`}
      >
        <div className="w-[122px] h-[65px]">
          <Logo />
        </div>
        <div className="w-[70px] h-[70px] rounded mt-8">
          <img
            src={process.env.PUBLIC_URL + "/assets/media/avatars/300-1.jpg"}
            alt="avatar"
            className="w-full rounded"
          />
        </div>
        <div className="text-center mt-2">
          <a href="#" className="text-gray-900 capitalize font-bold">
            {isAdmin ? nickName : name}
          </a>
          <span className="text-gray-600 font-bold block mb-1 capitalize">
            {isAdmin ? role : `Welcome Back!!!`}
          </span>
        </div>
        <div className="py-[5rem] self-start px-8">
          <Dashboard activeLink={activeLink} />
          <UserProfile activeLink={activeLink} />
          <Projects activeLink={activeLink} />

          {isAdmin ? (
            <>
              <Members activeLink={activeLink} />
              {/* <Statistics activeLink={activeLink} /> */}
              {/* <Tools activeLink={activeLink} /> */}
            </>
          ) : (
            <>
              <Contribution activeLink={activeLink} />
              <Claim activeLink={activeLink} />
            </>
          )}
        </div>
        <div className="flex justify-between mt-auto self-start items-center px-8 mb-6 w-full">
          <button onClick={handleLogout} className="flex items-center">
            <div className="w-[24px] h-[24px] mr-2">
              <LogOutIcon />
            </div>
            <span className="text-[#7E8299] font-semibold">Log Out</span>
          </button>
          <div
            className="btn btn-sm btn-icon btn-icon-gray-600 btn-active-color-primary position-relative me-n1"
            data-kt-menu-trigger="click"
            data-kt-menu-overflow="true"
            data-kt-menu-placement="top-start"
          >
            <span className="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z"
                  fill="black"
                />
                <path
                  d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
