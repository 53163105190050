import { formatToCurrencyString } from "utils/formatToCurrencyString";
import type { Column } from "react-table";
import { ReactComponent as UserIcon } from "assets/rocket-img/icons/user-solid.svg";

interface ITableData {
  user: {
    name: string;
    email: string;
  };
  date: string;
  amount: number;
}

export const tableColumn: readonly Column<ITableData>[] = [
  {
    Header: "user",
    accessor: "user",
    Cell: (Row) => {
      const { value } = Row;
      return (
        <div className="text-[#3F4254] flex items-center">
          <div className="w-[30px] min-w-[30px] h-[30px] mr-2">
            <UserIcon className="w-full h-full fill-[#00A3FF]" />
          </div>
          <div>
            <h4>{value.name}</h4>
            <h4 className="text-[13px] text-muted">{value.email}</h4>
          </div>
        </div>
      );
    },
  },
  {
    Header: "amount",
    accessor: "amount",
    Cell: (Row) => {
      const { value } = Row;
      return (
        <div className="text-[#3F4254]">
          <h4>${formatToCurrencyString(value)}</h4>
        </div>
      );
    },
  },
  {
    Header: "date",
    accessor: "date",
    Cell: (Row) => {
      const { value } = Row;
      return (
        <div className="text-[#3F4254]">
          <h4>{value}</h4>
        </div>
      );
    },
  },
];
