import axios from "axios";
import { PaginatedResponse, rocketAPI } from "./base";
import { MemberInterface } from "interfaces/member";

const memberfulRoutes = {
  memberful: "/auth",
  token: "/auth/token",
  user: "/auth/user",
};

interface IMemberfulLink {
  link: string;
}

export const GET_MEMBERFUL = () => {
  return rocketAPI.get<IMemberfulLink>(memberfulRoutes.memberful);
};

export const VERIFY_TOKEN = (requestPayload: {
  code: string | null;
  state: string | null;
}) => {
  return rocketAPI.post(memberfulRoutes.token, requestPayload);
};

export const GET_USER = async (data: { token: string }) => {
  return rocketAPI.post(
    memberfulRoutes.user,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.token}`,
      },
    }
  );
};

export const LOGOUT_MEMBERFUL = () => {
  return axios.post("https://kryptoplayboy.memberful.com/auth/sign_out");
};
