import { useEffect, useRef } from "react";
import { useAppContext } from "contexts/AppContext";

const key = "rocketSTATE";
export const useHydrateState = () => {
  const hyrated = useRef(false);
  const { appState, updateState } = useAppContext();
  useEffect(() => {
    if (hyrated.current) {
      localStorage.setItem(key, JSON.stringify(appState));
    } else {
      const stateJson = localStorage.getItem(key);
      hyrated.current = true;
      if (!stateJson) return;
      if (!updateState) return;
      const state = JSON.parse(stateJson);
      updateState({ ready: true, ...state });
    }
  }, [appState!]);
};
