export const formatDate = (date: string | undefined) => {
  if (date) {
    return new Date(date).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
  return "";
};
