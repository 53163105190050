import { useState } from "react";
import { Panel, TabList } from "components/Tabs";
import Schedule from "components/projects/admin/contributionSchedules";
import { useQuery } from "react-query";
import { GET_PROJECT } from "services/api/projects";
import Header from "components/header";
import ProjectStats from "components/projects/ProjectStats";
import { useParams } from "react-router-dom";
import UpdateProcessModal from "components/projects/ProcessModal";
import Contributions from "components/projects/admin/contributions";
import { formatToCurrencyString } from "utils/formatToCurrencyString";
import DistributionSchedules from "components/projects/admin/distributionSchedules";
import { Link } from "react-router-dom";
import Spinner from "components/loader-utils/Spinner";

const AdminView = () => {
  const [showModal, setShowModal] = useState(false);
  const { project } = useParams();
  const { data, refetch, isLoading } = useQuery(
    ["getProject", project!],
    GET_PROJECT,
    {
      retry: 30000000,
      staleTime: Infinity,
      enabled: Boolean(project),
    }
  );

  const projectDetails = data?.data;

  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    });
  };
  const handleChangeStatus = () => {
    if (!setShowModal) return;
    setShowModal(true);
  };

  return (
    <>
      <div className="w-full ml-auto">
        <div className="flex flex-col font-roboto px-10">
          <Header
            title="Projects"
            subTitle="Subtitle"
            name={projectDetails?.name}
          />
          <></>
          {!isLoading ? (
            <>
              {location.pathname.includes("update") ? (
                ""
              ) : (
                <ProjectStats projectDetails={projectDetails || null}>
                  <div className="flex flex-col lg:flex-row justify-between">
                    <div className="grid grid-cols-3 gap-4 mb-4">
                      <div className="border border-gray-300 border-dashed rounded min-w-[125px] p-4">
                        <div className="flex flex-col">
                          <p className="text-[#5E6278] text-base font-bold">
                            {formatDate(projectDetails?.close_time || "0")}
                          </p>

                          <p className="text-[13px] text-gray-400 mt-2">
                            Due Date
                          </p>
                        </div>
                      </div>

                      <div className="border border-gray-300 border-dashed rounded min-w-[125px] p-4">
                        <div className="flex flex-col">
                          <p className="text-[#5E6278] text-base font-bold">
                            {formatToCurrencyString(
                              parseFloat(projectDetails?.pool_size || "0")
                            )}
                          </p>

                          <p className="text-[13px] text-gray-400 mt-2">pool</p>
                        </div>
                      </div>

                      <div className="border border-gray-300 border-dashed rounded min-w-[125px] p-4">
                        <div className="flex flex-col">
                          <p className="text-[#5E6278] text-base font-bold">
                            {projectDetails?.contributions?.length}
                          </p>

                          <p className="text-[13px] text-gray-400 mt-2">
                            Contributors
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="my-6 lg:my-2 flex flex-col xl:flex-row items-start">
                      <Link
                        to={`/projects/${projectDetails?.id}/update`}
                        className="bg-[#F5F8FA] text-[#7E8299] text-[13px] rounded py-2 px-6 mr-2 hover:scale-95 hover:opacity-[0.9]"
                      >
                        Edit Details
                      </Link>
                      <button
                        onClick={handleChangeStatus}
                        className="bg-[#00A3FF] text-white text-[13px] py-2 px-6 rounded-sm hover:scale-95 hover:opacity-[0.9]"
                      >
                        Change Status
                      </button>
                    </div>
                  </div>
                </ProjectStats>
              )}

              <div className="w-full flex flex-col">
                <div className="border-t border-[#EFF2F5] border-solid flex w-[90%] mx-auto"></div>
                <TabList selectedBg="#fff" selectedColor="#00A3FF">
                  <Panel title="Details">
                    <div>
                      <h2 className="text-lg text-dark font-semibold">
                        Details
                      </h2>
                      <div className="border-t border-[#EFF2F5] border-solid w-full"></div>
                      <div className="pt-[5rem] flex">
                        <div>
                          <h4 className="text-dark font-semibold">
                            Pool Informaion
                          </h4>
                          <div className="flex items-center mt-4">
                            <p className="mr-8">Opens</p>
                            <p className="text-[#7E8299] text-sm">
                              {formatDate(projectDetails?.open_time || "")}
                            </p>
                          </div>
                          <div className="flex items-center mt-4">
                            <p className="mr-8">Closes</p>
                            <p className="text-[#7E8299] text-sm">
                              {formatDate(projectDetails?.close_time || "")}
                            </p>
                          </div>
                          <div className="flex items-center mt-4">
                            <p className="mr-8">Swap Rate</p>
                            <p className="text-[#7E8299] text-sm">
                              {projectDetails?.swap_rate}{" "}
                              {projectDetails?.token_code}
                            </p>
                          </div>
                          <div className="flex items-center mt-4">
                            <p className="mr-8">Pool Size</p>
                            <p className="text-[#7E8299] text-sm">
                              {formatToCurrencyString(
                                parseFloat(projectDetails?.pool_size || "0")
                              )}{" "}
                              USDT
                            </p>
                          </div>
                        </div>
                        <div className="mx-auto">
                          <h4 className="text-dark font-semibold">
                            Token Informaion
                          </h4>
                          <div className="mt-4">
                            <div className="flex items-center">
                              <p className="mr-8">Name</p>
                              <p className="text-[#7E8299] text-sm">
                                {projectDetails?.name}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <p className="mr-8">Symbol</p>
                              <p className="text-[#7E8299] text-sm">
                                {projectDetails?.token_code}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                  <Panel title="Description">
                    <div>
                      <h2 className="text-lg text-dark font-semibold">
                        Description
                      </h2>
                      <div className="border-t border-[#EFF2F5] border-solid w-full"></div>
                      <div className="pt-[2rem] flex">
                        <p>{projectDetails?.description}</p>
                      </div>
                    </div>
                  </Panel>
                  <Panel title="Contribution Schedules">
                    <Schedule details={projectDetails} />
                  </Panel>
                  <Panel title="Distribution Schedules">
                    <DistributionSchedules
                      details={projectDetails!}
                      refetch={refetch}
                    />
                  </Panel>
                  <Panel title="Contributions">
                    <Contributions projectId={project || ""} />
                  </Panel>
                  <Panel title="Claims">
                    <div>jhgvc</div>
                  </Panel>
                </TabList>
              </div>
            </>
          ) : (
            <div className=" w-full h-full overflow-y-auto min-h-[100vh] items-center justify-center flex z-[100]">
              <div className="m-auto">
                <Spinner />
              </div>
            </div>
          )}
        </div>
        {showModal ? (
          <UpdateProcessModal
            refecthProjectDetails={refetch}
            project={projectDetails!}
            setShowModal={setShowModal}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AdminView;
