import { useState } from "react";
import { ReactComponent as SearchIcon } from "assets/rocket-img/icons/search_icon_black.svg";
import { ReactComponent as Grid1 } from "assets/rocket-img/icons/grid_1.svg";
import { ReactComponent as Grid2 } from "assets/rocket-img/icons/grid_2.svg";
import { ReactComponent as ChevronDown } from "assets/rocket-img/icons/chevron_down.svg";
import { GET_SCHEDULES } from "services/api/projects";
import { useQuery } from "react-query";
import Table from "../../../Table";
import { Column } from "react-table";
import CreateScheduleModal from "./createSchedule";
import { IContributionSchedule } from "interfaces/projects";
import Spinner from "components/loader-utils/Spinner";
import { formatToCurrencyString } from "utils/formatToCurrencyString";

const Schedule = ({ details }: { details: any }) => {
  const [isNewSchedule, setIsNewSchedule] = useState(false);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const {
    data: schedules,
    isSuccess: schedulesIsSuccess,
    refetch,
    isLoading: scheduleIsLoading,
  } = useQuery(
    [
      "getSchedules",
      {
        id: details?.id,
        page: page + 1,
      },
    ],
    GET_SCHEDULES,
    {
      staleTime: Infinity,
    }
  );
  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    });
  };
  const displaySchedules = (): IContributionSchedulesTable[] => {
    if (schedules?.data && Array.isArray(schedules?.data)) {
      return schedules?.data?.map((schedule: IContributionSchedule) => ({
        name: schedule?.name,
        contribution_fee: `${schedule?.contribution_fee}%`,
        max_contribution: parseFloat(`${schedule?.max_contribution}`),
        min_contribution: parseFloat(`${schedule?.min_contribution}`),
        closeTime: formatDate(schedule?.close_datetime),
        openTime: formatDate(schedule?.open_datetime),
        id: schedule?.id,
      }));
    }
    return [];
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <button
          onClick={() => setIsNewSchedule(true)}
          className="bg-[#1DC894] text-white py-3 px-6 ml-auto mr-4 rounded font-semibold hover:scale-95 hover:opacity-[0.9]"
        >
          Add New
        </button>
        <div className="flex flex-col sm:flex-row sm:items-center py-2 mt-8 border-b border-[#eee] border-solid shadow-sm">
          <h2 className="text-xl text-dark font-semibold">
            Schedules ({schedules?.data?.length || 0})
          </h2>
          <div className="relative sm:ml-4 my-4 sm:my-0">
            <input
              type="search"
              className="bg-white outline-0 py-2 pl-8"
              placeholder="Search"
              name="search_contribution"
            />
            <span className="w-[15px] h-[15px] absolute top-[50%] translate-y-[-50%] left-[5px]">
              <SearchIcon />
            </span>
          </div>
          <div className="sm:ml-auto flex items-center ">
            <div className="w-[15px] h-[15px] mr-4">
              <Grid1 />
            </div>
            <div className="w-[15px] h-[15px] mr-4">
              <Grid2 />
            </div>
            <div className="mr-4">
              Filters
              <span className="ml-4">2</span>
            </div>
            <button className="flex items-center">
              Export
              <span className="w-[10x] ml-6">
                <ChevronDown />
              </span>
            </button>
          </div>
        </div>
        {scheduleIsLoading ? (
          <div className=" w-full h-full overflow-y-auto py-[7rem] flex z-[100]">
            <div className="m-auto">
              <Spinner />
            </div>
          </div>
        ) : (
          <Table
            isNavigation={false}
            active={"contribution schedules"}
            tableColumns={tableColumns}
            tableData={displaySchedules()}
            hasPagination={true}
            setPageIndex={setPage}
            queryPageIndex={page}
            queryPageSize={pageSize}
            isSuccess={schedulesIsSuccess}
            totalItems={10}
          />
        )}
      </div>
      {isNewSchedule ? (
        <CreateScheduleModal
          refetchSchedules={refetch}
          details={details}
          setIsNewSchedule={setIsNewSchedule}
        />
      ) : (
        ""
      )}
    </>
  );
};

interface IContributionSchedulesTable {
  id: string;
  name: string;
  contribution_fee: string;
  max_contribution: number;
  min_contribution: number;
  closeTime: string;
  openTime: string;
}

const tableColumns: readonly Column<IContributionSchedulesTable>[] = [
  {
    Header: "name",
    accessor: "name",
    Cell: (Row) => {
      const { value } = Row;
      return <span className="text-[#3F4254]">{value}</span>;
    },
  },
  {
    Header: "contribution fee",
    accessor: "contribution_fee",
    Cell: (Row) => {
      const { value } = Row;
      return <span className="text-[#3F4254]">{value}</span>;
    },
  },
  {
    Header: "min contribution",
    accessor: "min_contribution",
    Cell: (Row) => {
      const { value } = Row;

      return (
        <span className="text-[#3F4254]">${formatToCurrencyString(value)}</span>
      );
    },
  },
  {
    Header: "max contribution",
    accessor: "max_contribution",
    Cell: (Row) => {
      const { value } = Row;
      return (
        <span className="text-[#3F4254]">${formatToCurrencyString(value)}</span>
      );
    },
  },
  {
    Header: "open time",
    accessor: "openTime",
    Cell: (Row) => {
      const { value } = Row;
      return <span className="text-[#3F4254]">{value}</span>;
    },
  },
  {
    Header: "close time",
    accessor: "closeTime",
    Cell: (Row) => {
      const { value } = Row;
      return <span className="text-[#3F4254]">{value}</span>;
    },
  },
];

export default Schedule;
