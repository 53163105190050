import { rocketAPI } from "./base";

interface SaveSignatureTransactionRequest {
  transactionId: string;
  blockchain?: string;
  nftId: number;
  signatureHash?: `0x${string}`;
  subId: string;
}

const adminRoutes = {
  signIn: "/auth/admin/signin",
};
const userRoutes = {
  signData: "customer/sign-subscription",
  saveSignatureTransaction: "customer/save-signed-transaction", //
};
export const ADMIN_SIGN_IN = (userPayload: {
  email: string;
  password: string;
}) => {
  return rocketAPI.post(adminRoutes.signIn, userPayload);
};

export const SignSubscription = (userPayload: {
  userId: string;
  subId: string;
}) => {
  return rocketAPI.post(`${userRoutes.signData}/${userPayload.subId}`, {
    walletAddress: userPayload.userId,
  });
};

export const SaveSignatureTransaction = ({
  transactionId,
  blockchain,
  nftId,
  signatureHash,
  subId,
}: SaveSignatureTransactionRequest) => {
  return rocketAPI.post(`customer/${subId}/save-signed-transaction`, {
    transactionId,
    blockchain,
    nftId,
    signatureHash,
  });
};
