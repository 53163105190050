import { ReactComponent as Logo } from "../assets/rocket-img/img/logo.svg";
import { useAppContext } from "../contexts/AppContext";

const MobileHeader = () => {
  const { appState, updateState } = useAppContext();
  const { isMobile } = appState;
  const toggleIsMobile = () => {
    if (!updateState) return;
    updateState({
      isMobile: !isMobile,
    });
  };
  return (
    <>
      <div className="flex lg:hidden sticky items-center bg-white border-b border-[#ddd] border-solid shadow-sm w-full py-6 px-8">
        <div className="w-[122px] h-[55px]">
          <Logo />
        </div>
        <div className="ml-auto">
          <button
            className="relative flex lg:hidden flex-col  justify-around w-[2rem] h-[1.5rem] bg-transparent pointer z-[10001]"
            onClick={toggleIsMobile}
            style={{ WebkitTapHighlightColor: "transparent" }}
          >
            <div
              className="w-[1.8rem] h-[0.18rem] bg-[#151C31] rounded-xl relative origin-[1px] transition-[transform] ease-in-out duration-500"
              style={
                isMobile
                  ? {
                      transform: `rotate(${45}deg)`,
                      width: "1.5rem",
                    }
                  : {
                      transform: `rotate(${0})`,
                    }
              }
            />
            <div
              className="w-[1.8rem] h-[0.18rem] bg-[#151C31] rounded-xl relative origin-[1px] transition-[transform] ease-in-out duration-500"
              style={
                isMobile
                  ? { transform: `translateX(${20}px)`, opacity: 0 }
                  : {
                      transform: `translateX(${0})`,
                      opacity: 1,
                    }
              }
            />
            <div
              className="w-[1.8rem] h-[0.18rem] bg-[#151C31] rounded-xl relative origin-[1px] transition-[transform] ease-in-out duration-500"
              style={
                isMobile
                  ? {
                      transform: `rotate(${-45}deg)`,
                      width: "1.5rem",
                    }
                  : {
                      transform: `rotate(${0})`,
                    }
              }
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
