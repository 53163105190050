// import { ChevronDownIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { CSSProperties } from "react";

interface DropdownProps {
  style?: CSSProperties;
}

const TokenBalanceDropdown = ({ style }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const items = [
    { label: "Option 1", icon: '<ChevronDownIcon className="w-4 h-4" />' },
    { label: "Option 2", icon: '<ChevronDownIcon className="w-4 h-4" />' },
    // Add more items as needed
  ];

  return (
    <div className="relative" style={style}>
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-center px-4 py-2 space-x-2 bg-blue-500 text-white rounded-md focus:outline-none"
      >
        <span>Your Balance</span>
        {/* <Icon className="w-5 h-5" /> */}
      </button>
      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-48 py-2 bg-white border border-gray-300 rounded-lg shadow-lg">
          {items.map((item, index) => (
            <button
              key={index}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none"
              onClick={() => {
                // Handle item selection here
                console.log("Selected:", item.label);
                toggleDropdown(); // Close the dropdown after selection (optional)
              }}
            >
              {item.icon}
              <span className="ml-2">{item.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TokenBalanceDropdown;
