import { useState, useEffect, useCallback } from "react";

const useDisplayPaginationButtons = (
  state: any,
  pageOptions: number[],
  pageCount: number,
  gotoPage: any
) => {
  const [paginateButtons, setPaginateButtons] = useState<number[]>([
    state.pageIndex + 1,
    state.pageIndex + 2,
    state.pageIndex + 3,
  ]);
  useEffect(() => {
    if (
      state.pageIndex !== 0 &&
      state.pageIndex !== 1 &&
      state.pageIndex !== pageOptions.length - 1
    ) {
      setPaginateButtons([
        state.pageIndex,
        state.pageIndex + 1,
        state.pageIndex + 2,
      ]);
    }
    if (state.pageIndex === pageOptions.length - 1 && pageOptions.length > 3) {
      setPaginateButtons([
        state.pageIndex - 2,
        state.pageIndex - 1,
        state.pageIndex,
      ]);
    }
    if (state.pageIndex === 0) {
      setPaginateButtons([
        state.pageIndex + 1,
        state.pageIndex + 2,
        state.pageIndex + 3,
      ]);
    }
  }, [state.pageIndex]);

  const displayPaginationButtons = useCallback(() => {
    return (
      <>
        <div className={paginateButtons.includes(1) ? "ml-auto lg:ml-0" : ""}>
          {paginateButtons
            .filter((item) => {
              return item < pageCount;
            })
            .map((item) => (
              <button
                key={item}
                style={
                  item === state.pageIndex + 1
                    ? {
                        backgroundColor: "#00A3FF",
                        color: "#fff",
                        borderColor: "#00A3FF",
                        borderRadius: "0.3rem",
                      }
                    : {}
                }
                className="text-[#667085] py-1 px-3 mx-2"
                onClick={() => {
                  gotoPage(item - 1);
                }}
              >
                {item}
              </button>
            ))}
        </div>
      </>
    );
  }, [state.pageIndex, paginateButtons, pageCount]);

  return { displayPaginationButtons, paginateButtons };
};

export default useDisplayPaginationButtons;
