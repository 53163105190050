import { useState } from "react";
import { IPanelProps } from "./Panel";

interface ITabProps {
  children: any[];
  selectedBg: string;
  selectedColor: string;
  selectedPanel?: number;
}

export const TabList = ({
  selectedPanel,
  children,
  selectedBg,
  selectedColor,
}: ITabProps) => {
  const [selected, setSelected] = useState(selectedPanel || 0);
  const handleChange = (index: number) => {
    setSelected(index);
  };
  return (
    <>
      <ul
        className="flex flex-wrap xl:flex-nowrap w-full bg-white text-[#A1A5B7]"
        role="tablist"
      >
        {children.map((element, index) => {
          const { title }: IPanelProps = element.props;

          return (
            <li
              key={title}
              className="mx-8 pt-4 pb-2 lg:py-2 transition-[border] duration-200"
              style={
                index === selected &&
                index !== children.length - 1 &&
                index !== 0
                  ? {
                      backgroundColor: selectedBg,
                      color: selectedColor,
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      borderBottom: "2px solid #00A3FF",
                    }
                  : index === selected && index === children.length - 1
                  ? {
                      backgroundColor: selectedBg,
                      color: selectedColor,
                      paddingLeft: "1rem",
                      paddingRight: "1rem",

                      borderBottom: "2px solid #00A3FF",
                    }
                  : index === selected && index === 0
                  ? {
                      backgroundColor: selectedBg,
                      color: selectedColor,
                      paddingLeft: "1rem",
                      paddingRight: "1rem",

                      borderBottom: "2px solid #00A3FF",
                    }
                  : {}
              }
              role="tab"
            >
              <button onClick={() => handleChange(index)}>{title}</button>
            </li>
          );
        })}
      </ul>
      <div className="p-6 mt-8 bg-white">{children[selected]}</div>
    </>
  );
};
