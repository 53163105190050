/* eslint-disable react-hooks/exhaustive-deps */
import { useParams, useLocation, Link } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import { ReactComponent as CloudIcon } from "../assets/rocket-img/icons/cloud_icon.svg";
import {
  useDisconnect,
  useSignMessage,
  useContract,
  useSigner,
  useNetwork,
  useConnect,
  Chain,
} from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { polygon, polygonMumbai } from "wagmi/chains";
import { verifyMessage } from "ethers/lib/utils.js";
import { useMutation } from "react-query";
import {
  SaveSignatureTransaction,
  SignSubscription,
} from "services/api/userActions";
import { useState } from "react";
import { useEffect } from "react";
import CONTRACT_ABI_PERMISSIONS from "./../contract/permission.json";
import { IMainNetChains, IMetaMaskErr, IRouteError, ITestNetChains } from "interfaces/general";
import { useSnackbar } from "notistack";
import ModalBox from "./ModalBox";
import ErrorIcon from "../assets/rocket-img/icons/error_icon.svg";
import MetaMask from "../assets/rocket-img/icons/MetaMask_Fox.svg";
import {
  defaultChain,
  getEnvChains,
  isChainIdAvailable,
} from "utils/getChainId";

interface HeaderProps {
  name?: string;
  title: string;
  subTitle: string;
}
interface Tree {
  merkleProof: string[];
  root: string;
  nftTierId: number;
}
const initialTreeValue = {
  merkleProof: [],
  root: "",
  nftTierId: 0,
};

const CONTRACT_ADDRESS = process.env.REACT_APP_PERMISSION_CONTRACT_ADDRESS;
// "0x87fd7f24D5A2FACF298076Df751Ac2E320013851";

const Header = ({ name, title, subTitle }: HeaderProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { project } = useParams();
  const location = useLocation();
  const { appState } = useAppContext();
  const { isAdmin, walletAddress, walletConnected } = appState;
  const [mintNFT, setMintNFT] = useState(false);
  const { memberFulUser } = appState;
  const [hasSignSub, setHasSignSub] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [message, setMessage] = useState("");

  const signSubscription = useMutation("signSubscription", SignSubscription, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });

  const saveSignatureTransaction = useMutation(
    "saveSignatureTransaction",
    SaveSignatureTransaction,
    {
      onError: (error) => {
        const errorMessage = (error as IRouteError)?.response.data.message;
        enqueueSnackbar({
          variant: "error",
          message: errorMessage,
          autoHideDuration: 5000,
        });
      },
    }
  );

  const handleSetSignSub = () => {
    if (memberFulUser?.customer?.signedTransaction?.id) {
      setHasSignSub(true);
    } else {
      setHasSignSub(false);
    }
  };

  useEffect(() => {
    handleSetSignSub();
  }, [memberFulUser?.customer?.signedTransaction?.id]);

  let { connect, data, isLoading: loadingWallet } = useConnect({
    connector: new InjectedConnector({
      chains: getEnvChains(),
    }),
    onMutate: async ({
      connector: { chains },
    }: {
      connector: { chains: Chain[] };
    }) => (await chainIsNotFound(chains)) && false,
    onError: async (error) => {
      if (error && error.message === "Connector not found") {
        setShowMessageBox(true);
      } else if (error.message !== "User rejected request") {
        const chainId = (window as any).ethereum?.chainId;
        const chain = isChainIdAvailable(chainId);
        chain && (await switchToDefaultNetwork(chain[0]));
      }
    },
  });

  const chainIsNotFound = async (
    chains: (ITestNetChains | IMainNetChains | Chain)[]
  ) => {
    if (
      !chains.some((chain) => chain.id === (window as any).ethereum?.chainId)
    ) {
      await addNetworkAndConnect();
      return true;
    }
  };

  const switchToDefaultNetwork = async (chain: Chain | undefined) => {
    try {
      const chainId = chain?.id || defaultChain().id;
      await (window as any).ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
    } catch (error) {
      console.log(error);
    }
  };

  async function addNetworkAndConnect() {
    try {
      const { nativeCurrency, id, name, rpcUrls, blockExplorers } =
        defaultChain();
      if (window.ethereum !== undefined) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${id.toString(16)}`,
              chainName: name,
              nativeCurrency,
              rpcUrls: [`${rpcUrls.public}`],
              blockExplorerUrls: [
                `${blockExplorers?.default?.url}`,
              ],
            },
          ],
        });
      }
    } catch (error) {
      console.log("Error adding or switching network:", error);
    }
  }

  const { chain: network } = useNetwork();

  const { disconnect } = useDisconnect();
  
  const { data: signer } = useSigner();

  const contract = useContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI_PERMISSIONS,
    signerOrProvider: signer,
  });
  
  const {
    data: signatureHash,
    isLoading,
    signMessage,
  } = useSignMessage({
    onSuccess(data, variables) {
      const userId = verifyMessage(variables.message, data);
      if (memberFulUser?.customer?.subscription?.id) {
        signSubscription.mutate(
          { userId, subId: memberFulUser?.customer?.subscription?.id },
          {
            onSuccess: () => {
              setMintNFT(true);
            },
          }
        );
      } else {
        setMessage("no sub");
        setShowMessageBox(true);
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  useEffect(() => {
    if (data && !isChainIdAvailable(data?.chain?.id)) {
      disconnect();
      enqueueSnackbar({
        variant: "error",
        message: "You connected to an unsupported network",
        autoHideDuration: 5000,
      });
      (async () => {
        await addNetworkAndConnect();
      })();
    }
    data = undefined; 
  }, [data, data?.chain?.id]);
  
  
  useEffect(() => {
    if (signSubscription.isSuccess && mintNFT && signSubscription.data?.data) {
      setMintNFT(false);
      handleMint(signSubscription.data.data);
    }
  }, [
    signSubscription.isSuccess,
    contract,
    signatureHash,
    network?.name,
    mintNFT
  ]);

  const handleMint = async (data: { merkleProof: any; root: any; nftTierId: any; }) => {
    try {
      const { merkleProof, root, nftTierId: nftId } = data;
      console.log("This is:", merkleProof, root, nftId, data);
      const trnxResponse = await contract?.assignTier(merkleProof, root, nftId);

      const {
        transactionHash: transactionId,
        confirmations,
        status,
      } = await trnxResponse.wait();

      if (
        status &&
        confirmations > 0 &&
        memberFulUser?.customer?.subscription?.id
      ) {
        setHasSignSub(true);
        return saveSignatureTransaction.mutate({
          transactionId,
          blockchain: network?.name,
          nftId,
          signatureHash,
          subId: memberFulUser?.customer?.subscription?.id,
        });
      }
    } catch (error) {
      if ((error as IMetaMaskErr)?.error) {
        enqueueSnackbar({
          variant: "error",
          message: (error as IMetaMaskErr).error.data.message,
          autoHideDuration: 5000,
        });
      }
    }
  };

  const formatAddress = (address: string | undefined) => {
    return `...${address?.substring(1, 6)}`;
  };

  const metaMaskLink = (
    <div>
      <p>
        <strong>ERROR: </strong>No wallet connector found please install a web3
        wallet, we recommend
      </p>
      <a
        href="https://metamask.io/download/"
        target="_blank"
        rel="noreferrer"
        style={{ color: "blue" }}
      >
        <img src={MetaMask} alt="MetaMask" />
        MetaMask
      </a>
    </div>
  );
  const subscriptionMessage = (
    <div>
      <p className="text-bold">
        You do not have an active subscription with KryptoPlayBoy. Please
        register with us on Memberful
        <a
          href="https://kryptoplayboy.com/join/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#9c7c52", display: "block" }}
        >
          <strong>Join Now</strong>
        </a>
      </p>
    </div>
  );
  const isCreateProjectPage = location.pathname === "/projects/create";
  return (
    <>
      <div className="w-full flex flex-col sm:flex-row sm:items-center pt-10 relative">
        <h1 className="flex flex-col text-dark font-semibold mb-8 sm:my-0 text-2xl">
          {title}
          {!project && !isCreateProjectPage ? (
            <small className="text-[#B5B5C3] text-lg ml-1 pt-1">
              {subTitle}
            </small>
          ) : project || isCreateProjectPage ? (
            <small className="text-[#B5B5C3] text-lg ml-1 pt-1">
              <Link to={"/"} className="ml-1">
                {" "}
                Home{" "}
              </Link>
              {"/"}
              <Link to={"/projects"} className="mx-1">
                Projects
              </Link>
              {"/"}
              <span
                className="ml-1"
                style={{ fontWeight: "900", color: "#181c32" }}
              >
                {location.pathname === `/projects/create`
                  ? "New Project"
                  : name}
              </span>
            </small>
          ) : (
            ""
          )}
        </h1>
        {!walletConnected ? (
          <button
            onClick={() => connect()}
            className="flex items-center py-3 px-6 max-w-[210px]  mt-2 sm:mt-0 sm:ml-auto rounded font-semibold hover:scale-95 hover:opacity-[0.9]"
            style={{ backgroundColor: "#7E8299", color: "#fff" }}
          >
            <span className="w-[15px] h-[15px] mr-4">
              <CloudIcon />
            </span>
            Connect Wallet
          </button>
        ) : walletConnected && !hasSignSub && !isAdmin ? (
          <div
            className="absolute top-0 sm:top-[20px] right-0 flex flex-row justify-between py-4 px-6  bg-wheat mt-0  border-x-2 mx-1.5"
            style={{ width: "80%", background: "#efe9e1" }}
          >
            <span className="ml-1 text-[#9f9c97] text-lg ml-1 pt-1">
              Sign your subscription to be able to contribute to a pool
            </span>
            <button
              className={`order-last py-2 px-2 rounded-lg  h-full relative text-white ml-10 text-center hover:scale-95 hover:opacity-[0.9] disabled`}
              style={{ backgroundColor: "rgb(27, 40, 63)" }}
              onClick={(event) => {
                signMessage({
                  message:
                    "Please Sign that you are allowing Rocket use your memberful subscription to create an NFT with the Rocket Id attached to your subscription.",
                });
              }}
              disabled={isLoading}
            >
              Sign Subscription
            </button>
          </div>
        ) : (walletConnected && hasSignSub) || (walletConnected && isAdmin) ? (
          <div className="items-center max-w-[210px] mt-2 sm:mt-0 sm:ml-auto shrink-0 flex">
            <small className="text-[#B5B5C3] text-lg fw-bold ml-1 pt-1">
              {formatAddress(walletAddress)}
            </small>
            <button
              onClick={() => {
                disconnect();
              }}
              style={{ backgroundColor: "#1BC5BD" }}
              className="py-2 px-2 rounded-lg w-[120px] h-[36px] relative text-white ml-10 text-center hover:scale-95 hover:opacity-[0.9]"
            >
              Connected
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {showMessageBox && (
        <ModalBox
          message={message === "" ? metaMaskLink : subscriptionMessage}
          toggleClose={() => setShowMessageBox(false)}
          SuccessIcon={ErrorIcon}
          key="no-connector"
        />
      )}
    </>
  );
};

export default Header;
