import React from "react";

export interface IPanelProps {
  title: string;
  children: React.ReactNode;
}

export const Panel = ({ children }: IPanelProps) => {
  return (
    <>
      <div role="tabpanel">{children}</div>
    </>
  );
};
