import { Column } from "react-table";
import { Link } from "react-router-dom";
import { ReactComponent as Grid2 } from "assets/rocket-img/icons/grid_2 _inactive.svg";
import { formatToCurrencyString } from "utils/formatToCurrencyString";

export interface ContributionTable {
  isSelected?: boolean;
  project: {
    name: string;
    id: string;
    network: string;
  };
  amount: number;
  tokenSymbol: string;
  swapRate: number | string;
  status: string;
  date?: string;
}
const getStyles = (value: string) => {
  if (value === "released") {
    return {
      backgroundColor: "#DFF1EB",
      color: "#2BA579",
    };
  } else if (value === "upcoming") {
    return {
      backgroundColor: "#F5F8FA",
      color: "#A1A5B7",
    };
  } else if (value === "closed") {
    return {
      backgroundColor: "#FFE2E5",
      color: "#F64E60",
    };
  } else {
    return {
      backgroundColor: "#F1FAFF",
      color: "#00A3FF",
    };
  }
};
export const tableColumns: readonly Column<ContributionTable>[] = [
  {
    Header: "project",
    accessor: "project",
    id: "project",
    Cell: (Row) => {
      const { value } = Row;
      return (
        <Link to={`/projects/${value.id}`}>
          <div className="font-semibold capitalize">
            Project {value.name}
            <p className="text-xs text-[#B5B5C3] font-medium">
              {value.network}
            </p>
          </div>
        </Link>
      );
    },
  },
  {
    Header: "Contribution",
    accessor: "amount",
    Cell: (Row) => {
      const { value } = Row;
      return <span>{formatToCurrencyString(value)}</span>;
    },
  },
  {
    Header: "Tokens",
    accessor: "tokenSymbol",
    Cell: (Row) => {
      const { swapRate, amount, tokenSymbol } = Row.row.original;
      const tokenAmount = parseFloat(`${swapRate}`) * amount;
      return (
        <span>
          {formatToCurrencyString(tokenAmount)} {tokenSymbol}
        </span>
      );
    },
  },

  {
    Header: "Status",
    accessor: "status",
    Cell: (Row) => {
      const { value } = Row;
      return (
        <div
          className="py-2 px-4 capitalize rounded-lg leading-5 w-fit text-xs"
          style={getStyles(value)}
        >
          {value}
        </div>
      );
    },
  },
  {
    Header: "TGE",
    accessor: "date",
    Cell: (Row) => {
      const { value } = Row;
      return <span>-</span>;
    },
  },
  {
    Header: "Action",
    accessor: (row) => row?.project.id,
    Cell: (value: string) => {
      return (
        <button className="p-3 text-white bg-[#DADADA] hover:bg-[#1BC5BD] rounded-lg">
          Claim
        </button>
      );
    },
  },
];
