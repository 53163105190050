import { useMutation } from "react-query";
import { Contribution, DistributionSchedule } from "@/interfaces/projects";
import { rocketAPI } from "services/api/base";
import { useEffect, useState } from "react";
import Table from "components/Table";
import { formatDate } from "utils/formatDate";
import type { Column } from "react-table";
import { LoaderButton } from "components/loader-utils/LoaderButton";
import { CLAIM } from "services/api/projects";
import { useContract, useSigner } from "wagmi";
import CONTRACT_ABI from "contract/abi.json";
import { IMetaMaskErr, IRouteError } from "interfaces/general";
import Spinner from "components/loader-utils/Spinner";
import { formatToCurrencyString } from "utils/formatToCurrencyString";
import { useSnackbar } from "notistack";

interface ClaimsProps {
  projectId?: string;
  projectPoolId: string;
  contributions?: Contribution[];
}
interface ClaimableDistributions extends DistributionSchedule {
  claimAbleAmount: number;
  remaining: number;
}
type ClaimableContributions = {
  distribution: ClaimableDistributions;
  contribution: Contribution;
}[];
export interface Claim {
  contributionId: string;
  blockchainContributionId: number;
  distributionId: string;
  blockchainDistributionId: string | null;
  batchName: string;
  date: string;
  contributionDate: string;
  claimed: number;
  remaining: number;
  percentage: string;
  status: string;
}

const getStyles = (value: string) => {
  if (value === "active") {
    return {
      backgroundColor: "#DFF1EB",
      color: "#2BA579",
    };
  } else {
    return {
      backgroundColor: "#FFE2E5",
      color: "#F64E60",
    };
  }
};

const Claims = ({ projectId, contributions, projectPoolId }: ClaimsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } = useMutation("claim", CLAIM, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response?.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });
  const [claims, setClaims] = useState<Claim[]>([]);
  const [isLoadingClaimsData, setIsLoadingClaimsData] = useState(false);

  const fetchClaim = async (contributionId: string) => {
    const res = await rocketAPI.get<ClaimableContributions>(
      `/contribution/${contributionId}/claim-distribution/${projectId}`
    );
    return res.data;
  };

  const getClaims = async (contributions: Contribution[]) => {
    try {
      setIsLoadingClaimsData(true);
      const claimsData = await Promise.all(
        contributions.map(async (item) => {
          const res = await fetchClaim(item.id);
          const distribution = res?.[0]?.distribution;
          const contribution = res?.[0]?.contribution;

          return {
            contributionId: contribution.id,
            blockchainContributionId: contribution.blockchainContributionId,
            contributionDate: contribution.create_date,
            distributionId: distribution.id,
            blockchainDistributionId: distribution.distributionId,
            batchName: distribution.batchId,
            date: distribution.startDate,
            claimed: distribution.remaining - distribution.claimAbleAmount,
            remaining: distribution.remaining,
            percentage: distribution.claimPercentage,
            status: distribution.status,
          };
        })
      );

      // Check if the blockchain contribution id exists
      const filteredClaimsData = claimsData.filter((claim) => {
        if (claim.blockchainContributionId) {
          return true;
        }
        return false;
      });
      // Set the claims state variable
      setClaims(filteredClaimsData);
      setIsLoadingClaimsData(false);
    } catch (error) {
      setIsLoadingClaimsData(false);
      enqueueSnackbar({
        variant: "error",
        message: "Could not fetch Claims",
        autoHideDuration: 5000,
      });
    }
  };
  useEffect(() => {
    if (contributions) {
      getClaims(contributions);
    }
  }, [contributions?.length]);

  const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const [contractLoading, setContractLoading] = useState(false);
  const { data: signer } = useSigner();
  const contract = useContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    signerOrProvider: signer,
  });

  const ClaimToken = async (
    contributionId: string,
    blockchainContributionId: number,
    distributionId: string,
    blockchainDistributionId: string
  ) => {
    try {
      setContractLoading(true);

      console.log("block contri, :", blockchainContributionId);
      console.log("block distri", blockchainDistributionId);

      const transaction = await contract?.claimPoolRewardToken(
        projectPoolId,
        blockchainContributionId,
        blockchainDistributionId
      );
      //   wait for transactionResponse
      const res = await transaction.wait(2);
      console.log(res);

      setContractLoading(false);
      await mutateAsync({
        data: {
          projectId: projectId!,
          contributionId,
          distributionId,
        },
        method: "POST",
      });
    } catch (error) {
      setContractLoading(false);
      console.log(error);

      if ((error as IMetaMaskErr)?.code) {
        enqueueSnackbar({
          variant: "error",
          message: (error as IMetaMaskErr).error.data.message,
          autoHideDuration: 5000,
        });
      }
    }
  };

  const tableColumn: readonly Column<Claim>[] = [
    {
      Header: "release",
      accessor: "batchName",
      Cell: (Row) => {
        const { value } = Row;
        return (
          <div className="text-[#3F4254] flex items-center">Period {value}</div>
        );
      },
    },
    {
      Header: "distribution date",
      accessor: "date",
      Cell: (Row) => {
        const { value } = Row;
        return (
          <div className="text-[#3F4254] flex items-center">
            {formatDate(value)}
          </div>
        );
      },
    },
    {
      Header: "contribution date",
      accessor: "contributionDate",
      Cell: (Row) => {
        const { value } = Row;
        return (
          <div className="text-[#3F4254] flex items-center">
            {formatDate(value)}
          </div>
        );
      },
    },
    {
      Header: "Claimed",
      accessor: "claimed",
      Cell: (Row) => {
        const { value } = Row;
        return (
          <div className="text-[#3F4254] flex items-center">
            ${formatToCurrencyString(value)}
          </div>
        );
      },
    },
    {
      Header: "Remaining",
      accessor: "remaining",
      Cell: (Row) => {
        const { value } = Row;
        return (
          <div className="text-[#3F4254] flex items-center">
            ${formatToCurrencyString(value)}
          </div>
        );
      },
    },
    {
      Header: "Percentage(%)",
      accessor: "percentage",
      Cell: (Row) => {
        const { value } = Row;
        return <div className="text-[#3F4254] flex items-center">{value}%</div>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (Row) => {
        const { value } = Row;
        return (
          <div
            className="py-2 px-4 capitalize rounded-lg leading-5 w-fit text-xs"
            style={getStyles(value)}
          >
            {value}
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: "distributionId",
      Cell: (Row) => {
        const {
          distributionId,
          blockchainDistributionId,
          contributionId,
          blockchainContributionId,
        } = Row.row.original;
        return (
          <LoaderButton
            loading={isLoading}
            onClick={() =>
              ClaimToken(
                contributionId,
                blockchainContributionId,
                distributionId,
                blockchainDistributionId!
              )
            }
            className="p-3 text-white bg-[#DADADA] hover:bg-[#1BC5BD] rounded-lg font-medium whitespace-nowrap btn-style min-w-[100px]"
          >
            Claim
          </LoaderButton>
        );
      },
    },
  ];

  return (
    <>
      {contractLoading || isLoadingClaimsData ? (
        <div className="fixed w-full h-full top-0 left-0 bg-black/10 z-[200]">
          <div className=" w-full h-full flex">
            <div className="m-auto">
              <Spinner />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <Table
        tableColumns={tableColumn}
        tableData={claims}
        hasPagination={false}
        active="claims-user"
        isNavigation={false}
      />
    </>
  );
};

export default Claims;
