import { ReactComponent as SearchIcon } from "assets/rocket-img/icons/search_icon_black.svg";
import { ReactComponent as Grid1 } from "assets/rocket-img/icons/grid_1.svg";
import { ReactComponent as Grid2 } from "assets/rocket-img/icons/grid_2.svg";
import { ReactComponent as ChevronDown } from "assets/rocket-img/icons/chevron_down.svg";
import { useState } from "react";
import { GET_CONTRIBUTIONS } from "services/api/projects";
import { useQuery } from "react-query";
import Spinner from "components/loader-utils/Spinner";
import Table from "../../../Table";
import { formatDate } from "utils/formatDate";
import { tableColumn } from "./tableColumn";

interface IProps {
  projectId: string;
}
const Contributions = ({ projectId }: IProps) => {
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const { data, error, isLoading, isSuccess } = useQuery(
    ["getAllContributions", { page: page + 1, projectId }],
    GET_CONTRIBUTIONS,
    {
      staleTime: Infinity,
    }
  );

  const displayContributions = () => {
    if (data?.data.items && Array.isArray(data?.data.items)) {
      return data?.data.items.map((contribution) => ({
        user: { name: "User Name", email: "user@test.com" },
        date: formatDate(contribution.create_date),
        amount: contribution.amount,
      }));
    }
    return [];
  };
  return (
    <>
      <div>
        <div className="flex items-center py-2 border-b border-[#eee] border-solid shadow-sm">
          <h2 className="text-xl text-dark font-semibold">
            Contributions ({data?.data?.items?.length})
          </h2>
          <div className="relative ml-4">
            <input
              type="search"
              className="bg-white outline-0 py-2 pl-8"
              placeholder="Search"
              name="search_contribution"
            />
            <span className="w-[15px] h-[15px] absolute top-[50%] translate-y-[-50%] left-[5px]">
              <SearchIcon />
            </span>
          </div>
          <div className="ml-auto flex items-center">
            <div className="w-[15px] h-[15px] mr-4">
              <Grid1 />
            </div>
            <div className="w-[15px] h-[15px] mr-4">
              <Grid2 />
            </div>
            <div className="mr-4">
              Filters
              <span className="ml-4">2</span>
            </div>
            <button className="flex items-center">
              Export
              <span className="w-[10x] ml-6">
                <ChevronDown />
              </span>
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className=" w-full h-full overflow-y-auto py-[7rem] flex z-[100]">
            <div className="m-auto">
              <Spinner />
            </div>
          </div>
        ) : (
          <Table
            isNavigation={false}
            active={"contributions"}
            tableColumns={tableColumn}
            tableData={displayContributions()}
            hasPagination={true}
            setPageIndex={setPage}
            queryPageIndex={page}
            queryPageSize={pageSize}
            isSuccess={isSuccess}
            totalItems={data?.data.meta.totalItems || 0}
          />
        )}
      </div>
    </>
  );
};

export default Contributions;
