import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import InitialProvider from "./contexts/AppContext";
import Web3Config from "./Web3Config";
import { CookiesProvider } from "react-cookie";
import { SnackbarProvider } from "notistack";
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <InitialProvider>
            <Web3Config>
              <App />
            </Web3Config>
          </InitialProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
