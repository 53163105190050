import React, { useEffect, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import {
  useContract,
  useSigner,
  useContractEvent,
  useConnect,
  useSwitchNetwork,
} from "wagmi";
import CONTRACT_ABI from "contract/abi.json";
import { Formik, Field, ErrorMessage, Form } from "formik";
import type { FormikProps } from "formik";
import { validationSchemaWEB3 } from "./utils/schema";
import { useAppContext } from "contexts/AppContext";
import { InjectedConnector } from "wagmi/connectors/injected";
import { LoaderButton } from "components/loader-utils/LoaderButton";
import { UPDATE_POOL_ID } from "services/api/projects";
import { UPLOAD_CLOUDINARY } from "services/cloudinary";
import { useMutation } from "react-query";
import type { UseMutationResult } from "react-query";
import type { AxiosResponse } from "axios";
import { getTimeStamp } from "utils/getTimeStamp";
import { ICreateProject } from "interfaces/projects";
import { IMetaMaskErr, IRouteError } from "interfaces/general";
import { convertToFloat } from "utils/convertToFloat";
import { useSnackbar } from "notistack";
import { getChainId } from "utils/getChainId";

interface IProjectBlockChain {
  addNewProject: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    {
      project: ICreateProject;
    },
    unknown
  >;
  setShowWeb3Modal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  setProjectState: Dispatch<SetStateAction<ICreateProject | null>>;
  projectState: ICreateProject | null;
}

interface IPoolParams {
  _targetAmount: number;
  _tokens: string[];
  expiry: number;
  _receiver: string;
  _price: number;
  _poolRewardAddress: string;
  _poolRewardTokenAmount: number;
}
interface ProjectForm {
  _targetAmount: number;
  _tokens: string[];
  expiry: number;
  _receiver: string;
  _price: number;
  _poolRewardAddress: string;
  _tokenClaimAmount: number;
  _claimDuration: number;
  _firstInterval: number;
  _nextInterval: number;
  _finalInterval: number;
  _claimRate: number;
}
const CreateProjectWeb3 = ({
  addNewProject,
  projectState,
  setShowSuccessModal,
  setShowWeb3Modal,
  setProjectState,
}: IProjectBlockChain) => {
  const { enqueueSnackbar } = useSnackbar();
  const project = projectState as ICreateProject;
  const uploadIcon = useMutation("CLOUDINARY", UPLOAD_CLOUDINARY, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });

  const { appState } = useAppContext();
  const { walletConnected } = appState;
  const updatePoolId = useMutation("updatePoolID", UPDATE_POOL_ID, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });

  const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const USDC_CONTRACT_ADDRESS = process.env.REACT_APP_USDC_ADDRESS || "";
  // const USDT_CONTRACT_ADDRESS = "0xdAC17F958D2ee523a2206206994597C13D831ec7"
  const { switchNetwork } = useSwitchNetwork();

  const { connect } = useConnect({
    chainId: getChainId(project?.contribution_network),
    connector: new InjectedConnector(),
    onSuccess: (data) => {
      if (data.chain.id !== getChainId(project?.contribution_network)) {
        switchNetwork?.(getChainId(project?.contribution_network));
      }
    },
  });
  useEffect(() => {
    if (!walletConnected) {
      connect();
    }
  }, []);

  const getClaimDuration = (close_time: string, open_time: string) => {
    const openTimestamp = getTimeStamp(open_time);
    const closeTimeStamp = getTimeStamp(close_time);
    return Math.ceil((closeTimeStamp - openTimestamp) / 1000);
  };
  const getSurportedTokens = (value: string) => {
    return [USDC_CONTRACT_ADDRESS];
  };

  const INITAIL_VALUES: ProjectForm = {
    _targetAmount: project?.pool_size,
    _tokens: getSurportedTokens("USDC, USDT"),
    expiry: Math.ceil(getTimeStamp(project?.close_time) / 1000),
    _receiver: "",
    _price: project?.swap_rate,
    _poolRewardAddress: "",
    _tokenClaimAmount: 0,
    _claimDuration: getClaimDuration(project?.close_time, project?.open_time),
    _firstInterval: 0,
    _nextInterval: 0,
    _finalInterval: 0,
    _claimRate: 0,
  };

  return (
    <>
      <div className="bg-black/50 w-full min-w-[100vw] py-[5rem] h-full min-h-[100vh] overflow-y-auto fixed top-0 left-0 flex z-[999]">
        <div
          className={`w-[90%] lg:w-[40%] max-w-[600px] h-fit  relative z-[150] mx-auto bg-white rounded-xl`}
        >
          <div className="px-8 py-6">
            <div className="w-full">
              <h2 className="text-xl font-semibold mb-4">Create Project</h2>
              <div className="border-t border-[#EFF2F5] border-solid w-full"></div>
              <Formik
                initialValues={INITAIL_VALUES}
                validationSchema={validationSchemaWEB3}
                onSubmit={() => {}}
              >
                {(props: FormikProps<ProjectForm>) => {
                  const [poolId, setPoolId] = useState<string>();
                  const [params, setParams] = useState<IPoolParams>();
                  const [ready, setReady] = useState(false);
                  const [contractLoading, setContractLoading] = useState(false);
                  const { data: signer } = useSigner();
                  const contract = useContract({
                    address: CONTRACT_ADDRESS,
                    abi: CONTRACT_ABI,
                    signerOrProvider: signer,
                  });

                  useContractEvent({
                    address: CONTRACT_ADDRESS,
                    abi: CONTRACT_ABI,
                    eventName: "CreatedPool",
                    listener(...args: any) {
                      setPoolId(args[0]);
                    },
                    once: true,
                  });

                  const callCreatePool = async () => {
                    try {
                      setContractLoading(true);

                      const transaction = await contract?.createPool(params);
                      await transaction.wait(2);
                      setContractLoading(false);
                    } catch (error) {
                      setContractLoading(false);
                      if ((error as IMetaMaskErr)?.error) {
                        enqueueSnackbar({
                          variant: "error",
                          message: (error as IMetaMaskErr).error.data.message,
                          autoHideDuration: 5000,
                        });
                      }
                    }
                  };

                  const handleCreateProject = async () => {
                    if (typeof project?.icon !== "string") {
                      const imageFormData = new FormData();
                      imageFormData.append("file", project?.icon);
                      if (process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET) {
                        imageFormData.append(
                          "upload_preset",
                          process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
                        );
                      }

                      try {
                        if (poolId) {
                          const icon = await uploadIcon.mutateAsync(
                            imageFormData
                          );
                          if (projectState?.name) {
                            const newProject = await addNewProject.mutateAsync({
                              project: {
                                ...project,
                                icon: icon?.data?.url,
                              },
                            });
                            await updatePoolId.mutateAsync({
                              projectId: newProject?.data?.id,
                              poolId,
                            });
                            setProjectState(null);
                            setShowWeb3Modal(false);
                            setShowSuccessModal(true);
                          }
                        }
                      } catch (error) {
                        return;
                      }
                    }
                  };
                  useEffect(() => {
                    if (poolId) {
                      handleCreateProject();
                    }
                  }, [poolId]);

                  useEffect(() => {
                    if (
                      props.values["_receiver"].length >= 42 &&
                      props.values["_poolRewardAddress"].length >= 42
                    ) {
                      //  convert to 10 ^ 18 number
                      const targetAmountFloat = convertToFloat(
                        props.values["_targetAmount"]
                      );
                      const expiryFloat = convertToFloat(
                        props.values["expiry"]
                      );
                      const priceFloat = convertToFloat(props.values["_price"]);
                      const rewardAmountFloat = convertToFloat(
                        props.values["_tokenClaimAmount"]
                      );
                      setParams({
                        _targetAmount: targetAmountFloat,
                        _tokens: props.values["_tokens"],
                        expiry: expiryFloat,
                        _receiver: `${props.values["_receiver"]}`,
                        _price: priceFloat,
                        _poolRewardAddress: `${props.values["_poolRewardAddress"]}`,
                        _poolRewardTokenAmount: rewardAmountFloat,
                      });

                      setReady(true);
                    }
                  }, [
                    props.values["_receiver"],
                    props.values["_poolRewardAddress"],
                    props.values["_tokens"],
                    props.values["_targetAmount"],
                    props.values["expiry"],
                    props.values["_price"],
                    props.values["_tokenClaimAmount"],
                  ]);

                  return (
                    <Form>
                      <div className="flex items-center w-full my-10">
                        <label
                          htmlFor="_receiver"
                          className="text-lg font-semibold w-[141px]"
                        >
                          Reciever Address
                        </label>
                        <div className="mx-auto w-[445px]">
                          <Field
                            name="_receiver"
                            type="text"
                            className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                          />
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="_receiver" />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center w-full my-10">
                        <label
                          htmlFor="_poolRewardAddress"
                          className="text-lg font-semibold w-[141px]"
                        >
                          Reward Address
                        </label>
                        <div className="mx-auto w-[445px]">
                          <Field
                            name="_poolRewardAddress"
                            type="text"
                            className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                          />
                          <div className="text-red-500 text-sm">
                            <ErrorMessage name="_poolRewardAddress" />
                          </div>
                        </div>
                      </div>
                      <div className="w-[90%] mx-auto pt-10 pb-[5rem] flex">
                        <LoaderButton
                          loading={
                            contractLoading ||
                            uploadIcon.isLoading ||
                            addNewProject.isLoading ||
                            updatePoolId.isLoading
                          }
                          onClick={() => {
                            if (ready) {
                              callCreatePool();
                            }
                          }}
                          type="button"
                          className="ml-auto bg-[#00A3FF] text-white rounded py-2 pl-2 px-4 hover:scale-95 hover:opacity-[0.9]"
                        >
                          Create Project
                        </LoaderButton>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProjectWeb3;
