import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NavArrow } from "../../../assets/rocket-img/icons/nav-arrow.svg";

const UserProfile = ({ activeLink }: { activeLink: string }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  return (
    <div className={`flex flex-col mb-6`} onClick={toggleSubMenu} role="button">
      <Link to="/user" className="flex items-center">
        <span
          className={`w-[15px] h-[15px] mr-2 transition-[transform] duration-300 ${
            activeLink === "user" ? "opacity-100" : "opacity-30"
          } `}
        >
          <NavArrow />
        </span>

        <span
          className={`font-semibold ${
            activeLink === "user" ? "text-dark" : "text-[#5E6278]"
          }`}
        >
          User Profile
        </span>
      </Link>
    </div>
  );
};

export default UserProfile;
