export const getReadableTimeDiff = (time: number): string => {
  const now: any = new Date();
  const diff = Math.abs(time - now);
  const mark = (time - now) >> -1 || 1;

  if (diff === 0) return new Intl.RelativeTimeFormat("en").format(0, "second");

  const times = [
    { type: "second", seconds: 1000 },
    { type: "minute", seconds: 60 * 1000 },
    { type: "hour", seconds: 60 * 60 * 1000 },
    { type: "day", seconds: 24 * 60 * 60 * 1000 },
    { type: "week", seconds: 7 * 24 * 60 * 60 * 1000 },
    { type: "month", seconds: 30 * 24 * 60 * 60 * 1000 },
    { type: "year", seconds: 12 * 30 * 24 * 60 * 60 * 1000 },
  ];

  let params: Array<any> = [];
  for (let t of times) {
    const segment = Math.round(diff / t.seconds);
    if (segment >= 0 && segment < 10) {
      params = [(segment * mark) | 0, t.type];
      break;
    }
  }
  return new Intl.RelativeTimeFormat("en").format(params[0], params[1]);
};
