import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NavArrow } from "../../../assets/rocket-img/icons/nav-arrow.svg";

const Dashboard = ({ activeLink }: { activeLink: string }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  return (
    <div className={`flex flex-col mb-6`} onClick={toggleSubMenu} role="button">
      <Link to="/" className="flex items-center">
        <span
          className={`w-[15px] h-[15px] mr-2 transition-[transform] duration-300 ${
            activeLink === "dashboard" ? "opacity-100" : "opacity-30"
          } `}
        >
          <NavArrow />
        </span>

        <span
          className={`font-semibold ${
            activeLink === "dashboard" ? "text-dark" : "text-[#5E6278]"
          }`}
        >
          Dashboards
        </span>
      </Link>
      {/* <div
        className={`flex ${showSubMenu ? "h-fit opacity-100 visible" : " h-[0px] opacity-0 invisible"}`}
      >
        <div className="mx-auto my-4">
          <a className="flex items-center" href=".">
            <div className="w-[5px] h-[5px] rounded-full bg-[#888] mr-2" />
            <span className="font-semibold text-[#888]">Multipurpose</span>
          </a>
        </div>

      </div> */}
    </div>
  );
};

export default Dashboard;
