import axios from "axios";

export const rocketAPI = axios.create({
  baseURL: process.env.REACT_APP_WEB_SERVICE,
});

export const setToken = (token: string | null) => {
  rocketAPI.defaults.headers.common["Authorization"] = token
    ? `Bearer ${token}`
    : "";
};
const pafe = [
  "Lop",
  {
    contributionId: "595005",
    projectId: "trejnnww0404",
  },
];
export type Query<T> = {
  queryKey: (string | T)[];
};

export interface PaginatedResponse<T> {
  items: T[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}
