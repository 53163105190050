import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useField } from "formik";
import { ReactComponent as PenIcon } from "assets/rocket-img/icons/pen_icon.svg";
import { ReactComponent as Placeholder } from "assets/rocket-img/img/volicity-9.svg";

interface DropZoneFieldProps {
  name: string;
}

const DropzoneField = ({ name }: DropZoneFieldProps) => {
  const [file, setFile] = useState<any>({});
  const [field, meta, helpers] = useField(name);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted: (files) => {
      helpers.setValue(files[0]);

      setFile({
        preview: URL.createObjectURL(files[0]),
      });
    },
    accept: {
      "image/jpeg": [".jpg"],
      "image/png": [".png"],
    },
  });

  return (
    <>
      <button
        type="button"
        {...getRootProps({
          className: "dropzone hover:scale-95 hover:opacity-[0.9]",
        })}
        onFocus={() => {
          helpers.setTouched(true);
        }}
      >
        <input {...getInputProps()} />
        {field.value ? (
          <div className="w-[200px] h-[200px] p-8  bg-[#F5F8FA]  rounded-lg relative">
            <img
              src={typeof field.value === "string" ? field.value : file.preview}
              className="w-full h-full"
              alt="uploaded logo"
            />
            <div className="absolute flex items-center bottom-[1rem] right-[-5rem]">
              <div className="w-[30px] h-[30px] flex items-center justify-center bg-white p-2 rounded-xl">
                <PenIcon />
              </div>
              <h2 className="text-[#B5B5C3] font-semibold">Change</h2>
            </div>
          </div>
        ) : (
          ""
        )}
        {!field.value && (
          <div className="w-[200px] h-[200px] p-8  bg-[#F5F8FA]  rounded-lg relative">
            <Placeholder />
            <div className="absolute flex items-center bottom-[1rem] right-[-5rem]">
              <div className="w-[30px] h-[30px] flex items-center justify-center bg-white p-2 rounded-xl">
                <PenIcon />
              </div>
              <h2 className="text-[#B5B5C3] font-semibold">Remove</h2>
            </div>
          </div>
        )}
      </button>
    </>
  );
};

export default DropzoneField;
