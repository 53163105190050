import { mainnet, polygon, polygonMumbai, goerli } from "wagmi/chains";
import { BNB_CHAIN, BNB_CHAIN_TEST } from "CHAINS";
import { IProtocolChain } from "@/interfaces/general";
import { Chain } from "wagmi";
const env = process.env.NODE_ENV;
export function getChainId(network: string) {
  if (network === "Polygon") {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      return polygonMumbai.id;
    else return polygon.id;
  } else if (network?.includes("Binance")) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      return BNB_CHAIN_TEST.id;
    else return BNB_CHAIN.id;
  } else {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      return goerli.id;
    else return mainnet.id;
  }
}

export const Chains: IProtocolChain = {
  mainNet: [
    {
      ...BNB_CHAIN,
      stableCoin: [
        {
          name: "USDT StableCoin",
          symbol: "USDT",
          slug: "usdt",
          decimal: 6,
          address: "0x",
        },
        {
          name: "USDC StableCoin",
          symbol: "USDC",
          slug: "usdc",
          decimal: 12,
          address: "0x",
        },
        {
          name: "TUSD StableCoin",
          symbol: "TUSD",
          slug: "tusd",
          decimal: 18,
          address: "0x",
        },
        {
          name: "Marker DAO Dai",
          symbol: "DAI",
          slug: "dai",
          decimal: 18,
          address: "0x",
        },
      ],
    },
    {
      ...mainnet,
      stableCoin: [
        {
          name: "USDT StableCoin",
          symbol: "USDT",
          slug: "usdt",
          decimal: 6,
          address: "0x",
        },
        {
          name: "USDC StableCoin",
          symbol: "USDC",
          slug: "usdc",
          decimal: 12,
          address: "0x",
        },
        {
          name: "TUSD StableCoin",
          symbol: "TUSD",
          slug: "tusd",
          decimal: 18,
          address: "0x",
        },
        {
          name: "Marker DAO Dai",
          symbol: "DAI",
          slug: "dai",
          decimal: 18,
          address: "0x",
        },
      ],
      default: true,
    },
    {
      ...polygon,
      stableCoin: [
        {
          name: "USDT StableCoin",
          symbol: "USDT",
          slug: "usdt",
          decimal: 6,
          address: "0x",
        },
        {
          name: "USDC StableCoin",
          symbol: "USDC",
          slug: "usdc",
          decimal: 12,
          address: "0x",
        },
        {
          name: "TUSD StableCoin",
          symbol: "TUSD",
          slug: "tusd",
          decimal: 18,
          address: "0x",
        },
        {
          name: "Marker DAO Dai",
          symbol: "DAI",
          slug: "dai",
          decimal: 18,
          address: "0x",
        },
      ],
    },
  ],
  testNet: [
    {
      ...BNB_CHAIN_TEST,
      stableCoin: [
        {
          name: "USDT StableCoin",
          symbol: "USDT",
          slug: "usdt",
          decimal: 6,
          address: "0xUSDT",
        },
        {
          name: "USDC StableCoin",
          symbol: "USDC",
          slug: "usdc",
          decimal: 12,
          address: "0xUSDC",
        },
        {
          name: "TUSD StableCoin",
          symbol: "TUSD",
          slug: "tusd",
          decimal: 18,
          address: "0xTUSD",
        },
        {
          name: "Marker DAO Dai",
          symbol: "DAI",
          slug: "dai",
          decimal: 18,
          address: "0xDAI",
        },
      ],
    },
    {
      ...goerli,
      stableCoin: [
        {
          name: "USDT StableCoin",
          symbol: "USDT",
          slug: "usdt",
          decimal: 6,
          address: "0xUSDT",
        },
        {
          name: "USDC StableCoin",
          symbol: "USDC",
          slug: "usdc",
          decimal: 12,
          address: "0xUSDC",
        },
        {
          name: "TUSD StableCoin",
          symbol: "TUSD",
          slug: "tusd",
          decimal: 18,
          address: "0xTUSD",
        },
        {
          name: "Marker DAO Dai",
          symbol: "DAI",
          slug: "dai",
          decimal: 18,
          address: "0xDAI",
        },
      ],
    },

    {
      ...polygonMumbai,
      stableCoin: [
        {
          name: "USDT StableCoin",
          symbol: "USDT",
          slug: "usdt",
          decimal: 6,
          address: "0xUSDT",
        },
        {
          name: "USDC StableCoin",
          symbol: "USDC",
          slug: "usdc",
          decimal: 12,
          address: "0xUSDC",
        },
        {
          name: "TUSD StableCoin",
          symbol: "TUSD",
          slug: "tusd",
          decimal: 18,
          address: "0xTUSD",
        },
        {
          name: "Marker DAO Dai",
          symbol: "DAI",
          slug: "dai",
          decimal: 18,
          address: "0xDAI",
        },
      ],
      default: true,
    },
  ],
};

export const mainNetChains = Chains.mainNet;
export const testNetChains = Chains.testNet;

export const isChainIdAvailable = (id: string | number | undefined) => {
  const idToNumber = (typeof id == "string") ? parseInt(id, 16) : id;
  const chains = env === "development" ? testNetChains : mainNetChains;
  const selectedChain = chains.filter((chain) => chain.id === idToNumber);
  return selectedChain.length ? selectedChain : false;
};

export const defaultChain = (): Chain => {
  const chains = env === "development" ? testNetChains : mainNetChains;
  return chains.filter((chain) => chain.default)[0];
};

export const getEnvChains = () => {
  return  env === "development" ? testNetChains : mainNetChains;
}