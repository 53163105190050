const Spinner = ({ className }: { className?: string }) => {
  return (
    <>
      <div
        className={`animate-spin w-[50px] h-[50px] border-[7px] border-[#7C88B1] border-solid  border-t-[#00A3FF] rounded-full ${className}`}
      ></div>
    </>
  );
};

export default Spinner;
