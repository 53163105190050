import type { Chain } from "wagmi";

export const BNB_CHAIN: Chain = {
  id: 56,
  name: "Binance Smart Chain",
  network: "Binance Smart Chain",
  nativeCurrency: {
    decimals: 18,
    name: "Binance Smart Chain",
    symbol: "BNB",
  },
  rpcUrls: {
    default: "https://bsc-dataseed.binance.org/",
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://bscscan.com" },
  },
  testnet: false,
};

export const BNB_CHAIN_TEST: Chain = {
  id: 97,
  name: "Smart Chain - Testnet",
  network: "Smart Chain - Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "Smart Chain - Testnet",
    symbol: "BNB",
  },
  rpcUrls: {
    default: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://testnet.bscscan.com" },
  },
  testnet: true,
};

export const HARD_HAT: Chain = {
  id: 8545,
  name: "Localhost 8545",
  network: "Localhost 8545",
  nativeCurrency: {
    decimals: 18,
    name: "Localhost 8545",
    symbol: "ETH",
  },
  rpcUrls: {
    default: "http://127.0.0.1:8545/",
  },
  // testnet: true,
};
