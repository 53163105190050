import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NavArrow } from "../../../assets/rocket-img/icons/nav-arrow.svg";

const Claim = ({ activeLink }: { activeLink: string }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  return (
    <div className={`flex flex-col`} role="button">
      <Link to="/claims" className="flex items-center">
        <span
          className={`w-[15px] h-[15px] mr-2 ${
            activeLink === "claims" ? "opacity-100" : "opacity-30"
          }`}
        >
          <NavArrow />
        </span>

        <span
          className={`font-semibold ${
            activeLink === "claims" ? "text-dark" : "text-[#5E6278]"
          }`}
        >
          Claims
        </span>
      </Link>
      {/* <div
        className={`flex ${
          showSubMenu
            ? "h-fit opacity-100 visible"
            : "h-[0px] opacity-0 invisible"
        }`}
      >
        <div className="mx-auto my-4">
          <p className="flex items-center">
            <div className="w-[5px] h-[5px] rounded-full bg-[#888] mr-2" />
            <span className="font-semibold text-[#888]">Multipurpose</span>
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default Claim;
