import { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "components/header";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import SuccessModal from "components/SuccessModal";
import CreateProjectForm from "components/projects/CreateProject_form";
import useInitialState from "components/projects/CreateProject_form/utils/useInitialState";
import { CREATE_PROJECT } from "services/api/projects";
import CreateProjectWeb3 from "components/projects/CreateProject_form/projectBlockChain";
import { useNavigate } from "react-router-dom";
import { ICreateProject } from "interfaces/projects";
import { IRouteError } from "interfaces/general";
import { useSnackbar } from "notistack";

const CreateProject = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showWeb3Modal, setShowWeb3Modal] = useState(false);
  const [projectState, setProjectState] = useState<ICreateProject | null>(null);
  const { initialProjectState } = useInitialState();
  const addNewProject = useMutation("newProject", CREATE_PROJECT, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });

  return (
    <>
      <div className="w-full ml-auto">
        <>
          <Helmet>
            {location.pathname.includes("update") ? (
              <title>{initialProjectState?.name}</title>
            ) : (
              <title>Rocket- Create New Project</title>
            )}
          </Helmet>

          <div className="flex flex-col px-0 sm:px-10">
            <div className="w-full  px-10 sm:px-0">
              <Header
                title="Projects"
                subTitle=" Subtitle"
                name={
                  location.pathname.includes("update")
                    ? initialProjectState?.name
                    : ""
                }
              />
            </div>

            <CreateProjectForm
              setProjectState={setProjectState}
              projectState={projectState}
              setShowSuccessModal={setShowSuccessModal}
              setShowWeb3Modal={setShowWeb3Modal}
            />
          </div>

          {showSuccessModal ? (
            <SuccessModal
              toggleClose={() => {
                setShowSuccessModal(false);
                if (!location.pathname.includes("update")) {
                  navigate(`/projects/${addNewProject.data?.data?.id}`);
                }
              }}
              message={
                location.pathname.includes("update")
                  ? "Project has been updated"
                  : "Project has been created"
              }
            />
          ) : (
            ""
          )}
          {showWeb3Modal ? (
            <CreateProjectWeb3
              addNewProject={addNewProject}
              setProjectState={setProjectState}
              setShowSuccessModal={setShowSuccessModal}
              setShowWeb3Modal={setShowWeb3Modal}
              projectState={projectState}
            />
          ) : (
            ""
          )}
        </>
      </div>
    </>
  );
};

export default CreateProject;
