import React from "react";
import CloseIcon from "assets/rocket-img/icons/close_icon.png";
import { Formik, Form, Field, ErrorMessage, FieldProps } from "formik";
import * as yup from "yup";
import { LoaderButton } from "../loader-utils/LoaderButton";
import { useMutation } from "react-query";
import { UPDATE_PROJECT } from "services/api/projects";
import { useParams } from "react-router-dom";
import { ICreateProject, IProject } from "interfaces/projects";

interface IProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  project: ICreateProject | IProject;
  refecthProjectDetails: any;
}

const UpdateProcessModal = ({
  setShowModal,
  project: projectDetails,
  refecthProjectDetails,
}: IProps) => {
  const { project } = useParams();

  const initialValue = {
    status: projectDetails?.status,
  };
  const validationSchema = yup.object({
    status: yup.string().required("Please select a status"),
  });
  const { mutate, isLoading } = useMutation(
    ["statusUpdate", project || ""],
    UPDATE_PROJECT,
    {
      onSuccess: () => {
        refecthProjectDetails();
        return setShowModal(false);
      },
      onError: (err: Error) => {
        alert(`Sorry an error occured, ${err.message}`);
      },
    }
  );

  const getStatus = (initialValue: string) => {
    const options = ["upcoming", "live", "closed", "released"];
    const matched = options.find((item) => initialValue === item);

    return (
      <>
        <option className="capitalize" value={matched}>
          {matched}
        </option>
        {options
          .filter((item: string) => matched !== item)
          .map((item: string) => {
            return (
              <>
                <option key={item} value={`${item}`} className="capitalize">
                  {item}
                </option>
              </>
            );
          })}
      </>
    );
  };
  return (
    <>
      <div className="bg-black/50 w-full min-w-[100vw] py-[5rem] h-full min-h-[100vh] overflow-y-auto fixed top-0 left-0 flex z-[999]">
        <div
          className={`w-[90%] lg:w-[70%] max-w-[500px] h-fit  relative z-[150] mx-auto bg-white rounded-xl`}
        >
          <div className="px-8 py-6">
            <div className="flex w-full">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="w-[30px] h-[30px] ml-auto opacity-[0.5]"
              >
                <img src={CloseIcon} alt="close" />
              </button>
            </div>
            <div className="w-full">
              <h2 className="text-xl font-semibold mb-4">Change Status</h2>
              <div className="border-t border-[#EFF2F5] border-solid w-full"></div>
              <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  if (project) {
                    const data = {
                      projectId: project,
                      projectDetails: {
                        swap_rate: Number(projectDetails.swap_rate),
                        status: values.status,
                      },
                    };
                    mutate(data);
                  }
                }}
              >
                <Form>
                  <div className="flex items-center w-full my-10">
                    <label
                      htmlFor="status"
                      className="text-lg font-semibold w-[141px]"
                    >
                      Status
                    </label>
                    <div className="mx-auto w-[445px] bg-[#F5F8FA] relative">
                      <Field name="status">
                        {(props: FieldProps) => {
                          const { field, form, meta } = props;

                          return (
                            <>
                              <select
                                onChange={(
                                  e: React.ChangeEvent<HTMLSelectElement>
                                ) => {
                                  form.setFieldValue("status", e.target.value);
                                }}
                                className="appearance-none cursor-pointer bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                                name="status"
                                //  value={field.value}
                                //  onBlur={field.onBlur}
                              >
                                {getStatus(meta.initialValue)}
                              </select>
                            </>
                          );
                        }}
                      </Field>
                      <span className="content-['']  border-r-[2px] border-b-[2px] border-[#7E8299] border-solid rotate-45 absolute top-[45%] translate-y-[-45%] right-[6px] inline-block p-[3px] pointer-events-none"></span>
                    </div>
                    <div className="text-red-500">
                      <ErrorMessage name="status" />
                    </div>
                  </div>

                  <LoaderButton
                    loading={isLoading}
                    className="ml-auto bg-[#00A3FF] text-white rounded py-2 pl-2 px-4 hover:scale-95 hover:opacity-[0.9]"
                  >
                    Change
                  </LoaderButton>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProcessModal;
