import { formatToCurrencyString } from "utils/formatToCurrencyString";
import type { FC } from "react";
import { IProject } from "interfaces/projects";
import { getProjectContributionAmount } from "./getContributionAmount";
import TokenBalanceDropdown from "./TokenBalanceDropdown";

interface IProps {
  projectDetails: IProject | null;
}


const ProjectStats: FC<IProps> = ({ projectDetails, children }) => {
  const contributionAmount = projectDetails?.id
    ? getProjectContributionAmount(projectDetails)
    : 0;
  const contributionPercentage = parseFloat(
    (
      (contributionAmount / parseFloat(`${projectDetails?.pool_size}`)) *
      100
    ).toFixed(2)
  );
  return (
    <>
      <div className="bg-white w-full rounded p-6 mt-6">
        <div className="flex flex-col lg:flex-row w-full mb-6">
          <div className="w-[80%] lg:w-[20%] flex justify-center bg-[#F5F8FA] rounded w-[150px] h-[150px] ml-auto lg:ml-0 mr-auto lg:mr-8 mb-4">
            <img
              className="p-4 object-contain w-full h-full"
              src={`${projectDetails?.icon}`}
              alt="thumbnail"
            />
          </div>

          <div className="w-full lg:w-[80%]">
            <div className="flex justify-between items-start mb-2">
              <div className="flex flex-col">
                <div className="flex items-center mb-1">
                  <a
                    href="."
                    className="text-[#3F4254] hover:text-[#00A3FF] text-xl font-bold mr-4"
                  >
                    Project {projectDetails?.name}
                  </a>
                </div>

                <div className="flex mb-4 text-base text-[#B5B5C3]">
                  tagline
                </div>
              </div>

              <div className="flex mb-4">
                <span className="py-2 px-4 bg-[#00A3FF]/10 rounded text-[#00A3FF]">
                  {projectDetails?.status}
                </span>
                <TokenBalanceDropdown />
              </div>
            </div>
            {children}
            <div>
              <div className="flex items-center mb-2 justify-between">
                <span className="text-[#3F4254] mr-2 font-bold">
                  ${formatToCurrencyString(contributionAmount)} of $
                  {formatToCurrencyString(
                    parseFloat(projectDetails?.pool_size || "0")
                  )}{" "}
                  contributed
                </span>
                <span className="text-[#3F4254] mr-2 font-bold">
                  {contributionPercentage}%
                </span>
              </div>
              <div className="progress h-[6px]">
                <div
                  className="progress-bar bg-[#8950FC]"
                  role="progressbar"
                  style={{ width: `${contributionPercentage}%` }}
                  aria-valuenow={contributionPercentage}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectStats;
