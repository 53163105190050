import { createContext, useContext, useState } from "react";
import type { FC } from "react";

export interface Subscription {
  id: string;
  name: string;
  subscriptionId: number;
}
export interface IAppState {
  /**
   * check if sidenav is displayed
   */
  isMobile: boolean;
  /**
   * check if user is an admin
   */
  isAdmin: boolean;
  /**
   * admin user
   */
  user?: {
    nickname: string;
    subscription?: Subscription;
  };
  /**
   * user role
   */
  role?: {
    name: string;
  };
  /**
   * memberful user details
   */
  memberFulUser?: {
    customer: {
      address: string;
      contributions: any;
      create_date: string;
      creator_user_id: string | number | null;
      email: string;
      first_name: string;
      id: string;
      kyc_verified: boolean;
      last_mod_date: string;
      last_mod_user_id: string | number | null;
      last_name: string;
      phoneNumber: string | null;
      projects: any[];
      signedTransaction: Partial<{
        blockchain: string;
        id: string;
        nftId: number;
        signatureHash: string;
        transactionId: string;
      }>;
      status: string;
      subscription: {
        id: string;
        name: string;
        nft: {
          id: string;
          name: string;
          nftTierId: number;
        };
        subscriptionId: string;
      };
      user_id: string;
      walletAddress: string | null;
    } | null;
    memberFulData: {
      fullName: string;
    };
  };

  /**
   * check if wallet is connected
   */
  walletConnected?: boolean;
  /**
   * wallet address
   */
  walletAddress?: string;
  /**
   * link to redirect to when session expires
   */
  redirect_to?: string | null;
}

export interface IAppContext {
  appState: IAppState;
  updateState?: (change: Partial<IAppState>) => void;
}

const INITIAL_STATE: IAppState = {
  isMobile: false,
  isAdmin: true,
  user: {
    nickname: "",
  },
  role: {
    name: "",
  },
  memberFulUser: {
    customer: null,
    memberFulData: {
      fullName: "",
    },
  },
  walletAddress: "",
};

const AppContext = createContext<IAppContext>({
  appState: INITIAL_STATE,
});
const AppProvider: FC = ({ children }) => {
  const [appState, setAppState] = useState(INITIAL_STATE);
  const updateState = (c: Partial<IAppState>) => {
    setAppState((prev) => ({ ...prev, ...c }));
  };
  return (
    <AppContext.Provider
      value={{
        appState,
        updateState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default AppProvider;

export const useAppContext = () => useContext(AppContext);
