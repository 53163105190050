import { Helmet } from "react-helmet";
import Header from "components/header";

const AdminView = () => {
  return (
    <>
      <Helmet>
        <title>Rocket- Admin {"(Dashboard)"}</title>
      </Helmet>

      <div className="w-full flex flex-col justify-between px-10">
        <Header name="Dashboard" title="Dashboard" subTitle="" />
        <div className="flex flex-col w-full"></div>
      </div>
    </>
  );
};

export default AdminView;
