import { useState } from "react";
import DatePicker from "react-datepicker";
import { useField } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarIcon } from "assets/rocket-img/icons/calendar_date_picker.svg";

const DatePickerField = ({ ...props }: any) => {
  const [field, meta, helper] = useField<string>(props);
  const [date, setDate] = useState<Date | null>(
    field.value ? new Date(field.value) : null
  );
  return (
    <>
      <div className="flex items-center">
        <div className="w-[16px] h-[16px] ml-4">
          <CalendarIcon />
        </div>

        <DatePicker
          {...field}
          {...props}
          className="bg-[#F5F8FA] py-2 outline-[#8e8e8e] w-full cursor-pointer"
          selected={(field.value && date) || null}
          onChange={(newDate) => {
            setDate(newDate);
            if (newDate) {
              helper.setValue(`${newDate}`);
            } else {
              helper.setValue("");
            }
            // setFieldValue(field.name, newDate);
          }}
          showTimeSelect={true}
          timeFormat="HH:mm"
          timeIntervals={props.customTimeInterval}
          isClearable={true}
          timeCaption="time"
          minDate={new Date()}
          dateFormat="MMMM d, yyyy h:mm aa"
        />
        {/* <button className="block bg-red-200 rounded-lg py-2 px-6 m-1 text-white" onClick={props.toggleInterval}>
    toggle
</button>
           </DatePicker> */}
      </div>
    </>
  );
};

export default DatePickerField;
