import { FC } from "react";
import {
  WagmiConfig,
  createClient,
  configureChains,
  defaultChains,
} from "wagmi";
import { polygon, polygonMumbai } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { BNB_CHAIN, BNB_CHAIN_TEST, HARD_HAT } from "./CHAINS";

const Web3Config: FC = ({ children }) => {
  const supported = [
    ...defaultChains,
    BNB_CHAIN,
    BNB_CHAIN_TEST,
    HARD_HAT,
    polygon,
    polygonMumbai,
  ];
  const { provider, webSocketProvider } = configureChains(supported, [
    publicProvider(),
  ]);
  const client = createClient({
    provider,
    webSocketProvider,
  });

  return (
    <>
      <WagmiConfig client={client}>{children}</WagmiConfig>
    </>
  );
};

export default Web3Config;
