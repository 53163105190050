import { Helmet } from "react-helmet";
import Header from "components/header";
import { useAppContext } from "contexts/AppContext";
import { ReactComponent as SearchIcon } from "assets/rocket-img/icons/search_icon.svg";
import Table from "components/Table";
import { ContributionTable, tableColumns } from "./columns";
import { useQuery } from "react-query";
import { GET_USER_CONTRIBUTIONS } from "./../../services/api/contribution";
import { useState } from "react";
import Spinner from "components/loader-utils/Spinner";
import { formatDate } from "utils/formatDate";
const Contributions = () => {
  const { appState } = useAppContext();
  const { isAdmin } = appState;
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const { data, error, isLoading, isSuccess } = useQuery(
    ["getAllContributions", { page: page + 1 }],
    GET_USER_CONTRIBUTIONS,
    {
      staleTime: Infinity,
    }
  );

  const displayContributions = (): ContributionTable[] => {
    if (data?.data.items && Array.isArray(data?.data.items)) {
      return data?.data.items.map((contribution) => ({
        project: {
          name: contribution.project.name,
          id: contribution.project.id,
          network: contribution.project.contribution_network,
        },
        amount: contribution.amount,
        tokenSymbol: contribution.project.token_code,
        swapRate: contribution.project.swap_rate,
        status: "Contributed",
      }));
    }
    return [];
  };
  return (
    <>
      <Helmet>
        <title>Contriutor- My Contributions</title>
      </Helmet>

      <div className="flex flex-col w-full px-10">
        <Header title="Contributions" subTitle="Subtitle" />
        <div>
          <div className="flex flex-col">
            <div className="flex items-center">
              <div className="w-[40%] relative my-4 ml-4">
                <span className="w-[15px] h-[15px] absolute top-[50%] translate-y-[-50%] left-0">
                  <SearchIcon />
                </span>

                <input
                  type="text"
                  className="bg-[#f0f0fa] outline-0 py-2 pl-6 w-full"
                  name="search"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            {isLoading ? (
              <div className=" w-full h-full overflow-y-auto py-[7rem] flex z-[100]">
                <div className="m-auto">
                  <Spinner />
                </div>
              </div>
            ) : (
              <Table
                isNavigation={false}
                active="contributions"
                tableColumns={tableColumns}
                tableData={displayContributions()}
                hasPagination={true}
                setPageIndex={setPage}
                queryPageIndex={page}
                queryPageSize={pageSize}
                isSuccess={isSuccess}
                totalItems={data?.data.meta.totalItems || 0}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contributions;
