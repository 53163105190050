import React, { useEffect, useState } from "react";
import DatePickerField from "../DatePicker";
import DropzoneField from "../DropzoneField";
import { Formik, Form, Field, ErrorMessage } from "formik";
import type { FieldProps, FormikProps } from "formik";
import { LoaderButton } from "components/loader-utils/LoaderButton";
import { validationSchema } from "./utils/schema";
import useInitialState from "./utils/useInitialState";
import { UPDATE_PROJECT } from "services/api/projects";
import { UPLOAD_CLOUDINARY } from "services/cloudinary";
import { useMutation } from "react-query";
import type { Dispatch, SetStateAction } from "react";
import { ICreateProject } from "@/interfaces/projects";
import {
  IMainNetChains,
  IRouteError,
  IStableCoins,
  ITestNetChains,
} from "interfaces/general";
import { useSnackbar } from "notistack";
import { getEnvChains } from "utils/getChainId";

interface ICreateProjectFormProps {
  setShowWeb3Modal: Dispatch<SetStateAction<boolean>>;
  projectState: ICreateProject | null;
  setProjectState: Dispatch<SetStateAction<ICreateProject | null>>;
  setShowSuccessModal: Dispatch<SetStateAction<boolean>>;
}

interface INewProjectFormInitial {
  logo: string;
  projName: string;
  status: string;
  swapRate: string;
  cap: string;
  capFormatted: string;
  symbol: string;
  timeToOpen: string;
  timeToClose: string;
  network: string;
  surpportedTokens: string;
  DesriptionAndSocials: {
    description: string;
    telegram: string;
    medium: string;
    twitter: string;
    facebook: string;
    discord: string;
    website: string;
  };
}

const CreateProjectForm = ({
  setShowWeb3Modal,
  setProjectState,
  projectState,
  setShowSuccessModal,
}: ICreateProjectFormProps) => {
  const [selectedChain, setSelectedChain] = useState<
    ITestNetChains | IMainNetChains
  >();

  const [selectedToken, setSelectedToken] = useState<IStableCoins>();

  const { enqueueSnackbar } = useSnackbar();

  const updateProject = useMutation("updateProject", UPDATE_PROJECT, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });

  const uploadIcon = useMutation("CLOUDINARY", UPLOAD_CLOUDINARY, {
    onError: (error) => {
      const errorMessage = (error as IRouteError)?.response.data.message;
      enqueueSnackbar({
        variant: "error",
        message: errorMessage,
        autoHideDuration: 5000,
      });
    },
  });

  useEffect(() => {
    if (projectState?.name) {
      window.scrollTo(0, 0);
      setShowWeb3Modal(true);
    }
  }, [projectState?.name]);

  const { INITAL_VALUES_CREATE, INITAL_VALUES_UPDATE, project } =
    useInitialState();

  const getStatus = (initialValue: string, value: string) => {
    const options = ["upcoming", "live", "closed", "released"];

    if (initialValue !== "") {
      const matched = options.find((item) => initialValue === item);

      return (
        <>
          <option className="capitalize" value={matched}>
            {matched}
          </option>
          <option value=""></option>
          {options
            .filter((item: string) => matched !== item)
            .map((item: string) => {
              return (
                <option key={item} value={`${item}`} className="capitalize">
                  {item}
                </option>
              );
            })}
        </>
      );
    } else {
      return (
        <>
          <option value=""></option>
          {options.map((item: string) => {
            return (
              <option key={item} value={`${item}`} className="capitalize">
                {item}
              </option>
            );
          })}
        </>
      );
    }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={
          location.pathname.includes("update")
            ? INITAL_VALUES_UPDATE
            : INITAL_VALUES_CREATE
        }
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const formatted_close = new Date(values.timeToClose);
          const formatted_open = new Date(values.timeToOpen);

          if (typeof values.logo !== "string") {
            const imageFormData = new FormData();
            imageFormData.append("file", values.logo);
            if (process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET) {
              imageFormData.append(
                "upload_preset",
                process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
              );
            }
            if (location.pathname.includes("update")) {
              uploadIcon.mutate(imageFormData, {
                onSuccess: (data: any) => {
                  if (project) {
                    updateProject.mutate({
                      projectId: project,
                      projectDetails: {
                        name: values.projName,
                        description: values.DesriptionAndSocials.description,
                        icon: data?.data?.url,
                        status: values.status,
                        open_time: formatted_open.toISOString(),
                        close_time: formatted_close.toISOString(),
                        website: values.DesriptionAndSocials.website,
                        medium: values.DesriptionAndSocials.medium,
                        twitter: values.DesriptionAndSocials.twitter,
                        discord: values.DesriptionAndSocials.discord,
                        telegram: values.DesriptionAndSocials.telegram,
                        pool_size: Number(values.cap),
                        swap_rate: Number(values.swapRate),
                        token_code: values.symbol,
                        contribution_network: values.network,
                        release_month: "",
                      },
                      // token
                    });
                  }
                },
              });
            } else {
              setProjectState({
                name: values.projName,
                description: values.DesriptionAndSocials.description,
                icon: values.logo,
                status: values.status,
                open_time: formatted_open.toISOString(),
                close_time: formatted_close.toISOString(),
                website: values.DesriptionAndSocials.website,
                medium: values.DesriptionAndSocials.medium,
                twitter: values.DesriptionAndSocials.twitter,
                discord: values.DesriptionAndSocials.discord,
                telegram: values.DesriptionAndSocials.telegram,
                facebook: values.DesriptionAndSocials.facebook,
                pool_size: Number(values.cap),
                swap_rate: Number(values.swapRate),
                token_code: values.symbol,
                contribution_network: values.network,
                release_month: "",
              });
            }
          } else {
            if (project) {
              updateProject.mutate(
                {
                  projectId: project,
                  projectDetails: {
                    name: values.projName,
                    description: values.DesriptionAndSocials.description,
                    icon: values.logo,
                    status: values.status,
                    open_time: formatted_open.toISOString(),
                    close_time: formatted_close.toISOString(),
                    website: values.DesriptionAndSocials.website,
                    medium: values.DesriptionAndSocials.medium,
                    twitter: values.DesriptionAndSocials.twitter,
                    discord: values.DesriptionAndSocials.discord,
                    telegram: values.DesriptionAndSocials.telegram,
                    pool_size: Number(values.cap),
                    swap_rate: Number(values.swapRate),
                    token_code: values.symbol,
                    contribution_network: values.network,
                    release_month: "",
                  },
                },
                {
                  onSuccess: () => {
                    window.scrollTo(0, 0);
                    setShowSuccessModal(true);
                  },
                }
              );
            }
          }
        }}
      >
        {({ values }: FormikProps<INewProjectFormInitial>) => {
          return (
            <Form>
              <div className="py-[5rem] px-10 mt-10 bg-white w-full">
                <h2 className="text-xl font-semibold mb-4">Details</h2>
                <div className="border-t border-[#EFF2F5] border-solid w-full"></div>
                <div className="w-full mt-10">
                  <div className="flex flex-col md:flex-row md:items-center w-full mb-10">
                    <label
                      htmlFor="logo"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Project Logo
                    </label>
                    <div className="sm:mx-auto w-full sm:w-[445px] h-[200px]">
                      <DropzoneField name="logo" />
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="logo" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="projName"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Project Name
                    </label>
                    <div className="sm:mx-auto">
                      <div className="w-full sm:w-[445px] bg-[#F5F8FA]">
                        <Field
                          name="projName"
                          type="text"
                          className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                        />
                      </div>
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="projName" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="status"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Status
                    </label>
                    <div className="sm:mx-auto">
                      <div className="w-full sm:w-[445px] bg-[#F5F8FA] relative">
                        <Field
                          name="status"
                          className="appearance-none cursor-pointer bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                        >
                          {(props: FieldProps<string>) => {
                            const { field, form, meta } = props;

                            return (
                              <>
                                <select
                                  key={"status"}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>
                                  ) => {
                                    form.setFieldValue(
                                      "status",
                                      e.target.value
                                    );
                                  }}
                                  className="appearance-none cursor-pointer bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                                  name="status"
                                  value={field.value}
                                  onBlur={field.onBlur}
                                >
                                  {getStatus(meta.initialValue!, meta.value)}
                                </select>
                              </>
                            );
                          }}
                        </Field>
                        <span className="content-['']  border-r-[2px] border-b-[2px] border-[#7E8299] border-solid rotate-45 absolute top-[45%] translate-y-[-45%] right-[6px] inline-block p-[3px] pointer-events-none"></span>
                      </div>
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="status" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="swapRate"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Swap Rate (%)
                    </label>
                    <div className="sm:mx-auto">
                      <div className="w-full sm:w-[445px] bg-[#F5F8FA] relative">
                        <Field
                          name="swapRate"
                          type="number"
                          className=" bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                          max={100}
                        />
                      </div>
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="swapRate" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="capFormatted"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Cap
                    </label>
                    <div className="sm:mx-auto">
                      <div className="w-full sm:w-[445px] bg-[#F5F8FA]">
                        <Field name="capFormatted">
                          {({ form, field }: FieldProps<number>) => {
                            const [fieldType, setFieldType] =
                              useState("number");
                            const handleChange = (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              form.setFieldValue("cap", e.target.value);
                              form.setFieldValue(
                                "capFormatted",
                                e.target.value
                              );
                            };
                            const handleFocus = (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (values.cap) {
                                setFieldType("number");
                                //  remove formatting on focus
                                form.setFieldValue("capFormatted", values.cap);
                              }
                            };
                            const handleBlur = (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (e.target.value === "") return;
                              setFieldType("text");
                              const value = String(values.cap).replace(
                                /,/g,
                                ""
                              );
                              // format value on blur
                              form.setFieldValue(
                                "capFormatted",
                                `${parseFloat(value).toLocaleString("en-US", {
                                  style: "decimal",
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}`
                              );
                            };
                            return (
                              <input
                                type={fieldType}
                                name="capFormatted"
                                value={field.value}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="capFormatted" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="symbol"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Symbol
                    </label>
                    <div className="sm:mx-auto">
                      <div className="w-full sm:w-[445px] bg-[#F5F8FA]">
                        <Field
                          name="symbol"
                          type="text"
                          className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                        />
                      </div>
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="symbol" />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="timeToOpen"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Opens
                    </label>
                    <div className="sm:mx-auto">
                      <div className=" w-full sm:w-[445px] bg-[#F5F8FA] relative">
                        <DatePickerField
                          name="timeToOpen"
                          customTimeInterval={30}
                        />
                        <span className="content-['']  border-r-[2px] border-b-[2px] border-[#7E8299] border-solid rotate-45 absolute top-[45%] translate-y-[-45%] right-[6px] inline-block p-[3px] pointer-events-none"></span>
                      </div>
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="timeToOpen" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="timeToClose"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Closes
                    </label>
                    <div className="sm:mx-auto">
                      <div className="w-full sm:w-[445px] bg-[#F5F8FA]">
                        <DatePickerField
                          name="timeToClose"
                          customTimeInterval={30}
                        />
                      </div>
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="timeToClose" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="network"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Contribution Network
                    </label>
                    <div className="sm:mx-auto">
                      <div className="w-full sm:w-[445px] bg-[#F5F8FA] relative">
                        <Field name="network">
                          {({
                            field,
                            form,
                            meta,
                          }: {
                            field: any;
                            form: any;
                            meta: any;
                          }) => (
                            <select
                              {...field}
                              className="appearance-none cursor-pointer bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                              value={selectedChain?.name || ""}
                              onChange={(e) => {
                                const selectedChain = getEnvChains().find(
                                  (chain) => chain.name === e.target.value
                                );
                                setSelectedChain(selectedChain);
                                form.setFieldValue("network", e.target.value);
                              }}
                            >
                              <option value=""></option>
                              {getEnvChains().map(
                                (
                                  chain: ITestNetChains | IMainNetChains,
                                  key: number
                                ) => (
                                  <option key={key} value={chain.name}>
                                    {chain.nativeCurrency?.name}
                                  </option>
                                )
                              )}
                            </select>
                          )}
                        </Field>
                        <span className="content-['']  border-r-[2px] border-b-[2px] border-[#7E8299] border-solid rotate-45 absolute top-[45%] translate-y-[-45%] right-[6px] inline-block p-[3px] pointer-events-none"></span>
                      </div>
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="network" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="surpportedTokens"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Supported Tokens
                    </label>
                    <div className="sm:mx-auto">
                      <div className="w-full sm:w-[445px] bg-[#F5F8FA] relative">
                        <Field name="supportedTokens">
                          {({ field, form }: { field: any; form: any }) => (
                            <select
                              {...field}
                              className="appearance-none cursor-pointer bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                              value={selectedToken?.address || ""}
                              onChange={(e: any) => {
                                const selectedToken =
                                  selectedChain?.stableCoin?.find(
                                    (token) => token.address === e.target.value
                                  );
                                setSelectedToken(selectedToken);
                                form.setFieldValue(
                                  "supportedTokens",
                                  e.target.value
                                );
                              }}
                            >
                              <option value=""></option>
                              {selectedChain?.stableCoin?.map((token) => (
                                <option key={token.slug} value={token.address}>
                                  {token.name}
                                </option>
                              ))}
                            </select>
                          )}
                        </Field>
                        <span className="content-['']  border-r-[2px] border-b-[2px] border-[#7E8299] border-solid rotate-45 absolute top-[45%] translate-y-[-45%] right-[6px] inline-block p-[3px] pointer-events-none"></span>
                      </div>
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="supportedTokens" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-[5rem] px-10 my-10 bg-white w-full mx-auto">
                <h2 className="text-xl font-semibold mb-4">
                  Description and Socials
                </h2>
                <div className="border-t border-[#EFF2F5] border-solid w-full"></div>
                <div className="mt-10 w-full">
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="DesriptionAndSocials.description"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Project Description
                    </label>
                    <div className="sm:mx-auto">
                      <div className="w-full sm:w-[445px] bg-[#F5F8FA]">
                        <Field
                          name="DesriptionAndSocials.description"
                          as="textarea"
                          className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                        />
                      </div>
                      <div className="text-sm text-red-500 mt-1">
                        <ErrorMessage name="DesriptionAndSocials.description" />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="DesriptionAndSocials.telegram"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Telegram
                    </label>
                    <div className="sm:mx-auto w-full sm:w-[445px] bg-[#F5F8FA]">
                      <Field
                        name="DesriptionAndSocials.telegram"
                        type="text"
                        className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="DesriptionAndSocials.medium"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Medium
                    </label>
                    <div className="sm:mx-auto w-full sm:w-[445px] bg-[#F5F8FA]">
                      <Field
                        name="DesriptionAndSocials.medium"
                        type="text"
                        className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="DesriptionAndSocials.twitter"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Twitter
                    </label>
                    <div className="sm:mx-auto w-full sm:w-[445px] bg-[#F5F8FA]">
                      <Field
                        name="DesriptionAndSocials.twitter"
                        type="text"
                        className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="DesriptionAndSocials.facebook"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Facebook
                    </label>
                    <div className="sm:mx-auto w-full sm:w-[445px] bg-[#F5F8FA]">
                      <Field
                        name="DesriptionAndSocials.facebook"
                        type="text"
                        className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="DesriptionAndSocials.discord"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Discord
                    </label>
                    <div className="sm:mx-auto w-full sm:w-[445px] bg-[#F5F8FA]">
                      <Field
                        name="DesriptionAndSocials.discord"
                        type="text"
                        className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row md:items-center  w-full mb-10">
                    <label
                      htmlFor="DesriptionAndSocials.website"
                      className="text-lg font-semibold w-[141px] mb-2 md:mb-0"
                    >
                      Website
                    </label>
                    <div className="sm:mx-auto w-full sm:w-[445px] bg-[#F5F8FA]">
                      <Field
                        name="DesriptionAndSocials.website"
                        type="text"
                        className="bg-[#F5F8FA] py-2 pl-2 outline-[#8e8e8e] w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[90%] mx-auto pb-[5rem] flex">
                {location.pathname.includes("update") ? (
                  <LoaderButton
                    type="submit"
                    loading={uploadIcon.isLoading || updateProject.isLoading}
                    className="ml-auto bg-[#00A3FF] text-white rounded py-2 pl-2 px-4 hover:scale-95 hover:opacity-[0.9]"
                  >
                    Edit Project
                  </LoaderButton>
                ) : (
                  <button
                    type="submit"
                    className="ml-auto bg-[#00A3FF] text-white rounded py-2 pl-2 px-4 hover:scale-95 hover:opacity-[0.9]"
                  >
                    Save Project
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateProjectForm;
