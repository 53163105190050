import * as yup from "yup";

const DesriptionAndSocialsSchema = yup.object().shape({
  description: yup.string().required("Field is required"),
  telegram: yup.string(),
  medium: yup.string(),
  twitter: yup.string(),
  facebook: yup.string(),
  discord: yup.string(),
  website: yup.string(),
});

export const validationSchema = yup.object({
  logo: yup.mixed().required("Field is required"),
  projName: yup.string().required("Field is required"),
  status: yup.string().required("Field is required"),
  swapRate: yup
    .number()
    .max(100, "Value cannot be more than 100")
    .required("Field is required"),
  capFormatted: yup.string().required("Field is required"),
  symbol: yup.string().required("Field is required"),
  // description: yup.string(),
  timeToOpen: yup.string().required("Field is required"),
  timeToClose: yup.string().required("Field is required"),
  network: yup.string().required("Field is required"),
  surpportedTokens: yup.string().required("Field is required"),
  DesriptionAndSocials: DesriptionAndSocialsSchema,
});

export const validationSchemaWEB3 = yup.object({
  _targetAmount: yup.number().required(),
  _tokens: yup.array().of(yup.string()),
  expiry: yup.number(),
  _receiver: yup.string().required("Please eenter a valid address"),
  _price: yup.number(),
  _poolRewardAddress: yup.string().required("Please enter a valid address"),
  _tokenClaimAmount: yup.number(),
  _claimDuration: yup.number(),
  _firstInterval: yup.number(),
  _nextInterval: yup.number(),
  _finalInterval: yup.number(),
  _claimRate: yup.number(),
});
